<template>
<div>
    <div class="userIndexTop clearfix">
        <div class="userName">
            <div class="userNameBox01">
                <h3 :class="userInfo.A_Name?'':'colorInfo01'"><!-- {{userInfo.A_Name?userInfo.A_Name:'暂无昵称'}} --><span>个人用户</span></h3>
                <p>{{userInfo.A_LoginAccount}}</p>
            </div>
            <div class="userNameBox02">
                <ul>
                    <li>
                        <router-link :to="{name:'userOrder'}">
                            <h3>订单</h3>
                            <p>{{orderData==null?0:orderData.length}}</p>
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{name:'userCourse'}">
                            <h3>课程</h3>
                            <p>{{usCourseNum==null?0:usCourseNum.length}}</p>
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{name:'userOrder',params:{activeName:'third'}}">
                            <h3>未激活</h3>
                            <p class="colorRed">{{NotableData.length}}</p>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
        <div class="userIndexBox01">
            <router-link :to="{name:'courseList',params:{id:'37a00d8e-1e5f-48ca-98a0-200eee4d3bc5'}}">
                <img src="@/assets/images/username01.png">
                <p>护理培训</p>
                <!-- <span>1000套试卷(点击购买)</span> -->
            </router-link>
        </div>
        <div class="userIndexBox01">
            <router-link :to="{name:'courseList',params:{id:'37a00d8e-1e5f-48ca-98a0-200eee4d3bb5'}}">
                <img src="@/assets/images/username02.png">
                <p>双评培训</p>
                <!-- <span>1000套试卷(点击购买)</span> -->
            </router-link>
        </div>
        <div class="userIndexBox01">
            <router-link :to="{name:'courseList',params:{id:'37a00d8e-1e5f-48ca-98a0-200eee4d3bd5'}}">
                <img src="@/assets/images/username03.png">
                <p>定期考核</p>
                <!-- <span>1000套试卷(点击购买)</span> -->
            </router-link>
        </div>
    </div>
    <div class="userIndex_con clearfix" style="display:none;">
        <div class="userIndex_con_l" v-loading="loading">
            <div class="userIndex_con_title">热门课件</div>
            <course-item v-for="(item,index) in courseData" :key="index" :itemCourseData="item"></course-item>
        </div>
        <div class="userIndex_con_r">
            <div class="userIndex_con_r_box01"><img src="@/assets/images/username_guang.jpg"></div>
            <div class="userIndex_con_r_box02">
                <h3>最新消息</h3>
                <ul v-if='false'>
                    <li>
                        <router-link :to="{name:'userMessage'}">
                            <h4>惠州市惠城区2016-2017年度的...</h4>
                            <p>测试信息惠州市惠城区2016-201777年度医师定期惠州市惠城区201的...</p>
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{name:'userMessage'}">
                            <h4>惠州市惠城区2016-2017年度的...</h4>
                            <p>测试信息惠州市惠城区2016-201777年度医师定期惠州市惠城区201的...</p>
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{name:'userMessage'}">
                            <h4>惠州市惠城区2016-2017年度的...</h4>
                            <p>测试信息惠州市惠城区2016-201777年度医师定期惠州市惠城区201的...</p>
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{name:'userMessage'}">
                            <h4>惠州市惠城区2016-2017年度的...</h4>
                            <p>测试信息惠州市惠城区2016-201777年度医师定期惠州市惠城区201的...</p>
                        </router-link>
                    </li>
                </ul>
                <div class="noMessage" v-else style="text-algin:center">
                    <img src="@/assets/images/null-page-draw.png" width="150px">
                    <p>您暂时还没有消息！</p>
                </div>
            </div>
        </div>
    </div>
</div>

</template>

<script>
import courseItem from '@/components/course/courseItem.vue'
import api from "@/api/index.js";
export default{
    components:{
        courseItem,
    },
    data(){
        return{
            userInfo:{},
            orderData:[],
            noPay:{},
            courseData:[],
            NotableData:[],
            loading:true,
            usCourseNum:[],
            params:{
                status: ''
            }
        }
    },
    created(){
        this.getUserIndexHotList();
        this.getUserInfo();
        this.getUserOrder();
        this.weCourseBagList();
        // api.getAllUser()
        //     .then(res=>{
        //         console.log(res);
        //     })
    },
    methods:{
        // 获取热门课件
        getUserIndexHotList(){
            api.postCourseBagHotList(6).then(res=>{
                this.courseData=res.data;
                console.log("获取热门课件:",res);
                this.loading=false;
            })
            .catch(error=> {
                    console.log(error);
            })
        },
        // 获取用户信息
        getUserInfo(){
            api.getCustomerAccount({
                params:{
                    code:this.$store.state.userNameID,
                }
            })
                .then(res=>{
                    console.log(res);
                    this.userInfo=res.data.rows;
                     console.log("获取用户信息:",this.userInfo)
                })
                .catch(err=>{
                    console.log(err)
                })
        },
        // 获取订单
        getUserOrder(){
            api.postAllOrderListTime(this.params)
                .then(res=>{
                    console.log("获取订单:",res)
                    this.orderData=res.data.rows;

                    if(this.orderData!=null){
                        this.NotableData=this.orderData.filter((item,index)=>{
                            return item.O_PayStatus ==0 && item.O_SHOULDPAY!=0;
                        })
                    }
                    console.log("NotableData:"+this.NotableData)
                })
                .catch(err=>{
                    console.log(err)
                })
        },
        // 我的课程
        weCourseBagList(){
            api.getTCustomer_CourseBagList()
            .then(res=>{
                console.log('我的课程包:',res)
                this.usCourseNum = res.data.rows;

            })
            .catch(err=>{
                console.log(err)
            })
        },
    }
}

</script>

<style>
@import '../../assets/css/user.css';
</style>
