<template>
  <div>
    <div class="ConRightNav">
      <router-link :to="{name:'index'}">首页</router-link>
      <strong>></strong>
      <span>帮助中心</span>
      <strong>></strong>
      <span>新手上路</span>
    </div>
    <div class="ConRightText">
      <div class="H_c_r_t_title">新手上路</div>
      <div class="H_c_r_t_con">
        <div class="H_c_h3con">
          <p>
            <span>1.了解医+E服务平台</span>
          </p>
          <p>浏览器中输入http://www.1pluse.com/。或者百度搜索医+E，点击医+E平台访问首页。</p>
        </div>
        <div class="H_c_h3con">
          <p>
            <span>2.注册为会员</span>
          </p>
          <p>打开首页，点击头部注册，打开注册页面。</p>
        </div>
        <div class="H_c_h3con">
          <p>
            <span>3.登录，开始访问会员中心</span>
          </p>
          <p>打开首页，点击头部登录，打开登录页面。</p>
        </div>
        <div class="H_c_h3con">
          <p>
            <span>4.访问医+定考首页</span>
          </p>
          <p>打开医+E首页，点击顶部菜单服务平台，选择定期考核，打开定期考核首页。或者输入http://www.1pluse.com/访问定期考核首页。</p>
        </div>
        <div class="H_c_h3con">
          <p>
            <span>5.购买课程</span>
          </p>
          <p>打开定期考核首页，选择课程，点击打开课程详情页面，点击立即购买。</p>
        </div>
        <div class="H_c_h3con">
          <p>
            <span>6.会员中心</span>
          </p>
          <p>会员登录，点击顶部会员中心，或者点击会员昵称，可以访问会员中心。</p>
        </div>
        <div class="H_c_h3con">
          <p>
            <span>7.开始学习</span>
          </p>
          <p>会员登录，进入会员中心。点击我的学习，查看购买的课程，开始学习课程。</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>