<template>
<div>
    <div class="aboutGray">
        <div class="about1200">
           <div class="right_con">
            	<div class="right_gszz">
                	<div class="tupian">
                        <ul class="clearfix">
                            <li>
                                <img alt="a3" src="@/assets/images/z01.jpg" width="189" height="256px">
                                <h3>医+资源管理系统</h3>
                             </li>
                            <li>
                                <img alt="a3" src="@/assets/images/z02.jpg" width="189" height="256px">
                                <h3>医师定期考核信息登记系统</h3>
                            </li>
                            <li>
                                <img alt="a3" src="@/assets/images/z03.jpg" width="189" height="256px">
                                <h3>医东智能化题库</h3>
                            </li>
                            <li>
                                <img alt="a3" src="@/assets/images/z04.jpg" width="189" height="256px">
                                <h3>医东电子化考试</h3>
                            </li>
                            <li>
                                <img alt="a3" src="@/assets/images/z05.jpg" width="189" height="256px">
                                <h3>医东自动化智能组卷系统</h3>
                            </li>
                            <li>
                                <img alt="a3" src="@/assets/images/z06.jpg" width="189" height="256px">
                                <h3>医东在线投稿软件</h3>
                            </li>
                            <li>
                                <img alt="a3" src="@/assets/images/z07.jpg" width="189" height="256px">
                                <h3>医东在线注册报名软件</h3>
                            </li>
                            <li>
                                <img alt="a3" src="@/assets/images/z08.jpg" width="189" height="256px">
                                <h3>医东在线教育网络培训软件</h3>
                            </li>
                            <li>
                                <img alt="a3" src="@/assets/images/z09.jpg" width="189" height="256px">
                                <h3>医东医+综合管理平台</h3>
                            </li>
                            <li>
                                <img alt="a3" src="@/assets/images/z10.jpg" width="189" height="256px">
                                <h3>医东一站式会议发布软件</h3>
                            </li>
                            <li>
                                <img alt="a3" src="@/assets/images/z11.jpg" width="189" height="256px">
                                <h3>医师在线服务系统</h3>
                            </li>
                            <li>
                                <img alt="a3" src="@/assets/images/z12.jpg" width="189" height="256px">
                                <h3>医东培训手机APP软件(IOS版)</h3>
                            </li>
                            <li>
                                <img alt="a3" src="@/assets/images/z13.jpg" width="189" height="256px">
                                <h3>医东会员中心服务软件</h3>
                            </li>
                            <li>
                                <img alt="a3" src="@/assets/images/z14.jpg" width="189" height="256px">
                                <h3>医东医+学协会信息化建设系统</h3>
                            </li>
                            <li>
                                <img alt="a3" src="@/assets/images/z15.jpg" width="189" height="256px">
                                <h3>医东办公自动化管理用软件</h3>
                            </li>
                         </ul>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>

</template>

<script>
export default{

}

</script>

<style>
.about1200{ width: 1200px; margin: 0 auto; background: #fff;}
.right_con{ }
.right_gszz{}
.tupian{ padding:20px 0px;}
.tupian ul{ margin-left:-30px;}
.tupian ul li{ float:left; margin-left:53px; height:292px; margin-bottom:20px;}
.tupian ul li h3{text-align:center; color:#333; margin-top:5px; font-size:14px; height:40px; line-height:20px; width:189px;}

</style>