
import axios from "axios";
import router from './router'
import store from './store'
import {Loading, Message} from 'element-ui'
import Vue from 'vue'
import cookie from 'vue-cookies'
Vue.use(cookie)
axios.defaults.withCredentials = true;
axios.defaults.baseURL = '';
// 添加请求拦截器
axios.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    if(Vue.prototype.$cookies.get('yddl_token')){
      config.headers.common['Token']=Vue.prototype.$cookies.get('yddl_token')
      }
    config.params = {
        ...config.params,
        // appkey:"bxw123_1554272180284"
    }
    return config;
  }, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  });
axios.interceptors.response.use( (response)=> {
    // 对响应数据做点什么
    console.log('+++++++------',response.data);
    console.log(response.data.Status);
    switch (response.data.Status) {
      case 3:
      router.push({
                name:"login",
                // querry:{redirect:router.currentRoute.fullPath}//从哪个页面跳转
              })
      Message.error({
        message: '登录失效，请重新登录',
      })
      store.commit("setUserName",'')
      store.commit("changeLogin",false)
      store.commit('del_token')
      //localStorage.setItem('setCourseListUrl','')
      break
    }
    return response;
  }, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
  });
export default axios;
