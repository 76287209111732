<template>
  <div>
    <header class="headerTop"><header-top class="headerTopCon" :areaIsTrue='isAreaImg' :areaIdNameaa='areaIdNmae'></header-top></header>
    <!-- <div class="mainContent"><router-view/></div> -->
    <div class="areaHeader">
        <!-- <ul>
            <li v-for="item in subjectDataList" @click="subContent(item.ID)" :key="item.ID">{{item.SPC_Name}}</li>
        </ul> -->
        <div class="areaHeaderTab">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane v-for="(item,index) in subjectDataList"  :key="item.ID" :label="item.SPC_Name" :name="'val'+(index+1)"></el-tab-pane>
            </el-tabs>
        </div>
        
    </div>
    <div class="areaTitleContent">
        <div class="areaTitleContentTop">{{baRemark}}</div>
        <div class="areaTitleContentBox">
            <div class="areaImg"><img width="284" height="140" :src="courseDataAreaOne.CB_PCPICTUREURL"/></div>
            <div class="areaTextBox">
                <h3>{{courseDataAreaOne.CB_NAME}}</h3>
                <p>类型：<span>{{courseDataAreaOne.SPC_Name}}</span></p>
                <p>价格：<span class="areaRed">￥{{courseDataAreaOne.CB_REALPRICE}}</span></p>
                <!-- <a href="#">去购买</a> -->
                <router-link :to="{name:'courseDetail',params:{id:courseDataAreaOne.CB_ID}}">去购买</router-link>
            </div>
        </div>
    </div>
    <div class="areaCoList">
        <div class="areaCoListH3">{{subAllName}}全部课程</div>
        <div class="areaCoListContent" v-loading="loading" element-loading-text="数据加载中">
            <course-item v-for="(item,index) in courseDataArea" :key="index" :itemCourseData="item"></course-item>
            <div v-if="courseDataArea.length==0" style="    text-align: center; margin: 30px 0 0 0">
                <img src="../assets/images/zanwu.png" width="300">
            </div>
        </div>
    </div>
    <footer class="footerBottom"><footer-bottom></footer-bottom></footer>
  </div>
</template>
<script>
import headerTop from '@/components/header/headerTop.vue'
import footerBottom from '@/components/footer/footerBottom'
import courseItem from '@/components/course/courseItem.vue'
import api from "@/api/index.js";
export default{
    components:{
        headerTop,
        footerBottom,
        courseItem,
    },
    created(){
        console.log(this.$route.query)
        this.areaId=this.$route.query.id;
        this.areaIdNmae=this.$route.query.name;
        console.log(this.areaIdNmae)
        // this.getUserIndexHotList();
        this.introduceCou();
        this.getProssesionList();
        
    },
    data(){
        return{
            courseDataArea:[],
            NotableData:[],
            loading:true,
            isAreaImg:true,
            areaId:'',
            subjectDataList:[],//专科列表
            activeName: 'val1',
            subAllName:'',
            courseDataAreaOne:'',
            areaIdNmae:'',
            baRemark:'',
            baTitle:'',
        }
    },
    methods:{
        // 获取热门课件
        // getUserIndexHotList(){
        //     api.postCourseBagHotList(6).then(res=>{
        //         this.courseData=res.data;
        //         console.log("获取热门课件:",res);
        //         // this.loading=false;
        //     })
        //     .catch(error=> {
        //             console.log(error);
        //     })
        // },
        handleClick(tab, event) {
            console.log(tab, event);
            this.subContent(this.subjectDataList[tab.index].ID);
            this.subAllName=tab.label
        },
        // 获取专科列表
         getProssesionList(){
              api.GetProssesionList({
                "CB_NAME": "",
                "CB_KEYWORDS": "",
                "CB_COURSETYPE": "",
                "CB_TYPE": "",
                "CB_PROJECTCODE": "",
                "CB_PROFESSIONCODE": "",
                "CB_AreaCode": this.areaId,
                "page": 1,
                "rows": 20,
                "CB_STATE": 1,
                "CB_ONLINE": 1,
                "Mannage_STATE": 1,
                "Mannage_ONLINE": 1,
                "CB_STARTTIME": "",
                "CB_ENDTIME": ""
            }).then(res=>{
                console.log(res)
                this.subjectDataList = res.data.rows;
            }).then(resp=>{
                // this.subContent(this.subjectDataList[0].ID);
                // this.handleClick();
                if(this.activeName=='val1'){
                     this.subContent(this.subjectDataList[0].ID);
                    this.subAllName=this.subjectDataList[0].SPC_Name;
                }
            })
        },
        //获取地区介绍
        introduceCou(){
            api.postGetBusinessAreaByCode(this.areaId).then(res=>{
            if(res.data.rows!=null){
                this.baTitle=res.data.rows.BA_Title;
                this.baRemark=res.data.rows.BA_Remark;
            }
            });
        },
        //获取专科内容
        subContent(val){
            this.loading=true;
            api.GetCourseBagList({
                    "CB_PROJECTCODE": "37a00d8e-1e5f-48ca-98a0-200eee4d3bd5",
                    "CB_PROFESSIONCODE": val,
                    "CB_AreaCode": this.areaId,
                    "page": 1,
                    "rows": 100
                }).then(resp=>{
                console.log(resp)
                this.courseDataArea=resp.data.rows.BagRowsList;
                this.courseDataAreaOne=resp.data.rows.CategoryBagRows || [];
            }).then(resp=>{
                this.loading=false;
                console.log('sdsdsdd',this.courseDataArea,this.courseDataAreaOne)
            }).catch(err=>{
                console.log(err)
            })
        }
    }
}

</script>
<style>
.headerTop{ background: #fff; height: 78px; position: relative; z-index: 9999; -moz-box-shadow:0px 0px 7px #ccc; -webkit-box-shadow:0px 0px 7px #ccc; box-shadow:0px 0px 7px #ccc;}
.headerTopCon{ width: 1200px; margin: 0 auto;}
.mainContent{min-height: 400px; font-size: 16px;}
</style>

