<template>
<div class="about1200">
    <div class="Help_con_r">
            	<div class="H_c_r_text" style="overflow:hidden;">
                	<div class="H_c_r_t_title">联系方式</div>
                    <div class="H_c_r_t_con" style=" float:left;">
                    	<p>联系人： 汤先生</p>
                        <p>电　话：(021)-5595-8877</p>
                        <p>邮　件：<a href="mailto:tangxiaoquan@doc-exam.com">tangxiaoquan@doc-exam.com</a></p>
                        <p>地　址： 上海市虹口区东体育会路1188号6楼</p>
                        <p>邮　编：200437</p>
                        <p>传　真： 021-5596-9966</p>
                    </div>
                    <div class="H_c_r_t_con" style=" float:right;">
                    	<p>北京分公司</p>
                    	<p>联系人： 张女士</p>
                        <p>电　话：(010)-8573-1952</p>
                        <p>邮　件：<a href="mailto:charlotte@doc-exam.com">charlotte@doc-exam.com</a></p>
                        <p>地　址：北京市海淀区知春路盈都大厦C座3单元16A</p>
                    </div>
                </div>
                <div class="H_c_r_text">
                	<div class="H_c_r_t_title">技术支持</div>
                    <div class="H_c_r_t_con">
                    	<p>我公司有一支强大的研发团队，随时欢迎你联系我们。</p>
                        <p>联系人：程先生</p>
                        <p>邮　箱：<a href="mailto:chengchuanbo@doc-exam.com">chengchuanbo@doc-exam.com</a></p>
                    </div>
                </div>
                <div class="H_c_r_text">
                	<div class="H_c_r_t_title">商务合作</div>
                    <div class="H_c_r_t_con">
                    	<p>作为国内一流的为医师提供在线教育的平台，我们拥有巨大的用户资源，成熟的推广渠道和市场策略，欢迎与我公司洽谈异业及战略推广合作、品牌合作、媒体资源合作、数据库营销合作，谋求共同发展。</p>
                        <p>联系人：汤先生</p>
                        <p>邮　　箱：<a href="mailto:tangxiaoquan@doc-exam.com">tangxiaoquan@doc-exam.com</a></p>
                    </div>
                </div>
            </div>
</div>

</template>

<script>
export default{

}

</script>

<style>
.about1200{ width: 1200px; margin: 0 auto; background: #fff;}
.Help_con_r{ padding-bottom:40px;}
.about1200 .H_c_r_text{ border-bottom:1px dashed #ccc; padding-bottom:20px; margin-top:20px;margin: 0 120px; padding-top: 30px;}
.H_c_r_t_title{ background:url(../../assets/images/help_xing.png) left center no-repeat;  font-size:16px; color:#00a8e0; padding-left:15px;}
.H_c_r_t_con{ font-size:14px; margin-top:10px; line-height:30px; overflow:hidden;}
</style>