/**
 * 解析段落的unicode字符，聊天记录的内容中有很多是编码过的
 */
export function decodeUnicode(str) {
    var ret = '';
    var splits = str.split(';');
    for (let i = 0; i < splits.length; i++) {
        ret += splitDecode(splits[i]);
    }
    return ret;
}


/**
 * 解析单个unidecode字符
 */
export function splitDecode(value) {
    var target = value.match(/\\u\d+/g);
    if (target && target.length > 0) {
        target = target[0];
        var temp = value.replace(target, '{{@}}');
        target = target.replace('\\u', '');
        target = String.fromCharCode(parseInt(target));
        return temp.replace("{{@}}", target);
    } else {
        return value;
    }
}