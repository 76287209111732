<template>
<el-pagination
  background
  layout="prev, pager, next"
  :total="itemPageRows">
</el-pagination>

</template>

<script>
export default{
  props:["itemPageRows"],
  // created(){
  //   this.pageRows=this.pageRows;
  // },
  // data() {
  //   return {
  //     kejianData:'',
  //   }
  // },

}

</script>

<style>
</style>