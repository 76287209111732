<template>
  <div class="courseware">
    <div class="wareTitle">
      <h3>{{CB_NAME}}</h3>
      <div class="wareText">
        <strong>{{ProjectName}}-{{ProfessionName}}</strong>
        <span>{{bagItemCount}}道题量</span>
        <span>{{CoursewaresCount}}个课件</span>
        <span>{{VideosCount}}个视频</span>
      </div>
      <p>{{CB_DESCRIPTION}}</p>
    </div>
    <div class="wareCon">
      <ul>
        <li>
          <a href="#" @click="aitemshow">
            <p>
              <i class="el-icon-c-scale-to-original waColor01"></i>
            </p>
            <h3>
              随机练习
            </h3>
          </a>
        </li>
        <li>
          <a href="#" @click="awareshow">
            <p>
              <i class="el-icon-share waColor04"></i>
            </p>
            <h3>              
              课件
              <span>( {{CoursewaresCount}}个 )</span>
            </h3>
          </a>
        </li>
        <li>
          <a href="#" @click="avideoshow">
            <p>
              <i class="el-icon-document waColor02"></i>
            </p>
            <h3>
              视频
              <span>( {{VideosCount}}个 )</span>
            </h3>
          </a>
        </li>
      </ul>
    </div>
    <div class="dvCW" v-if="itemshow">
      <ul>
        <li>
          <router-link :to="{name:'userHospital',path:'/userHospital',params:{courseId:this.courseId}}">
            <h3>刷题<span>（{{courswareNum}}题）</span></h3>
          </router-link>
        </li>
        <li>
          <router-link :to="{name:'userTestList',path:'/userTestList',params:{courseId:this.courseId}}">
            <h3>模拟考试</h3>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="dvwares" v-if="waresShow">
      <ul>
        <li v-for="item in CourseBagCoursewares" :key="item.C_ID">
          <router-link :to="{path:'',query: { CoursewareUrl: item.C_CoursewareUrl}}" @click.native="waresClick(item.C_CoursewareUrl)">
            <h3>{{item.C_CoursewareName}}</h3>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="dvVideo" v-if="videoShow">
      <ul>
        <li v-for="item in CourseBagVideos" :key="item.C_ID">
          <router-link :to="{path:'',query:{}}" @click.native="videoClick(item.C_videoUrl)" >
            <h3>{{item.C_videoName}}</h3>
          </router-link>
        </li>
      </ul>
    </div>
    <video-player
      class="video-player vjs-custom-skin"
      ref="videoPlayer"
      :playsinline="true"
      :options="playerOptions"
      v-if="visibleVideo"
    ></video-player>
    <template v-if="visiblePdf">
      <div class="pdf-container">
        <pdf :src="pdfUrl"/>
      </div>
    </template>
    <div>
      <!-- <pdf src="../../static/test.pdf"></pdf> -->
    </div>
  </div>
</template>

<script>
import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";
import pdf from "vue-pdf";
import api from "@/api/index.js";
import { ftruncate } from 'fs';
export default {
  components: {
    videoPlayer,
    pdf
  },
  created() {
    if(this.$route.params.orderId!=undefined){
      this.orderId = this.$route.params.orderId;
      console.log("传过来的订单id:", this.orderId);
    }
    else if(this.$route.params.courseId!=undefined){
      this.courseId=this.$route.params.courseId;
      console.log("传过来的课程包id:", this.courseId);
    }
    
    this.postDangekecehng();
    
  },
  data() {
    return {
      courseItemIds:'',
      courseId: "",
      orderId:"",
      CB_NAME:"",     //课程包名称即标题
      CB_DESCRIPTION:"",  //课程包描述即简介
      ProjectName:"",
      ProfessionName:"",
      CoursewaresCount:"",
      bagItemCount:"",
      VideosCount:"",
      pdfUrl:"",
      CourseBagCoursewares:[],
      CourseBagVideos:[],
      playerOptions: {
        // playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            src: "http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4", // 路径
            type: "video/mp4" // 类型
          },
          {
            src: "//path/to/video.webm",
            type: "video/webm"
          }
        ],
        poster:
          "https://surmon-china.github.io/vue-quill-editor/static/images/surmon-1.jpg", //你的封面地址
        // width: document.documentElement.clientWidth,
        // width:'300px',
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true //全屏按钮
        }
      },
      itemshow: false,
      waresShow:false,
      videoShow:false,
      visibleVideo:true,
      visiblePdf:false,
    };
  },
  methods: {
    // 查看单个 课程项目的全部内容
    postDangekecehng() {
      //console.log(this.courseDetail.CB_ID);
      //this.courseId = 'FF1C60C4-F098-4311-95CF-EC2B774F673B';  //A1A2型题
      if(this.courseId!=""){
        api.PostCourseBagSingleAll(this.courseId).then(res => {
          if(res.data!=null){
            this.CB_NAME=res.data.CB_NAME;
            this.CB_DESCRIPTION=res.data.CB_DESCRIPTION;
            this.ProjectName=res.data.ProjectName;
            this.bagItemCount=res.data.CourseBagItems.length;
            this.CoursewaresCount=res.data.CourseBagCoursewares.length;
            this.VideosCount=res.data.CourseBagVideos.length;
            this.ProfessionName=res.data.ProfessionName;
            this.CourseBagCoursewares=res.data.CourseBagCoursewares;
            this.CourseBagVideos=res.data.CourseBagVideos;
            this.postCourseItemIDS();
          }
        });
      }
      else if(this.orderId!=""){
        console.log(1);
        api.postCourseBagSingleAllGetByOrderID(this.orderId).then(res=>{
          if(res.data!=null){
            console.log(res.data);
            this.courseId=res.data.CB_ID;
            this.CB_NAME=res.data.CB_NAME;
            this.CB_DESCRIPTION=res.data.CB_DESCRIPTION;
            this.ProjectName=res.data.ProjectName;
            this.bagItemCount=res.data.CourseBagItems.length;
            this.CoursewaresCount=res.data.CourseBagCoursewares.length;
            this.VideosCount=res.data.CourseBagVideos.length;
            this.ProfessionName=res.data.ProfessionName;
            this.CourseBagCoursewares=res.data.CourseBagCoursewares;
            this.CourseBagVideos=res.data.CourseBagVideos;
            this.postCourseItemIDS();
          }
        });
      }
      
    },
    postCourseItemIDS() {
      //this.courseId = "FF1C60C4-F098-4311-95CF-EC2B774F673B";
      console.log("courseId:"+this.courseId);
      api.postCourseBagItemIDListGet(this.courseId).then(res => {
        this.courseItemIds = res.data;
        this.courswareNum=res.data.length;
      });
    },
    aitemshow() {
      this.itemshow = !this.itemshow;
      this.videoShow = false;
      this.waresShow = false;
    },
    awareshow(){
      this.waresShow = !this.waresShow;
      this.videoShow = false;
      this.itemshow = false;
    },
    avideoshow(){
      this.videoShow = !this.videoShow;
      this.waresShow = false;
      this.itemshow = false;
    },
    videoClick(obj){
      this.visibleVideo=true;
      this.visiblePdf=false;
      var videoPath="/"+obj;
      console.log(videoPath);
      this.playerOptions= {
        // playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            src: videoPath, // 路径
            type: "video/mp4" // 类型
          },
          {
            src: "//path/to/video.webm",
            type: "video/webm"
          }
        ],
        poster:
          "https://surmon-china.github.io/vue-quill-editor/static/images/surmon-1.jpg", //你的封面地址
        // width: document.documentElement.clientWidth,
        // width:'300px',
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true //全屏按钮
        }
      }
    },
    waresClick(obj){
      this.visibleVideo=false;
      this.visiblePdf=true;
      this.pdfUrl=decodeURIComponent('http://192.168.2.196:8054/upload/CourseBagCoursewarePdf/350ee1af79e747569b5b07c3b7a34048.pdf');
    },
  }
};
</script>

<style>
.video-js .vjs-control {
  outline: none;
}
.video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  transform: translate(-50% -50%);
}
.v-enter,
.v-leave-to {
  opacity: 0;
  transform: translateX(100px);
}
.v-enter-active,
.v-leave-active {
  transition: all 0.4s ease;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.dvCW {
  float: left;
  margin-top: -21px;
  z-index: 12;
  position: absolute;
}
.dvCW ul {
  margin: 0px 0 0 -20px;
  overflow: hidden;
}
.dvCW ul li {
  margin: 1px 0 0 20px;
  width: 226px;
  height: 30px;
  text-align: center;
  background-color: #BBFFFF;
  padding-top: 10px;
  font-family:Arial, Helvetica, sans-serif;
}
.dvwares{
  float: left;
  margin-top: -21px;
  z-index: 12;
  position: absolute;
}
.dvwares ul {
  margin: 0px 0 0 226px;
  overflow: hidden;
}
.dvwares ul li {
  margin: 1px 0 0 20px;
  width: 226px;
  height: 30px;
  text-align: center;
  background-color: #BBFFFF;
  padding-top: 10px;
  font-family:Arial, Helvetica, sans-serif;
}
.dvVideo{
  float: left;
  margin-top: -21px;
  z-index: 12;
  position: absolute;
}
.dvVideo ul {
  margin: 0px 0 0 472px;
  overflow: hidden;
}
.dvVideo ul li {
  margin: 1px 0 0 20px;
  width: 226px;
  height: 30px;
  text-align: center;
  background-color: #BBFFFF;
  padding-top: 10px;
  font-family:Arial, Helvetica, sans-serif;
}
</style>