<template>
<div class="courseRightMain">
    <div class="courseRightTitle">热门课程</div>
    <div class="courseRightBox">
        <course-item v-for="(item,index) in courseData" :key="index" :itemCourseData="item"></course-item>
    </div>
    
</div>

</template>

<script>
import courseItem from '@/components/course/courseItem.vue'
import api from "@/api/index.js";
export default{
    components:{
        courseItem,
    },
    data(){
        return{
            courseData:[],
            // TitleList:{
            //     physicians:{
            //         title:'医师定期考核',
            //         projectCode:'37a00d8e-1e5f-48ca-98a0-200eee4d3bd5'
            //     },
            //     double:{
            //         title:'非公立医疗机构信用与能力评价',
            //         projectCode:'37A00D8E-1E5F-48CA-98A0-200EEE4D3BB5'
            //     },
            //     nurse:{
            //         title:'医师护理培训',
            //         projectCode:'37A00D8E-1E5F-48CA-98A0-200EEE4D3BC5'
            //     },
            //     hospital:{
            //         title:'医院管理培训',
            //         projectCode:''
            //     },
            //     subject:{
            //         title:'专科医师培训',
            //         projectCode:''
            //     },
            //     learnSociety:{
            //         title:'学协会信息化及会员服务系统',
            //         projectCode:''
            //     },
            // },
            paramsId:'',
        }
    },
    created(){
        this.paramsId=this.$route.params.id;
        // console.log(this.paramsId+'qwwe',this.TitleList[this.paramsId].projectCode);
        api.postCourseBagHotList(3).then(res=>{
            console.log("热门课程:",res,);
            this.courseData=res.data;
        }).catch(error=> {
                console.log(error);
        })
    }
}

</script>

<style>
.courseRightMain{background-color: #f7f8f9;}
.courseRightTitle{ padding:10px 0px; border-bottom: 1px solid #eee;margin:0 10px 10px 10px;}


</style>