<template>
<div>
    <pdf src="../../assets/images/kejian.pdf"></pdf>
  </div>

</template>

<script>
import pdf from 'vue-pdf'
export default{
    components: {
    pdf
  }

}

</script>

<style>
</style>