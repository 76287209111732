<template>
  <div class="courseware">
    <div class="wareTitle">
      <h3>{{CB_NAME}}</h3>
      <div class="wareText">
        <strong>{{ProjectName}}-{{ProfessionName}}</strong>
        <span>{{bagItemCount}}道题量</span>
        <span v-if="{CoursewaresCount}>0">{{CoursewaresCount}}个课件</span>
        <span v-if="{VideosCount}>0">{{VideosCount}}个视频</span>
      </div>
      <div v-html="CB_DESCRIPTION"></div>
    </div>
    <div class="wareCon">
      <ul>
        <!-- <li>
          <a href="#" @click="aitemshow">
            <p>
              <i class="el-icon-c-scale-to-original waColor01"></i>
            </p>
            <h3>
              随机练习
            </h3>
          </a>
        </li> -->
        <li>
          <a href="#">
            <div v-if="this.courseId=='dcf23d87-a760-4bb2-b5ca-28fa063a82a6'||this.courseId=='a7afe10b-8404-4190-98ed-c862b418f149'
||this.courseId=='c591d4d5-16be-4b0e-84ad-5fec2c56aa54'||this.courseId=='1cbcf3f7-2f01-4b3e-a814-eafef7f0b875'">
              <p>
                <i class="el-icon-c-scale-to-original waColor01"></i>
              </p>
              <h3>刷题 <span>({{bagItemCount}}题)<br>系统正在维护中，请使用手机刷题</span></h3>
            </div>
            <router-link v-else :to="{name:'usersImulation',query:{courseId:this.courseId}}">
                <p>
                  <i class="el-icon-c-scale-to-original waColor01"></i>
                </p>
                <h3>刷题 <span>({{bagItemCount}}题)</span></h3>
            </router-link>
          </a>
        </li>
        <li>
          <a href="#" @click="subTest">
                <p>
                  <i class="el-icon-c-scale-to-original waColor01"></i>
                </p>
                <h3>模拟考试</h3>
          </a>
                  <el-dialog
        title="模拟考试须知"
        :visible.sync="centerDialogVisible"
        width="30%"

        center>
        <span>{{alertContent}}</span>
        <span slot="footer" class="dialog-footer">
          <el-button  @click="centerDialogVisible = false">取 消</el-button>
          <el-button type="primary"  @click="DialogVisible()">确 定</el-button>
        </span>
       </el-dialog>
        </li>
        <li v-if="{CoursewaresCount}>0">
          <a href="#" @click="awareshow">
            <p>
              <i class="el-icon-share waColor04"></i>
            </p>
            <h3>
              课件
              <span>( {{CoursewaresCount}}个 )</span>
            </h3>
          </a>
        </li>
        <li v-if="{VideosCount}>0">
          <a href="#" @click="avideoshow">
            <p>
              <i class="el-icon-document waColor02"></i>
            </p>
            <h3>
              视频
              <span>( {{VideosCount}}个 )</span>
            </h3>
          </a>
        </li>
      </ul>
    </div>
    <!-- <div class="dvCW" v-if="itemshow">
      <ul>
        <li>
          <router-link :to="{name:'usersImulation',params:{courseId:this.courseId}}">
            <h3>刷题<span>（{{courswareNum}}题）</span></h3>
          </router-link>
        </li>
        <li>
          <router-link :to="{name:'userTestList',params:{courseId:this.courseId}}">
            <h3>模拟考试</h3>
          </router-link>
        </li>
      </ul>
    </div> -->
    <div class="dvwares" v-if="waresShow">
      <ul>
        <li v-for="item in CourseBagCoursewares" :key="item.C_ID">
          <span>{{item.C_CoursewareName}}</span>
          <router-link :to="{path:'',query: { courseId: courseId}}" @click.native="waresClick(item.C_CoursewareUrl,item.EbookId)">
            <el-button class="gobtn"  type="primary" size="small">去学习</el-button>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="dvVideo" v-if="videoShow">
      <ul>
        <li v-for="item in CourseBagVideos" :key="item.C_ID">
          <span>{{item.C_videoName}}</span>
          <router-link :to="{path:'',query:{ courseId: courseId }}" @click.native="videoClick(item.C_videoUrl)" >
            <el-button class="gobtn"  type="primary" size="small">去学习</el-button>
          </router-link>
        </li>
      </ul>
    </div>
    <video-player
      class="video-player vjs-custom-skin"
      ref="videoPlayer"
      :playsinline="true"
      :options="playerOptions"
      v-if="visibleVideo"
    ></video-player>

      <div v-if="visiblePdf" style="width:100%;height:1000px;border:1px solid #999999;">
        <div style="width:30%; float:left;height: 1000px;border-right-width: thin;border-right-style: ridge;">
          <div style="width:100%; float:left;height: 500px;overflow: auto">
            <div style="text-align: center;    border-bottom-width: thin;border-bottom-style: ridge;">
              <span style="margin-top: 10px;font-size: 22px;line-height: 40px">目录</span>
            </div>
            <div style="overflow: auto;width: 100%;height: 450px">
              <template>
                <el-tree :data="treedata" :props="defaultProps" node-key="id" @node-click="handleNodeClick"></el-tree>
              </template>
            </div>
          </div>
          <div style="width:100%; float:left;height: 500px;">
            <div style="width:100%;float: left;text-align: center; border-top-width: thin;border-top-style: ridge;border-bottom-width: thin;border-bottom-style: ridge;">
              <div style="float: left;text-align: center;width: 70%"><span style="margin-top: 10px;font-size: 22px;line-height: 40px">书签</span></div>
              <div style="float: right;margin-right: 10px;width: 19%"><span style="font-size: 20px;cursor: pointer" title="设置书签" @click.stop="customerDataMarkAdd">+</span></div>
            </div>
            <div style="float: left;overflow: auto;height: 450px;width: 100%">
              <ul>
                <li style="margin-left: 5px;line-height: 20px;font-size: 16px;cursor: pointer" v-for="item in customerMark" :key="item.id" @click.stop="handleMark(item.EbookCatalogNum)">
                  <div class="box">
                    <span style="text-align: left;width: 75%">{{item.MarkName}}</span>
                    <span style="font-size: 20px;cursor: pointer;width: 20%" title="删除书签" @click.stop="customerDataMarkDel(item.id)">-</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div style="width:69%; float:right;height: 1000px;overflow: auto">
          <div style="height: 940px;margin-top: 10px">
          <template>
          <pdf style="margin-right: 5px;width: 98%;height: 98%"
                  ref="pdf"
                  :src="pdfUrl"
                  :page="pageNum"
                  >
          </pdf>
          </template>
          </div>
          <div style="height: 30px;width: 98%;float: left;font-size: 14px;text-align: center">
            <el-button type="primary"  style="padding: 10px;" tip="添加当前页为书签" @click.stop="customerDataMarkAdd">添加书签</el-button>
            <el-button-group>
              <el-button style="padding: 10px;margin-top: -1px;margin-left: 5px" type="primary" icon="el-icon-arrow-left" @click.stop="prePage()">上一页</el-button>
              <el-button style="padding: 10px;margin-top: -1px;margin-left: 5px" type="primary" @click.stop="nextPage()">下一页<i class="el-icon-arrow-right el-icon--right"></i></el-button>
            </el-button-group>
          </div>
        </div>

      </div>
  </div>
</template>

<script>
import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";
import pdf from "vue-pdf";
import api from "@/api/index.js";


export default {
  name: 'VuePdf',
  components: {
    videoPlayer,
    pdf
  },
  created() {
    if(this.$route.query.OrderID!=undefined){
      this.orderId = this.$route.query.OrderID;
      console.log("传过来的订单id:", this.orderId);
    }
    else if(this.$route.query.courseId!=undefined){
      this.courseId=this.$route.query.courseId;
      console.log("传过来的课程包id:", this.courseId);
    }
    //this.treeLoad();
    //this.pdfTask('/static/epub/zyzl.pdf');
    this.postDangekecehng();
  },
  data() {
    return {
      courseItemIds:'',
      courseId: "",
      orderId:"",
      CB_NAME:"",     //课程包名称即标题
      CB_DESCRIPTION:"",  //课程包描述即简介
      ProjectName:"",
      ProfessionName:"",
      courswareNum:"",
      CoursewaresCount:"",
      bagItemCount:"",
      VideosCount:"",
      testNum:0,
      pdfUrl: '',
      numPages: 10,
      pageNum:1,
      centerDialogVisible:false,
      alertContent:'',
      CourseBagCoursewares:[],
      CourseBagVideos:[],
      playerOptions: {
        // playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            src: "",
            type: "video/webm"
          }
        ],
        poster:
          "https://surmon-china.github.io/vue-quill-editor/static/images/surmon-1.jpg", //你的封面地址
        // width: document.documentElement.clientWidth,
        // width:'300px',
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true //全屏按钮
        }
      },
      itemshow: false,
      waresShow:false,
      videoShow:false,
      visibleVideo:false,
      visiblePdf:false,

      selection: null,
      treedata:[],
      defaultProps:{
        children: 'childen',
        label: 'name' // 这里的名字要和你封装的数据中的节点的名字一样
      },
      currentBookId:'',
      customerMark:[],
    };
  },

  methods: {
    customerDataMarkAdd(){

      api.postEbookCustomerAddMark({
        bookNum:this.pageNum,
        bookId:this.currentBookId,
        markName:this.pageNum,
      }).then(res=>{
        if(res.data==true)
        {
          this.customerDataMark(this.currentBookId)
        }
      });
    },
    customerDataMarkDel(markid){
      api.postEbookCustomerDeleteMark(markid).then(res=>{
        this.customerMark=res.data;
        this.customerDataMark(this.currentBookId)
      });
    },
    customerDataMark(bookid){
      api.postEbookCustomerMark(this.currentBookId).then(res=>{
        this.customerMark=res.data;
      });
    },
    handleNodeClick(data) {
      this.loading = true;
      var p = this.pageNum;
      p=data.EbookCatalogNum;
      if(p<=this.numPages) {
        this.pageNum = p;
      }
      else{
        this.pageNum =this.numPages;}
    },
    handleMark(data) {
      if(data<=this.numPages) {
        this.pageNum = data;
      }
      else{
        this.pageNum =this.numPages;}
    },
	pdfTask(pdfUrl){
        this.pdfUrl = pdf.createLoadingTask(pdfUrl)
        this.pdfUrl.promise.then(pdf => {
          this.numPages = pdf.numPages // 这里拿到当前pdf总页数
        }).catch((err) => {
            console.error('pdf加载失败')
        })
      },
    prePage(){
      var p = this.pageNum
      p = p>1?p-1:this.numPages
      this.pageNum = p
    },
    nextPage(){
      var p = this.pageNum
      p = p<this.numPages?p+1:1
      this.pageNum = p
    },
    // 查看单个 课程项目的全部内容
   postDangekecehng() {
      if(this.courseId!=""){
        api.postCourseBagInfo(this.courseId).then(res => {
          if(res.data!=null){
            this.CB_NAME=res.data.CB_NAME;
            this.CB_DESCRIPTION=res.data.CB_DESCRIPTION;
            this.ProjectName=res.data.ProjectName;
            this.bagItemCount=res.data.CB_ItemsCount;
            this.CoursewaresCount=res.data.CB_CoursewareCount;
            this.VideosCount=res.data.CB_VideoCount;
            this.ProfessionName=res.data.ProfessionName;
          }
        });
      }
     else if(this.orderId!=""){
        api.postCourseBagSingleAllGetByOrderID(this.orderId).then(res=>{
          if(res.data!=null){
            this.courseId=res.data.CB_ID;
            this.CB_NAME=res.data.CB_NAME;
            this.CB_DESCRIPTION=res.data.CB_DESCRIPTION;
            this.ProjectName=res.data.ProjectName;
            this.bagItemCount=res.data.CB_ItemsCount;
            this.CoursewaresCount=res.data.CB_CoursewareCount;
            this.VideosCount=res.data.CB_VideoCount;
            this.ProfessionName=res.data.ProfessionName;
          }
        });
      }
    },
   postCourseItemIDS() {
      api.postCourseBagItemIDListGet(this.courseId).then(res => {
        this.courseItemIds = res.data;
        this.courswareNum=res.data.length;
      });
    },
    subTest(){
            if(this.bagItemCount<=0){
              this.centerDialogVisible=true;
              this.alertContent='本课程包没有试题,不能进行模拟操作';
              return false;
            }
            api.canNumCb(this.courseId)
                .then(res=>{
                    if(res.data.Status==1){
                        this.testNum=res.data.rows
                        if(this.testNum==0){
                           this.centerDialogVisible=true;
                           this.alertContent='你的模拟考试次数已用完!';
                           return false;
                        }else{
                           this.centerDialogVisible=true;
                           this.alertContent='模拟考试需答完所有试题，不能中途退出。中途退出将失去（示为放弃）一次模拟机会。你还有'+this.testNum+'次模拟考试的机会，是否要考试,';
                        }
                    }else{
                        Toast(res.data.Msg);
                    }
                })
                .catch(err=>{
                    console.log(err)
                })
        },
    DialogVisible(){
      this.centerDialogVisible=false;
      this.alertContent="";
      if(this.testNum>0){
       this.$router.push({ name:'userTestList', query:{courseId:this.courseId} });
      }
    },
    aitemshow() {
      this.itemshow = !this.itemshow;
      this.videoShow = false;
      this.waresShow = false;
    },
    awareshow(){
      api.postCourseBagCousewares(this.courseId).then(res=>{
          console.log("rest:"+res);
          console.log("rest.data:"+res.data);
          this.CourseBagCoursewares=res.data;
      });
      this.waresShow = !this.waresShow;
      this.visiblePdf=false;
      this.videoShow = false;
      this.itemshow = false;
      this.visibleVideo=false;
    },
    avideoshow(){
      api.postCourseBagVideos(this.courseId).then(res=>{
          this.CourseBagVideos=res.data;
      });
      this.videoShow = !this.videoShow;
      this.visibleVideo=false;
      this.waresShow = false;
      this.itemshow = false;
      this.visiblePdf=false;
    },
    videoClick(obj){
      this.videoShow=!this.videoShow;
      this.visibleVideo=!this.visibleVideo;

      this.itemshow = false;
      this.waresShow = false;
      this.visiblePdf=false;
      var videoPath=obj;
      if(videoPath.indexOf("/") != 0){
	videoPath="/"+obj;
      }
      console.log(videoPath);
      this.playerOptions= {
        // playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            src: videoPath, // 路径
            type: "video/mp4" // 类型
          }
        ],
        poster: "", //你的封面地址
        // width: document.documentElement.clientWidth,
        // width:'300px',
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true //全屏按钮
        }
      }
    },
    waresClick(pdfUrl,EbookId){
      this.visibleVideo=false;
      this.videoShow=false;
      this.itemshow = false;
      this.waresShow = !this.waresShow;
      this.visiblePdf=!this.visiblePdf;
      this.visibleVideo=false;
      if(!pdfUrl.toString().startsWith("/"))
      {
        pdfUrl="/"+pdfUrl;
      }
      this.pdfTask(pdfUrl)
      this.treeLoad(EbookId);
      this.currentBookId=EbookId;
      this.customerDataMark(this.currentBookId)
    },
    treeLoad(bookid)
    {
      api.postEbookCatalog(bookid).then(res=>{
        this.treedata=res.data;
      });
    }
  }
};
</script>

<style>

  .box {
    width: 100%;
    /* 因为 content 会另起一行，影响样式的话，height 设置为具体的值可以避免高度变高的情况 */
    height: 100%;
    /* 设置元素两端对齐 */
    text-align: justify;
  }

  /* 这里的伪元素一定要加上，不然span元素不能两端对齐 */
  .box:after {
    content: "";
    display: inline-block;
    overflow: hidden;
    width: 100%;
  }

  .box span {
    width: 45%;
    height: 20px;
    /* 设置盒子为行内块 */
    display: inline-block;
    /* 设置盒子内元素水平居中 */
    text-align: center;
    /* 设置盒子内容垂直居中 */
    line-height: 20px;
  }

  .video-js .vjs-control {
  outline: none;
  }
.video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  transform: translate(-50% -50%);
}
.v-enter,
.v-leave-to {
  opacity: 0;
  transform: translateX(100px);
}
.v-enter-active,
.v-leave-active {
  transition: all 0.4s ease;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.dvCW {
  float: left;
  margin-top: -21px;
  z-index: 12;
  position: absolute;
}
.dvCW ul {
  margin: 0px 0 0 -20px;
  overflow: hidden;
}
.dvCW ul li {
  margin: 1px 0 0 20px;
  width: 226px;
  height: 30px;
  font-size: 18px;
  text-align: center;
  background-color: #BBFFFF;
  padding-top: 10px;
  font-family:Arial, Helvetica, sans-serif;
}
.dvwares{
  position: relative;
  float: inherit;
  z-index: 12;
}
.dvwares ul {
  margin: 6px 0 0 2px;
  width: 100%;
}
.dvwares ul li {
  margin: 1px 0 0 2px;
  width: 100%;
  font-size: 16px;
  height: 36px;
  text-align: left;
  padding-left: 5px;
  background-color: #FFFFFF;
  padding-top: 10px;
  font-family:Arial, Helvetica, sans-serif;
  border-bottom: 1px solid #eee;
}
.gobtn{ float: right; margin-right: 11px; }
.dvVideo{
  position: relative;
  float: inherit;
  z-index: 12;
}
.dvVideo ul {
  margin: 6px 0 0 2px;
  width: 100%;
}
.dvVideo ul li {
  margin: 1px 0 0 2px;
  width: 100%;
  font-size: 16px;
  height: 36px;
  text-align: left;
  padding-left: 5px;
  background-color: #FFFFFF;
  padding-top: 10px;
  font-family:Arial, Helvetica, sans-serif;
  border-bottom: 1px solid #eee;
}
.pdf-container{
  width: 100%;
  height: 600px;
}
</style>
