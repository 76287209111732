<template>
<div class="weCourse">
    <el-tabs v-model="activeName" @tab-click="handleClick" class="clearfix">
        <el-tab-pane label="全部课程" name="first" >
            <div class="weCourseList">
                <div class="weCourseHeader">
                    <ul>
                        <li class="weCourseHeaderOn">课件详细</li>
                        <li>类型</li>
                        <li>金额</li>
                        <li>状态</li>
                        <li>操作</li>
                    </ul>
                </div>
                <div class="weCourseItem" v-for="(item,index) in usCourseData" :key="index">
                    <div class="weCouItBox01">
                        <span>到期时间：{{item.EndDateTime.split("T")[0]+' '+item.EndDateTime.split("T")[1].substring(0,5)}}</span>
                        <!-- <span><strong>订单号</strong>293023203902309</span> -->
                    </div>
                    <div class="weCouItBox02">
                        <div class="weCouItBox03">
                            <img :src="item.PcUrl">
                            <p>{{item.CourseBagName}}</p>
                        </div>
                        <div class="weCouItBox04"><strong>医师定考</strong></div>
                        <div class="weCouItBox04">{{item.Price}}</div>
                        <div class="weCouItBox04">已激活</div>
                        <!-- <div class="weCouItBox04"><el-button type="primary" size="mini">去学习</el-button></div> -->
                        <div class="weCouItBox04">
                            <el-button type="primary" size="mini" @click="learnCourse(index,item)">去学习</el-button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wuImg" v-if="usCourseData == '' ? true:false"><img src="@/assets/images/bcym01.png"></div>
            <div class="coursePage coursePageBox " v-if="pageRows > 1 ? true:false">
                <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="pageRows"
                    :page-size="pageSize"
                    :current-page="currentPage"
                    @current-change="handleCurrentChange">
                </el-pagination>
            </div>
        </el-tab-pane>
        <!-- <el-tab-pane label="已学习" name="second">
            <div class="wuImg"><img src="@/assets/images/bcym01.png"></div>
        </el-tab-pane>
        <el-tab-pane label="未学习" name="third">
            <div class="wuImg"><img src="@/assets/images/bcym01.png"></div>
        </el-tab-pane> -->
    </el-tabs>
</div>

</template>

<script>
import api from "@/api/index.js";
export default{
    created(){
        this.weCourseBagList();
        this.weCourseBagListPage();
    },
data() {
      return {
        activeName: 'first',
        usCourseData:'',
        currentPage:1,
        pageRows:null,
        pageSize:5,
        }
},
methods:{
    handleClick(tab, event){
        console.log(tab, event);
    },
    // 我的课程
    weCourseBagList(){
        api.getTCustomer_CourseBagList()
        .then(res=>{
            console.log('我的课程包:',res)
        })
        .catch(err=>{
            console.log(err)
        })
    },
    // 我的课程分页
    weCourseBagListPage(){
        api.postTCustomer_CourseBagListByPage({
            page:this.currentPage,
            rows:this.pageSize,
        })
        .then(res=>{
            console.log('分页我的课程包:',res)
            if(res.data.Status==1){
                this.usCourseData = res.data.rows;
                this.pageRows = res.data.total;
            }
            console.log(this.usCourseData);
            
        })
        .catch(err=>{
            console.log(err)
        })
    },
    funt(){
        if(document.body.scrollTop){
                document.body.scrollTop = 0
            }else {
                document.documentElement.scrollTop = 0
            }
    },
    // 分页
      handleCurrentChange(val){
            this.currentPage=val;
            this.weCourseBagListPage();
            setTimeout(this.funt,200)
            
        },
      // 去学习
      learnCourse(index, row) {
        console.log(index, row);
        console.log("CourseBagId"+row.CourseBagId);
        this.$router.push({
          name: 'userCourselearn',
          query: {
            courseId:row.CourseBagId
          }
        })
      },
}
}

</script>

<style>
</style>