<template>
  <div>
    <div class="loginTop on">
      <div class="Main">
        <div class="Navigator_box01">
          <a href="#" title="医+E平台">
            <img src="../assets/images/logo01.png">
          </a>
          <span></span>
          <img id="bbbb" src="../assets/images/backPasswordBg.png">
        </div>
        <div class="Navigator_box02 Logo_right">
          <el-button type="primary" round>
            <router-link :to="{name:'index'}">返回首页</router-link>
          </el-button>
        </div>
      </div>
    </div>
    <div class="registerMiddle">
      <div class="registerContent">
        <div class="registerConLeft">
          <div class="registerTitle">
            <span>找回密码</span>
          </div>
          <el-form
            :model="ruleForm"
            status-icon 
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
            hide-required-asterisk
          >
            <el-form-item label="手机号码：" prop="phone" :rules="rules.phone">
              <el-input  v-model="ruleForm.phone" placeholder="请输入您的手机号"></el-input>
              <!-- <div class="regiLeftDivBox" v-else>重新发送(40)</div> -->
            </el-form-item>
            <el-form-item label="验证码：" prop="imgInputCode">
              <el-input class="regiLeftInput01" v-model="ruleForm.imgInputCode" placeholder="请输入图形验证码"></el-input>
              <!-- <strong><img @click="z_onCodeImg" :src="z_codeImg"></strong> -->
              <img @click="z_onCodeImg" :src="z_codeImg" alt="验证码">
            </el-form-item>
            <el-form-item label="手机验证码：" prop="PhoneVerifyCode">
              <el-input class="regiLeftInput01" v-model="ruleForm.PhoneVerifyCode" placeholder="请输入您的手机验证码"></el-input>
              <div class="regiLeftDivBox"  @click="onPhoneCode">获取验证码</div>
            </el-form-item>
            <el-form-item label="设置密码：" prop="password">
              <el-input type="password" v-model="ruleForm.password" placeholder="密码需含数字、字母、特殊字符且至少8位"></el-input>
            </el-form-item>
            <el-form-item label="确认密码：" prop="rePassword">
              <el-input type="password" v-model="ruleForm.rePassword" placeholder="请再次输入密码"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm('ruleForm')">确认提交</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="registerConRight">
          <div class="re_ma_r_box01">
            已有账号
            <router-link :to="{name:'login'}">直接登录</router-link>
          </div>
          <div class="re_ma_r_box02">
            <div>
              <img src="../assets/images/erweima.jpg">
            </div>
          </div>
          <div class="re_ma_r_box03">
            <h3>温馨提示：</h3>
            <p>1.目前仅支持电子邮箱及中国大陆手机号注册。</p>
            <p>2.如果已有账号可以直接点击上方“直接登录”</p>
            <p>3.关注上方“医+E”微信公众号，随时随地学习</p>
          </div>
        </div>
      </div>
    </div>
    <div class="loginBottom on">
      <p>
        <a href="http://www.doc-exam.com/contact_us.html" target="_blank">关于我们</a>|
            <router-link :to="{name:'helpAgreement'}">服务条款</router-link>|
            <router-link :to="{name:'helpProblem'}">帮助中心</router-link>|
            <router-link :to="{name:'helpStatement'}">法律声明</router-link>|
            <router-link :to="{name:'helpNew'}">新手上路</router-link>|
            <a href="http://www.doc-exam.com/about_lxwm.html" target="_blank">联系我们</a>|
            <a href="http://www.doc-exam.com/recruitment.html" target="_blank">招聘信息</a>
      </p>
      <p>
        <span>©医东网络科技发展（上海）有限公司版权所有</span>
        <span>沪公网安备 31010902001559号</span>
        <span>沪ICP备14003938号-2</span>
      </p>
    </div>
  </div>
</template>

<script>
import loginInput from "@/components/login/loginInput.vue";
import api from "@/api/index.js";
// import axios from "axios"
// import { validateMoneyNumber,qq,mobile,regexn,integer} from '@/utils/validate'
export default {
  created(){
    this.z_onCodeImg();
  },
  data() {
    const isvalidateInteger= (rule, value, callback) => {        
          if(value != null && value != "") {
              if(!integer(value)) {
                  callback(new Error('请输入正整数!'))
              } else {
                  callback()
              }
          }
         else{
             callback();
         }
      }
      var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else {
          if (this.ruleForm.password !== '') {
            this.$refs.ruleForm.validateField('rePassword');
          }
          callback();
        }
      };
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.ruleForm.password) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
    return {
      // isTime:false,
      isAsterisk:false,
      z_codeImg:'',
      uid:null,
      ruleForm: {
        // userName: "",
        password: "",
        rePassword: "",
        imgInputCode:'',
        phone:'',
        PhoneVerifyCode:'',
      },
      rules: {
        // userName: [
        //   { required: true, message: "请输入您的昵称", trigger: "blur" },
        //   { min: 3, max: 8, message: "长度在 3 到 5 个字符",  trigger: "blur"  },
        //   {
        //     pattern: /^(\w){3,10}$/,
        //     message: '只能输入3-10个字母、数字、下划线'
        //   }
          
        // ],
        phone: [
          { required: true, message: "请输入您的手机号", trigger: "blur" },
          {validator:(rule,value,callback)=>{
            console.log(this);
            if(/^1[34578]\d{9}$/.test(value) == false){
              callback(new Error("请输入正确的手机号"));
              this.isAsterisk=false
            }else{
              console.log(233)
              this.isAsterisk=true;
              console.log(this);
              console.log(this.isAsterisk)
              callback();
            }
          }, trigger: 'blur'}//  pattern: /^1[34578]\d{9}$/, message: '目前只支持中国大陆的手机号码' }
        ],
        imgInputCode: [
          { required: true, message: "请输入图形验证码", trigger: "blur" },
          // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" }
          // {validator:isvalidateInteger,trigger:'blur'}
        ],
        PhoneVerifyCode: [
          { required: true, message: "请输入手机验证码", trigger: "blur" },
          { min: 3, max: 6, message: "长度在 3 到 5 个字符", trigger: "blur" }
        ],
        password: [
          // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
            { validator: validatePass, trigger: 'blur' }
          ],
          rePassword: [
            // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
            { validator: validatePass2, trigger: 'blur' }
          ],
      }
    };
  },
  components: {
    loginInput
  },
  methods: {
      // 获取手机验证码
      onPhoneCode(){
        // console.log(this.isAsterisk)
        if(this.isAsterisk){
          this.isTime=true;
          api.getPhoneCode({
            phone: this.ruleForm.phone,
            imgInputCode: this.ruleForm.imgInputCode,
            uid:this.uid,
            Type:this.uid,
        }).then(res=>{
          console.log(res.data);
          if(res.data.Status==2){
            this.$message({
              message:res.data.Msg,
              type: 'warning',
              offset:"100"
            });
          }
        })
        }
        // if(this.ruleForm.imgInputCode==""){
        //   this.$message({
        //       message:'图像验证码不能为空',
        //       type: 'warning',
        //     });
        //   return false;
        // }
        if(this.ruleForm.phone==""){
          console.log(111)
          this.$message({
              message:'手机号码不能为空',
              type: 'warning',
              offset:100
            });
        }
      },
      // 获取图形验证码
      z_onCodeImg(){
        this.uid=(new Date()).getTime();
        console.log(this.uid);
        api.geCodeImg({
          params:{
            uid:this.uid,
          }
        }).then(res=>{
        
        this.z_codeImg="/api/CustomerAccount/VerifyRegister?uid=" + this.uid;
      }).catch(function (error) {
        console.log(error);
      });
      },
      // 点击注册
      submitForm(formName) {
          console.log(this.userName,this.password)
         this.$refs[formName].validate((valid) => {
           if(this.ruleForm.phone==''){
             return false;
           }else if(this.ruleForm.imgInputCode==''){
             return false;
           }else if(this.ruleForm.PhoneVerifyCode==''){
             return false;
           }else if(this.ruleForm.password==''){
             return false;
           }else if(this.ruleForm.rePassword==''){
             return false;
           }
          api.postForgotPassword({
              // A_Name: this.ruleForm.phone,
                newPassword: this.ruleForm.password,
                phone:this.ruleForm.phone,
                // A_LoginAccount:this.ruleForm.phone,
                // VerifyCode:this.ruleForm.VerifyCode,
                PhoneVerifyCode:this.ruleForm.PhoneVerifyCode,
                confirmPassword: this.ruleForm.rePassword
          }).then(res=>{
            console.log(res)
            if(res.data.Status==1){
              this.$message({
                      message:"密码修改成功，请登录",
                      type: 'success',
              });
              this.$router.push({name:'login'});
            }else{
              this.$message({
                      message:res.data.Msg,
                      type: 'warning',
              });
            }
          }) .catch(function (error) {
              console.log(error);
            });
        });
      },
  }
};
</script>

<style>
/* .loginTop {
  background: #fff;
}
.loginTop.on {
  position: relative;
  z-index: 999;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
}
div.Main {
  width: 1200px;
  margin: 0px auto;
  overflow: hidden;
}
div.Navigator_box01 {
  float: left;
  height: 78px;
  line-height: 78px;
}
div.Navigator_box01 img {
  vertical-align: middle;
}
div.Navigator_box01 span {
  display: inline-block;
  width: 1px;
  height: 31px;
  background: #dddddd;
  position: relative;
  top: 12px;
  margin: 0px 20px 0px 20px;
}
div.Navigator_box02 {
  float: right;
  height: 78px;
  line-height: 78px;
}
div.Navigator_box02 img {
  vertical-align: middle;
}
div.Logo_right {
  width: 504px;
  background: url(../assets/images/login_right.png) bottom right no-repeat;
  text-align: right;
}
div.Logo_right img {
  position: relative;
  right: -200px;
}
div.Logo_right .el-button.is-round {
  padding: 6px 0px;
}
div.Logo_right a {
  color: #fff;
  display: block;
}
div.Logo_right span {
  display: block;
  width: 104px;
}
.registerMiddle {
  height: 500px;
  background: #f0f4f5;
  padding: 25px 0 30px 0;
}
.registerContent {
  width: 1200px;
  margin: 0 auto;
  height: 100%;
  border-top: 5px solid #1f78bd;
  background: #fff;
}
.loginBottom {
  padding: 20px 0 15px 0;
  background: #f3f3f3;
  text-align: center;
  font-size: 14px;
  color: #999;
}
.loginBottom p {
  line-height: 28px;
}
.loginBottom p a {
  margin: 0 9px 0 7px;
  color: #999;
}
.loginBottom p a:hover {
  color: #28a7e1;
}
.loginBottom span {
  margin-right: 5px;
}
.loginBottom.on {
  position: relative;
  z-index: 999;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.12), 0 -2px 6px 0 rgba(0, 0, 0, 0.04);
}

.registerConLeft {
  float: left;
  width: 877px;
  height: 470px;
  border-right: 1px solid #eee;
}
.registerConRight {
  float: right;
  width: 260px;
  padding: 0 30px;
  margin-top: 35px;
}
.registerTitle {
  margin: 30px 20px 0 20px;
  line-height: 40px;
  font-size: 18px;
  position: relative;
  z-index: 10;
}
.registerTitle span {
  display: inline-block;
  height: 100%;
  line-height: 100%;
  padding: 0 10px;
  background: #fff;
  position: relative;
  z-index: 1000;
  margin-left: 40px;
}
.registerTitle::after {
  content: "";
  width: 100%;
  height: 1px;
  background: #eee;
  position: absolute;
  top: 50%;
  left: 0;
}

.registerConRight div.re_ma_r_box01 {
  background: url(../assets/images/re_ma_r_box01.png) 50px top no-repeat;
  text-align: center;
  height: 36px;
  line-height: 24px;
  text-indent: 30px;
  font-size: 14px;
}
.registerConRight div.re_ma_r_box01 a {
  color: #00a8e0;
}
.registerConRight div.re_ma_r_box02 {
  width: 100%;
  text-align: center;
  padding-top: 10px;
}
.registerConRight div.re_ma_r_box02 div img {
}
.re_ma_r_box03 {
  text-align: left;
}
.re_ma_r_box03 h3 {
  font-size: 14px;
  padding: 5px 0 10px 0;
}
.re_ma_r_box03 p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 5px;
  color: #666;
}
.registerConLeft .el-form {
  width: 400px;
  margin: 0 auto;
}
.registerConLeft .regiLeftInput01 {
  width: 70%;
}
.registerConLeft .el-form-item__content strong {
  display: inline-block;
  cursor: pointer;
  width: 80px;
  margin-left: 10px;
  text-align: center;
  font-weight: normal;
  background: #eee;
}
.registerConLeft .el-form-item__content .regiLeftDivBox {
  display: inline-block;
  cursor: pointer;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-size: 12px;
  width: 80px;
  margin-left: 10px;
  text-align: center;
}
.registerConLeft .el-button--primary {
  width: 100%;
}
.registerConLeft .el-button--primary span {
  background: none;
  font-size: 16px;
} */
</style>