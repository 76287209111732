<template>
<div class="help">
    <div class="helpBanner"></div>
    <div class="helpContent">
        <div class="helpWidth">
            <div class="helpConLeft">
                <div class="helpNavList">
                        <dt>帮助中心</dt>
                        <dd class="active"><router-link :to="{name:'helpProblem'}">常见问题</router-link></dd>
                        <dd><router-link :to="{name:'helpNew'}">新手上路</router-link></dd>
                        <!-- <dd><router-link :to="{name:'helpMap'}">站点地图</router-link></dd> -->
                    </div>
                    <div class="helpNavList">
                        <dt>服务条款</dt>
                        <dd><router-link :to="{name:'helpStatement'}">法律声明</router-link></dd>
                        <dd><router-link :to="{name:'helpAgreement'}">服务协议</router-link></dd>
                        <dd><router-link :to="{name:'helpPrivacy'}">隐私条款</router-link></dd>
                        <dd><router-link :to="{name:'helpLiability'}">免责条款</router-link></dd>
                    </div>
            </div>
            <div class="helpConRight">
                <router-view></router-view>
            </div>
        </div>
    </div>
</div>

</template>

<script>
export default{

}

</script>

<style>

</style>