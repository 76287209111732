<template>
<div class="indexMain">
    <div class="indexBanner"><banner-tu></banner-tu></div>
    <div class="indexListBox01">
        <div class="wrapSer">
            <div class="indexTitle" id="box01"><img src="../assets/images/xiangmufuwu.png"></div>
            <div class="indexService">
                <div class="indexSerItem">
                    <div class="indexSerTop"><img src="../assets/images/yishidingqikaohe.png"></div>
                    <div class="indexSerButtom">
                        <h3>医师定期考核</h3>
                        <p>医师定期考核不仅是深化医药卫生体系改革的重要组成部分，更是建立和完善医师准入与退出机制，促进医师知识更新，提高医师执业水平和执业能力，确保医疗质量与医疗安全的重要手段。</p>
                        <!-- <a href="#">了解更多</a> -->
                        <router-link :to="{name:'BusinessAreaIndex'}">了解更多</router-link>
                    </div>
                </div>
                <div class="indexSerItem indexSerItemRight">
                    <div class="indexSerTop"><img src="../assets/images/feigongliyiliao.png"></div>
                    <div class="indexSerButtom">
                        <h3>非公立医疗机构信用与能力评价</h3>
                        <p>非公立医疗机构信用与能力评价是由协会组织相关专家，根据评价标准，按照规定程序，对申报的非公立医疗机构进行信用与能力专业性、技术性综合评价。</p>
                        <router-link :to="{name:'newCourseList',params:{id:'37a00d8e-1e5f-48ca-98a0-200eee4d3bb5'}}">了解更多</router-link>
                    </div>
                </div>
                <div class="indexSerItem indexSerItemRight">
                    <div class="indexSerTop"><img src="../assets/images/hulizhuanyepeixun.png"></div>
                    <div class="indexSerButtom">
                        <h3>护理专业培训</h3>
                        <p>护理专业培训是稳定和加强护理队伍建设，提高护理人文关怀意识，树立正确的执业价值观念，将护理理论和临床实践有机结合，掌握基本及专科技能操作规范的重要手段。</p>
                         <router-link :to="{name:'newCourseList',params:{id:'37a00d8e-1e5f-48ca-98a0-200eee4d3bc5'}}">了解更多</router-link>
                    </div>
                </div>
                <!-- <div class="indexSerItem indexSerItemRight serviceOnTop243">
                    <div class="indexSerLeft indexCol04"><img src="../assets/images/indexSer04.png"></div>
                    <div class="indexSerRight">
                        <h3>医院管理培训</h3>
                        <p>医院管理培训帮助医院高层管理者掌握国内外战略管理规划、质量管理发展与临床路径、医院品牌建设、资本运营与管理、现代医院领导力。打造现代医院高层职业管理经营者。</p>
                        <router-link :to="{name:'service',query:{name:'hospital',id:'001'}}">了解更多</router-link>
                    </div>
                </div>
                <div class="indexSerItem serviceOnTop486">
                    <div class="indexSerLeft indexCol05"><img src="../assets/images/indexSer05.png"></div>
                    <div class="indexSerRight">
                        <h3>专科医师培训</h3>
                        <p>专科医师规范化培训是国际上公认的临床医学专家成长的必由之路，对保证临床医师的专业水准、加强卫生人才队伍建设和提供高质量的医疗服务具有重要的、不可替代的作用。</p>
                        <router-link :to="{name:'service',query:{name:'subject',id:'002'}}">了解更多</router-link>
                    </div>
                </div> -->
                <!-- <div class="indexSerItem indexSerItemRight serviceOnTop243">
                    <div class="indexSerLeft indexCol06"><img src="../assets/images/indexSer01.png"></div>
                    <div class="indexSerRight">
                        <h3>学协会信息化及会员服务系统</h3>
                        <p>学协会官网网站建设是针对学协会网站及会员管理建设的一站式在线信息化建设。除了实现学协会建立自己门户网站和管理自己会员的需要外，还提供更多个性化的定制开发，满足学协会各项需求。</p>
                        <router-link :to="{name:'service',query:{name:'learnSociety',id:'003'}}">了解更多</router-link>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
    <div class="indexListBox02">
        <div class="wrapSer">
            <div class="indexTitle">我们的优势</div>
            <div class="indexAdvantage">
                <div class="AdvantageItem">
                    <div class="advantLeft indexCol01">
                        <img src="../assets/images/indexAdvant03.png">
                    </div>
                    <div class="advantRight">
                        <h3>海量专业题库支撑</h3>
                        <p>涵盖临床、护理、中医、非公双评、口腔、公共卫生等各专业。</p>
                    </div>
                </div>
                <div class="AdvantageItem">
                    <div class="advantLeft indexCol06">
                        <img src="../assets/images/indexAdvant02.png">
                    </div>
                    <div class="advantRight">
                        <h3>强大专家团队</h3>
                        <p>涵盖社会医学与卫生事业管理、临床医学、药学、护理、医技、财务管理、信用管理、信息、法律等各专业。</p>
                    </div>
                </div>
                <div class="AdvantageItem">
                    <div class="advantLeft indexCol02">
                        <img src="../assets/images/indexAdvant01.png">
                    </div>
                    <div class="advantRight">
                        <h3>互联网+专业技术</h3>
                        <p>负责搭建网站、系统开发、站点维护、数据库维护、域名维护。</p>
                    </div>
                </div>
                <div class="AdvantageItem">
                    <div class="advantLeft indexCol03">
                        <img src="../assets/images/indexAdvant04.png">
                    </div>
                    <div class="advantRight">
                        <h3>专业医学编辑团队</h3>
                        <p>负责政策解读、学术科研、学术活动、继续教育、题库、健康科普文章等的编辑工作。</p>
                    </div>
                </div>
                <div class="AdvantageItem">
                    <div class="advantLeft indexCol04">
                        <img src="../assets/images/indexAdvant05.png">
                    </div>
                    <div class="advantRight">
                        <h3>技术优势</h3>
                        <p>获得多项计算机软件著作权，获得软件安全开发三级服务资质、信息系统安全集成三级服务资质、获得高新技术企业证书。</p>
                    </div>
                </div>
                <div class="AdvantageItem">
                    <div class="advantLeft indexCol05">
                        <img src="../assets/images/indexAdvant06.png">
                    </div>
                    <div class="advantRight">
                        <h3>网络信息开发、集成、运维安全</h3>
                        <p>包含考核登记系统、题库系统、培训系统、考试系统、OA系统、会员系统、新闻发布系统等</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="indexListBox03">
        <div class="wrapSer">
            <div class="indexTitle">每周一题</div>
            <div class="indexNews clearfix">
                <div class="indexNewsBox01">
                    <div class="newsTitle">
                        <span><img src="../assets/images/news01.png">公司介绍</span>
                    </div>
                    <div class="indexNewsTu"><img src="../assets/images/news02.png"></div>
                </div>
                <div class="indexNewsBox02">
                    <div class="newsTitle">
                        <span><img src="../assets/images/news03.png">每周一题</span><router-link :to="{name:'dayTopic'}">查看更多</router-link>
                    </div>
                    <div class="indexAssess">
                         <ul class="textList">
                            <li v-for="(item,index) in dayTopic.rows"  :key="index"><router-link :to="{name:'dayTopicDetail',params:{id: item.MQ_ID}}">
                                    <p>{{item.MQ_Title}}</p><span>{{item.DisplayDate}}</span>
                                </router-link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</div>

</template>

<script>
import bannerTu from '@/components/main/banner.vue'
// import axios from "axios";
import api from "@/api/index.js";
export default{
    components:{
        bannerTu,
    },
    created(){
        this.$nextTick(() => {this.getlocal()})
        // api.getDayTopic({
        //   params: {
        //     T_TypeName:'',
        //     StartCreateDate:'',
        //     EndCreateDate:'',
        //     T_Type:'',
        //     pageIndex: 1,
        //     pageSize: 7
        //   }
        // })
        // .then(res => {
        //   console.log("每日一题:",res.data);
        //   this.dayTopic=res.data
        // }).catch(function (error) {
        //  console.log(error);
        // });
    },
    data(){
        return {
            dayTopic:{},
        }
    },
    mounted () {
        let that = this;
            that.$nextTick(function(){
                window.addEventListener('scroll',that.handleScroll)
            })
            console.log("mounted")
        // this.$nextTick(()=>[
        //             this.goAnchor(window.location.hash)
        //         ])
        var indexService = document.getElementsByClassName('indexService')[0].offsetTop;
        if(!indexService){
            return false;
        }else{window.addEventListener('scroll', this.handleScroll)}
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        var viewHeight = window.innerHeight-100;
        if(indexService-scrollTop<viewHeight){
            document.getElementsByClassName('indexSerItem')[0].classList.add("serviceOn_l")
            document.getElementsByClassName('indexSerItem')[1].classList.add("serviceOn_r")
        }


    },
    methods:{
        //从我本地找到id
            getlocal(){
                //找到锚点id
                let selectId = sessionStorage.getItem("toId");
                let toElement = document.getElementById(selectId);
                //如果对应id存在，就跳转
                if(selectId){
                    toElement.scrollIntoView()
                }
            },
        // 首页动画
        handleScroll () {
            var NewsBox01 = document.getElementsByClassName('indexNewsBox01')[0];
            if(!NewsBox01){
                return false;
            }
            var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            var NewsBox01 = document.getElementsByClassName('indexNewsBox01')[0];
            var NewsBox02 = document.getElementsByClassName('indexNewsBox02')[0];
            var indexAdvantage = document.getElementsByClassName('indexAdvantage')[0];
            var arr = document.getElementsByClassName('indexNews')[0].offsetTop;
            var Advantage = document.getElementsByClassName('indexListBox02')[0].offsetTop;
            var indexService = document.getElementsByClassName('indexService')[0].offsetTop;
            var viewHeight = window.innerHeight-100;
            if(arr-scrollTop<viewHeight){
                NewsBox01.classList.add("indexNewOn01");
                NewsBox02.classList.add("indexNewOn02");
            }
            if(Advantage-scrollTop<viewHeight-100){
                indexAdvantage.classList.add("advantageOn");
            }
            if(indexService-scrollTop<viewHeight){
                document.getElementsByClassName('indexSerItem')[0].classList.add("serviceOn_l")
                document.getElementsByClassName('indexSerItem')[1].classList.add("serviceOn_r")
            }
            if(indexService-scrollTop<viewHeight-100){
                document.getElementsByClassName('indexSerItem')[2].classList.add("serviceOn_l")
                //document.getElementsByClassName('indexSerItem')[3].classList.add("serviceOn_r")
            }
            // if(indexService-scrollTop<viewHeight-200){
            //     document.getElementsByClassName('indexSerItem')[4].classList.add("serviceOn_l")
            //     document.getElementsByClassName('indexSerItem')[5].classList.add("serviceOn_r")
            // }
            // console.log(scrollTop,arr,viewHeight)

        },
    },
    destroyed(){
            sessionStorage.setItem("toId",'')
        }
}

</script>

<style>
/* .indexBanner{height: 400px;}
.indexMain{ margin-top: 0px}
.indexListBox01{ background: url(../assets/indexBg01.jpg) center center; height: 858px;}
.indexListBox01{ overflow: hidden;}
.wrapSer{width: 1200px; margin: 0 auto;}
.indexTitle{ font-size: 24px; text-align: center;padding:40px 0;}
.indexService{ margin-left: -60px;  position: relative;}
.indexSerItem{  float: left; width: 380px; height: auto;  left: -100%;transition: all 1s linear; margin:0 0 20px 30px; background:rgba(255,255,255,0.5);-moz-box-shadow:0px 0px 25px #eee; -webkit-box-shadow:0px 0px 25px #eee; box-shadow:0px 0px 25px #eee;}
.indexSerItem.indexSerItemRight{left:auto;right: -100%;}
.indexSerTop{ width: 166px; height: 173px; background: #629bd5; position: relative;}
.indexSerTop img{ position:relative; top: 50%; left: 50%; transform: translate(-50%,-50%)}
.indexSerButtom{float:left; width: 370px; margin-right: 25px;}
.indexSerButtom h3{ font-size: 18px; color: #0e6eb8; font-weight: normal;padding:15px 0 5px 0;}
.indexSerButtom p{ font-size: 14px; line-height: 28px; }
.indexSerButtom a{ display: block; margin-top: 10px; width: 120px; height: 32px; color: #0e6eb8; border: 1px solid #0e6eb8; font-size: 14px; text-align: center; line-height: 32px;}
.indexSerButtom a:hover{color: #fff; background: #0e6eb8;}
.indexCol01{ background: #0e6eb8;}
.indexCol02{ background: #62d5a0;}
.indexCol03{ background: #d3a62d;}
.indexCol04{ background: #62ced5;}
.indexCol05{ background: #9b6add;}
.indexCol06{ background: #cb833d;}
.serviceOn_l{ left: 0px;}
.indexSerItem.indexSerItemRight.serviceOn_r{ right: 0px;}
.indexSerItem.serviceOnTop243{ top: 243px;}
.indexSerItem.serviceOnTop486{top: 486px;}

.indexListBox02{ background: #f1f5f7; height:428px; position: relative;}
.indexAdvantage{ margin-left: -52px; position:absolute;transition: all 1s linear; top:400px; }
.AdvantageItem{ float: left; width: 360px; margin-left: 52px;height: 145px;}
.advantLeft{ float: left; width: 82px; height: 82px; border-radius: 50%; position: relative; }
.advantLeft img{position:absolute; top: 50%; left: 50%; transform: translate(-50%,-50%)}
.advantRight{ float: right;width: 265px; font-size: 14px; }
.advantRight h3{ font-size: 16px;}
.advantRight p{color: #666; line-height: 28px; margin-top: 5px;}

.indexListBox03{background: url(../assets/indexBg02.jpg) center center; height: 630px; overflow: hidden;}
.indexNews{ background: rgba(255,255,255,.7); position: relative; height: 419px;}
.indexNews{ padding:20px;}
.indexNewsBox01{ border: 1px solid #ccc; width: 563px; float: left;height: 419px; transition: all 1s linear;position:absolute; left:-100%;}
.newsTitle{ margin: 0 20px; border-bottom: 1px solid #ccc; line-height: 40px; }
.newsTitle a{ float:right; font-size: 12px; border: 1px solid #999; border-radius: 20px; line-height: 20px; height: 20px; color: #999; padding: 0 10px; margin-top: 10px;}
.newsTitle a:hover{border: 1px solid #0e6eb8; color:#0a84e0;}
.newsTitle img{ margin-right: 10px; position: relative; top: 5px;}
.indexNewsTu{ text-align: center; margin-top: 15px;}
.indexNewsBox02{border: 1px solid #ccc; width: 563px; float: right; height: 419px; transition: all 1s linear; position:absolute; right:-100%;}
.indexNewOn01{ left:20px;}
.indexNewOn02{ right:20px;}
.advantageOn{ top:120px;}

.indexAssess{}
.textList{}
.textList li{line-height: 54px; height: 54px; padding:0 20px;}
.textList li a{ padding:0 0px 0 17px; font-size: 14px;  border-bottom: 1px dashed #eee; overflow: hidden; background: url(../assets/images/yuan.png) left center no-repeat; display: block; }
.textList li a p{ float: left; margin:0; max-width:410px;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;}
.textList li a:hover{ color: #3f90ea; }
.textList li a span{ float: right; color: #999; }
.textList li:last-child a{border:none;} */
</style>
