<template>
<div class="hederLeft clearfix">
    <div class="headerLogo"><router-link :to="{name:'index'}"><img src="@/assets/logo01.png"></router-link></div>
    <div class="areaImgTop">{{areaNamebb}}医师定期考核培训课程</div>
</div>

</template>

<script>
export default{
    props:{
        areaNamebb: {
            type: String,
            default: ''
        }
    },
    methods:{
        goto(page,selectId){
                var path = page;
                var selectId = selectId;
                sessionStorage.setItem("toId",selectId);
                this.$router.push({path:'/'+ path});
            }
    },
     
    created(){
        console.log('oooo',this.areaNamebb)
    }
}

</script>

<style>
@import url(../../assets/css/base.css);
.hederLeft{ height: 78px;}
.headerLogo{ float: left;height: 78px; line-height: 78px;}
.headerLogo img{  margin-right: 40px;  vertical-align: middle;}
.logoNav{ float: left;}
.logoNav li{ float: left; font-size: 16px; margin-right: 25px; }
.logoNav li a{ display: block; line-height: 78px; position: relative;}
.logoNav li a:hover{ color:#0e6eb8;}
.logoNav li a.active{ color:#0e6eb8; }
.logoNav li a.active:after{ position:absolute; content: ''; width: 100%; height: 3px;background: #0e6eb8; bottom: 0px; left: 0; }
.codeOn{ position: relative;}
.codeOn:hover img{display: block;}
.codeOn img{ display: none; position:absolute; bottom:-199px; left: 50%; transform: translateX(-50%);}
</style>