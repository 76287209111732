<template>
<div class="courseBg">
    <div class="indexBanner"><banner-tu></banner-tu></div>
    <div class="courseMain clearfix">
        <div class="courseContent" v-loading="loading">
            <h3 class="h3text" style="width:100%"><span>医师定期考核--业务地区</span></h3>
            <div class="courseMainList newCouseMain">
                <div class="courseColList" v-for="(item,index) in areaData" :key="index"  :title="item.BA_Title">
                    <router-link :to="{name:'areaCourseShow',params:{id:item.BA_AreaCode}}"><img :src="item.BA_ImgPath" class="image"></router-link>
                    <h4><router-link :to="{name:'areaCourseShow',params:{id:item.BA_AreaCode}}">{{item.AreaName}}</router-link></h4>
                    <div style="padding:5px 0px;" class="courseItemTitle">
                        <hr />
                        <h3><router-link :to="{name:'areaCourseShow',params:{id:item.BA_AreaCode}}">{{item.BA_Subtitle}}</router-link></h3>
                    </div>
                </div>
            </div>
            <el-button round  v-if="isVisableButton" @click="moreCourses">更多</el-button>
            <div v-if="isPaging" class="coursePage">
                <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="pageRows"
                    :page-size="pageSize"
                    :current-page="currentPage"
                    @current-change="handleCurrentChange">
                </el-pagination>
            </div>
        </div>
    </div>
</div>


</template>

<script>
import Vue from 'vue'
import bannerTu from '@/components/main/banner.vue'
import coursePage from '@/components/course/coursePage.vue'

import api from "@/api/index.js";
import { setTimeout } from 'timers';
export default{
    created(){
        this.initList();
    },
    components:{
        bannerTu,
        coursePage
    },
    data(){
        return{
            loading: true,
            isPaging:false,
            isVisableButton:true,
            ProName:'',
            projiectType:'',
            paramsId:'',
            projectCodeArr:'',
            currentPage:1,
            pageRows:null,
            pageSize:8,
            areaData:[],

        }
    },
    methods:{
        // 初始化加载课程列表
        initList(){
            api.postGetBusinessAreaList({
                page: this.currentPage,
                rows: this.pageSize
            }).then(res=>{
                this.areaData=res.data.rows;
                this.pageRows=res.data.total;
                this.loading=false;
                console.log("课程列表:",res);
            }).then(res=>{

            })
            .catch(error=> {
                    console.log(error);
            })
        },
        moreCourses(){
            this.isPaging=true;
            this.isVisableButton=false;
            this.pageSize=20;
            this.initList();
        },
        handleCurrentChange(val){
            this.currentPage=val;
            this.moreCourseList();
            setTimeout(function(){
                if(document.body.scrollTop){
                    document.body.scrollTop = 0
                }else {
                    document.documentElement.scrollTop = 0
                }
            },300)
        },
    },
}

</script>

<style>
.h3text{
    margin: 20px 0 15px 0;
    border: none;
    width: 100%;
    text-align: center;
    position: relative;
}
.h3text span{
     /* border-bottom: 2px solid blue;  */
}
.courseContent button{
    margin-left: 50%;
    margin-bottom: 15px;
}
.courseItemTitle{font-size: 14px;}
.newCouseMain .courseItemTitle h3{
    font-weight: normal;
    font-size: 16px;
    margin: 20px 0 10px 0;
    text-align: center;
}
.newCouseMain .courseItemTitle hr{border: 1px dashed lightgray;}
.newCouseMain .courseItemTitle p{ color: #999;}
.newCouseMain .courseItemTitle p span{ }
.newCouseMain .courseItemTitle p span.red{ color: red; font-size: 14px; font-weight: bold; }
.newCouseMain .courseItemTitle p span.green{ color: green; font-size: 14px; }
.newCouseMain .courseItemTitle p strong{ float:right; font-weight: normal; }
.newCouseMain .courseColList{ display: inline-block; border:1px solid transparent;    position: relative; margin: 0; padding:10px; width: 286px; box-sizing: border-box;}
.courseOn{position:absolute; top:125px; background-color: rgba(0,0,0,.5); width: 100%; color: #fff; font-size: 12px; line-height: 24px; padding-left: 5px; box-sizing: border-box; width: 274px;}
.courseOn span{ margin-right: 5px;}
.image {
    width: 100%;
    height: 140px;
    display: block;
  }
.newCouseMain .courseColList h4{
    font-size: 22px;
    text-align: center;
    margin:30px 0 20px 0;
}
.newCouseMain .courseColList h4 a{color: #0ba4ea;}

.newCouseMain .courseColList{
    webkit-box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
    border: 1px solid #eee;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);background-color: #fff;
    margin-right: 5px;
}
.newCouseMain .courseColList:hover{
    webkit-box-shadow: 0 2px 12px 0 rgba(0,0,0,.2);
    border: 1px solid lightgray;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);background-color: #fff;
}

</style>
