<template>
<div class="baseInfo">
    <div class="baseInfoTitle"><span>基本信息</span></div>
    <div class="baseInfoCon">
        <div class="baseInfoCon_item"><strong>帐号：</strong><span>{{infoData.A_LoginAccount}}</span></div> 
        <div class="baseInfoCon_item"><strong>名称：</strong><span>{{infoData.A_Name}}</span></div>        
        <div class="baseInfoCon_item"><strong>手机号：</strong><span>{{infoData.A_MOBILE}}</span></div>
        <div class="baseInfoCon_item"><strong>注册时间：</strong><span>{{dateFormat(infoData.A_REGISTERTIME)}}</span></div>
        <!-- <div class="baseInfoCon_item"><strong>email</strong><span>{{infoData.email}}</span></div> -->  
    </div>
    <div class="baseInfoTitle"><span>扩展信息</span></div>
    <div class="baseInfoXiu">
        <el-form ref="form" :model="form" label-width="128px">
            <el-row>
                <el-col :span="10">
                    <el-form-item label="身份证号码：" class="el-formItem" prop="D_IDENTITYCARDNO" >
                        <el-input v-if="inputVisable" v-model="form.D_IDENTITYCARDNO" placeholder="请输入身份证号码(18位或15位)" :maxlength="18" @keydown="computedCard"></el-input>
                        <span v-if="spanVisable">{{form.D_IDENTITYCARDNO}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="11">
                    <el-form-item label="姓名：" prop="D_NAME">
                        <el-input v-if="inputVisable" v-model="form.D_NAME" placeholder="请输入姓名(最大长度20)" :maxlength="20"></el-input>
                        <span v-if="spanVisable">{{form.D_NAME}}</span>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="10">
                    <el-form-item label="性别：" class="el-formItem" prop="sex">
                        <el-radio-group v-if="inputVisable"  v-model="form.D_SEX" placeholder="请选择性别">
                            <el-radio @click.native.prevent="onSEXChange(1)" :label="1">男</el-radio>
                            <el-radio @click.native.prevent="onSEXChange(0)" :label="0">女</el-radio>
                        </el-radio-group>
                        <span v-if="spanVisable">{{form.D_SEXText}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="11">
                    <el-form-item label="工作性质：" prop="D_NatureofWork" >
                           <el-radio-group v-if="inputVisable"  v-model="form.D_NatureofWork" placeholder="请选择工作性质">
                            <el-radio @click.native.prevent="onNatureofWorkChange(0)" :label="0">行政管理</el-radio>    
                            <el-radio @click.native.prevent="onNatureofWorkChange(1)" :label="1">医生</el-radio>
                            <el-radio @click.native.prevent="onNatureofWorkChange(2)" :label="2">护士</el-radio>
                        </el-radio-group>
                        <span  v-if="spanVisable">{{form.D_NatureofWorkText}}</span>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="10">
                    <el-form-item label="所在地区：" prop="D_PROVINCE">
                      <treeselect  v-if="inputVisable" v-model="form.D_PROVINCE" :options="options" :normalizer="normalizer" placeholder="请选择所在地区" />
                      <span v-if="spanVisable">{{ form.D_PROVINCEText }}</span> 
                    </el-form-item>                                       
                </el-col>
                <el-col :span="11">
                    <el-form-item label="职称：">
                        <el-input v-if="inputVisable" v-model="form.D_PHYSICIANLEVEL" placeholder="请输入职称"></el-input>
                        <span v-if="spanVisable">{{form.D_PHYSICIANLEVEL}}</span>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="10">
                    <el-form-item label="资格证书编号：">
                        <el-input v-if="inputVisable" v-model="form.D_PHYSICIAN_QUALIFICATION_CODE" placeholder="请输入资格证书编号(最大长度50)" :maxlength="50"></el-input>
                        <span v-if="spanVisable">{{form.D_PHYSICIAN_QUALIFICATION_CODE}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="11">
                    <el-form-item label="执业证书编号：">
                        <el-input v-if="inputVisable" v-model="form.D_PHYSICIAN_CODE" placeholder="请输入执业证书编号(最大长度50)" :maxlength="50"></el-input>
                        <span v-if="spanVisable">{{form.D_PHYSICIAN_CODE}}</span>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                     <el-button type="primary"  v-if="isBaseInfo"  class="baseButton"  @click="onSubmit('form')">保存</el-button>
                     <el-button type="primary"  v-if="!isBaseInfo"  class="baseButton" @click="baseInfoXiu()">修改</el-button>
                </el-col>
            </el-row>
        </el-form>
    </div>
</div>

</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import api from "@/api/index.js";
import { constants, fips } from 'crypto';
import { normalize } from 'path';
export default{
     components: { Treeselect },
    created(){
      this.baseInfo();  
    },
     data() {
      return {
        infoData:'',
        value:null,
        isBaseInfo:false,
        inputVisable:false,
        spanVisable:true,
        options:[],
        form: {
          name: '',
          sex: '',
          D_SEXText:'',
          phone:'',
          userName:'',
          D_PROVINCE:null,
          D_PROVINCEText:'',
          email:'',
          D_IDENTITYCARDNO:'',
          D_NatureofWork:'',
          D_NatureofWorkText:'',
          D_SEX:'',
          D_PHYSICIANLEVEL:'',
          D_PHYSICIAN_QUALIFICATION_CODE:'',
          D_PHYSICIAN_CODE:'',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: ''
        },
      }
    },
    methods: {
    // 基本信息
    baseInfo(){
        api.getCustomerAccount({
            params:{
                code:this.$store.state.userNameID,
            }
        })
            .then(res=>{
                console.log('基本信息：',res)
                this.infoData = res.data.rows;
            }).catch(error=>{
                console.log(error)
            });
        api.postAllAreaList().then(res=>{
            this.options=res.data.rows;
            console.log("this.options:"+this.options);
            this.baseInfoExtend();
        });           
    },
    //绑定扩展信息
    baseInfoExtend(){
        api.postGetCustomerExtend().then(res=>{
            res.data.rows.D_NatureofWork=parseInt(res.data.rows.D_NatureofWork);
            this.form=res.data.rows;
            console.log("this.form:"+JSON.stringify(this.form));
            //性别由数字转换成文本
            switch(parseInt(this.form.D_SEX))
            {
               case 0:
                   this.form.D_SEXText="女";
                   break;
               case 1:
                   this.form.D_SEXText="男";
                   break;
            }
            //行政管理由数字转换成文本        
            switch(parseInt(this.form.D_NatureofWork))
            {
                case 0:
                    this.form.D_NatureofWorkText="行政管理";
                    break;
                case 1:
                    this.form.D_NatureofWorkText="医生";
                    break;
                case 2:
                    this.form.D_NatureofWorkText="护士";
                    break;

            }
            //行政区域有guid转换成文本
            console.log(JSON.stringify(this.options));
            for(var i=0;i<this.options.length;i++)
            {
                if(this.options[i].id==this.form.D_PROVINCE){
                    this.form.D_PROVINCEText=this.options[i].text;
                }
            }
        });
    },
    // 修改信息
    baseInfoAmend(){
        api.postUpdateGetCustomerExtend({
            D_IDENTITYCARDNO:this.form.D_IDENTITYCARDNO,
            D_NAME:this.form.D_NAME,
            D_SEX:this.form.D_SEX,
            D_NatureofWork:this.form.D_NatureofWork,
            D_PROVINCE:this.form.D_PROVINCE,
            D_PHYSICIANLEVEL:this.form.D_PHYSICIANLEVEL,
            D_PHYSICIAN_QUALIFICATION_CODE:this.form.D_PHYSICIAN_QUALIFICATION_CODE,
            D_PHYSICIAN_CODE:this.form.D_PHYSICIAN_CODE
        }).then(res=>{
            console.log('修改信息：',res)
            if(res.data.Status ==1){
                this.baseInfoExtend();
                //隐藏编辑vue组件
                this.inputVisable=false;
                //显示span标签
                this.spanVisable=true;
                this.isBaseInfo=false;
            }
        });
        // api.postUpdateCustomerAccount({
        //     // "A_ID": "ce171551-6e12-4cd1-9305-7d7dbd98ca4c",
        //     A_LoginAccount: this.phone,
        //     A_Name: this.name,
        //     A_Type: this.sex,
        //     // "A_Password": "",
        //     // "A_RFROM": 1,
        //     // "A_REGISTERTIME": "2019-07-15T16:49:27.5198064+08:00",
        //     A_MOBILE: this.phone,
        //     A_EMAIL: this.email,
        //     // "A_CREATEBYID": "75de136b-63f7-461e-8696-aeccb8d08e61",
        //     // "A_CREATEDATE": "2019-07-15T16:49:27.5198064+08:00",
        //     // "A_MODIFYBYID": "777f72e8-9c84-4ae4-8624-1c0f5aeae006",
        //     // "A_MODIFYDATE": "2019-07-15T16:49:27.5198064+08:00",
        //     // "A_STATE": 1,
        //     // "A_OpenId": "",
        //     // "A_AreaCode": "",
        //     // "A_SessionId": ""
        // })
        // .then(res=>{
        //     console.log('修改信息：',res)
        //     if(res.data.Status ==1){
        //         this.isBaseInfo=false;
        //     }
        // }).catch(err=>{
        //     console.log(err);
        // })
    },
      onSubmit(formName) {
          this.baseInfoAmend();
          console.log('submit!');
      },
      baseInfoXiu(){
          this.isBaseInfo=!this.isBaseInfo;
          this.inputVisable=true;
          this.spanVisable=false;
      },
      dateFormat:function(time) {
        console.log("time:"+time);
        if(time==undefined) return;
		var date=new Date(time);
		var year=date.getFullYear();
		var month= date.getMonth()+1<10 ? "0"+(date.getMonth()+1) : date.getMonth()+1;
		var day=date.getDate()<10 ? "0"+date.getDate() : date.getDate();
		var hours=date.getHours()<10 ? "0"+date.getHours() : date.getHours();
		var minutes=date.getMinutes()<10 ? "0"+date.getMinutes() : date.getMinutes();
		var seconds=date.getSeconds()<10 ? "0"+date.getSeconds() : date.getSeconds();
		//返回格式化后的日期
		return year+"-"+month+"-"+day+" "+hours+":"+minutes+":"+seconds;
    },
    onNatureofWorkChange(e)
    {
      console.log("onNatureofWorkChange:"+e);
      e == this.form.D_NatureofWork ? this.form.D_NatureofWork = 1 : this.form.D_NatureofWork = e;  
    },
    onSEXChange(e)
    {
      //e === this.form.D_SEX ? this.form.D_SEX = '' : this.form.D_SEX = e;
      this.form.D_SEX = e;
      this.form.sex = e == 1 ? true:false;
    },
    normalizer(node){
        console.log("node:"+node);
        if(node.children && !node.children.length){
            delete node.children;
        }
        return {
            id: node.id,
            label:node.text,
            children:node.children
        }
    },
    computedCard(value){
        if(value.length>18){
            alert("身份证最大长度是18位！");
            return;          
        }
    },
  }
}

</script>

<style>
 .baseInfo{width: 100%;padding: 5px;margin: 5;}
 .baseInfoTitle{width: 100%;margin-left: 5px;}
 .baseInfoCon_item{width: 50%;float: left;}
 .el-select{width: 290.75px;}
 .spanArea{margin-left:54px;}
 .selArea{width: 86px;margin-left: 15px;}
</style>