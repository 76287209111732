<template>
  <div class="right_con">
    <div class="catalogue">
      <ul>
        <li>
          <div class="catalogue_time">
            <span>2017年</span>
          </div>
          <div class="catalogue_con clearfix">
            <h3>与烟台市非公立医疗机构协会签订战略合作协议</h3>
            <div class="ca_con_box03">
              <span></span>
              <i></i>
              <div>
                <h4>12月</h4>
                <p>2017年</p>
              </div>
            </div>
          </div>
          <div class="catalogue_con clearfix">
            <h3>与中国非公立医疗机构协会信用与能力评价授权委托</h3>
            <div class="ca_con_box03">
              <span></span>
              <i></i>
              <div>
                <h4>10月</h4>
                <p>2017年</p>
              </div>
            </div>
          </div>
          <div class="catalogue_con clearfix">
            <h3>与惠州市惠城区医师协会签订战略合作协议</h3>
            <div class="ca_con_box03">
              <span></span>
              <i></i>
              <div>
                <h4>7月</h4>
                <p>2017年</p>
              </div>
            </div>
          </div>
          <div class="catalogue_con clearfix">
            <h3>与中国非公立医疗机构协会签订医协体委托函</h3>
            <div class="ca_con_box03">
              <span></span>
              <i></i>
              <div>
                <h4>5月</h4>
                <p>2017年</p>
              </div>
            </div>
          </div>
          <div class="catalogue_con clearfix">
            <h3>与交通大学国家健康产业研究院合作，成立互联网+健康研究所</h3>
            <div class="ca_con_box03">
              <span></span>
              <i></i>
              <div>
                <h4>4月</h4>
                <p>2017年</p>
              </div>
            </div>
          </div>
          <div class="catalogue_con clearfix">
            <h3>与东莞市医师协会签订战略合作协议</h3>
            <div class="ca_con_box03">
              <span></span>
              <i></i>
              <div>
                <h4>4月</h4>
                <p>2017年</p>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="catalogue_time">
            <span>2016年</span>
          </div>
          <div class="catalogue_con clearfix">
            <h3>与中国非公立医疗机构协会签订信息化战略合作协议</h3>
            <div class="ca_con_box03">
              <span></span>
              <i></i>
              <div>
                <h4>10月</h4>
                <p>2016年</p>
              </div>
            </div>
          </div>
          <div class="catalogue_con clearfix">
            <h3>上海市科委创新基金项目《应用于医师在线教育的信息化服务平台》立项</h3>
            <div class="ca_con_box03">
              <span></span>
              <i></i>
              <div>
                <h4>7月</h4>
                <p>2016年</p>
              </div>
            </div>
          </div>
          <div class="catalogue_con clearfix">
            <h3>与深圳市医师协会签订战略合作协议，全面启动信息化建设和医师定期考核项目</h3>
            <div class="ca_con_box03">
              <span></span>
              <i></i>
              <div>
                <h4>6月</h4>
                <p>2016年</p>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="catalogue_time">
            <span>2015年</span>
          </div>
          <div class="catalogue_con clearfix">
            <h3>与上海市医师协会签订战略合作协议，全面启动信息化建设和医师定期考核项目</h3>
            <div class="ca_con_box03">
              <span></span>
              <i></i>
              <div>
                <h4>10月</h4>
                <p>2015年</p>
              </div>
            </div>
          </div>
          <div class="catalogue_con clearfix">
            <h3>与珠海市医师协会签订战略合作协议，全面启动信息化建设和医师定期考核项目</h3>
            <div class="ca_con_box03">
              <span></span>
              <i></i>
              <div>
                <h4>6月</h4>
                <p>2015年</p>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.right_con{ width: 1200px; background: #fff; margin: 0 auto;box-sizing: border-box;padding:20px 20px; }
.right_con .catalogue{ padding-bottom:60px; margin-top:40px;}
.right_con .catalogue ul{ border-right:2px solid #649df3; width:721px; margin: 0 0 0 180px;}
.right_con .catalogue ul li{ padding:40px 0px 20px 0px;position:relative;}
.right_con .catalogue_con{ width:619px; padding:0px 20px; margin-top:10px;border:1px solid #ccc; border-radius:5px;position:relative;}
.right_con .catalogue_time{ position:absolute; top:0px; right:-136px;height:55px; line-height:55px; width:160px; background:url(../../assets/images/about_right.png) 0px 0px no-repeat;}
.right_con .catalogue_time span{ font-size:26px; color:#649df3; margin-left:60px;}
.right_con .catalogue_con h3{ font-size:16px; font-weight:normal; line-height:45px;}
.right_con .catalogue_con h3 a:hover{color:#3a74cc;}
.right_con .ca_con_box01{ width:116px; height:77px; float:left;}
.right_con .ca_con_box02{ float:right; width:475px; font-size:14px; color:#777777; line-height:30px;}
.right_con .ca_con_box02 a{ color:#777777;}
.right_con .ca_con_box02 a:hover{ color:#3a74cc;}
.right_con .ca_con_box03{ position:absolute; height:50px; width:170px; top:8px; right:-170px;}
.right_con .ca_con_box03 span{ display:inline-block; width:13px; height:28px; float:left; background:url(../../assets/images/about_right.png) 0px -215px no-repeat;}
.right_con .ca_con_box03 i{ display:inline-block; width:16px; height:16px; float:left; margin-top:7px; margin-left:42px; background:url(../../assets/images/about_right.png) 0px -91px no-repeat; }
.right_con .ca_con_box03 div{ float:right; width:86px;}
.right_con .ca_con_box03 div h4{ font-weight:normal; font-size:18px; color:#575350;}
.right_con .ca_con_box03 div p{ font-size:14px; color:#575350;}
</style>