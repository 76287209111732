<template>
<div class="courseBg">
    <div class="indexBanner"><banner-tu></banner-tu></div>
    <div class="courseMain clearfix">
        <div class="courseContent" v-loading="loading">
            <h3 class="h3text"><span>{{paramsId=='37a00d8e-1e5f-48ca-98a0-200eee4d3bb5'?'管理培训':'护理培训'}}热门课程</span></h3>
            <div class="courseMainList">
                <course-item v-for="(item,index) in courseData" :key="index" :itemCourseData="item"></course-item>
                <!-- <button v-if="isVisableButton" @click="moreCourses">更多课程</button> -->               
            </div>
            <el-button round  v-if="isVisableButton" @click="moreCourses">更多课程</el-button>
            <div v-if="isPaging" class="coursePage">
                <!-- <course-page :itemPageRows="pageRows"></course-page> -->
                <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="pageRows"
                    :page-size="pageSize"
                    :current-page="currentPage"
                    @current-change="handleCurrentChange">
                </el-pagination>
            </div>
        </div>
    </div>
</div>


</template>

<script>
import Vue from 'vue'
import bannerTu from '@/components/main/banner.vue'
import coursePage from '@/components/course/coursePage.vue'
import courseItem from '@/components/course/courseItem.vue'

import api from "@/api/index.js";
import { setTimeout } from 'timers';
export default{
    created(){
        this.paramsId=this.$route.params.id;
        console.log("传过来的id值:",this.paramsId);
        this.initList();
    },
    components:{
        bannerTu,
        coursePage,
        courseItem
    },
    data(){
        return{
            loading: true,
            isPaging:false,
            isVisableButton:true,
            ProName:'',
            projiectType:'',
            paramsId:'',
            projectCodeArr:'',
            currentPage:1,
            pageRows:null,
            pageSize:8,
            courseData:[],
            
        }
    },
    methods:{
        // 初始化加载课程列表
        initList(){          
            api.getProjectData({
                CB_NAME: '',
                CB_KEYWORDS: '',
                CB_COURSETYPE: '',
                CB_TYPE: '',
                CB_PROJECTCODE: this.paramsId,
                CB_PROFESSIONCODE: '',
                page: this.currentPage,
                rows: this.pageSize
            }).then(res=>{
                this.courseData=res.data.rows;
                this.pageRows=res.data.total;
                this.loading=false;
                console.log("课程列表:",res);
            }).then(res=>{

            })
            .catch(error=> {
                    console.log(error);
            })
        },
        moreCourses(){
            this.isPaging=true;
            this.isVisableButton=false;
            this.currentPage=1;
            this.pageSize=20;
            this.initList();
        },
        handleCurrentChange(val){
            this.currentPage=val;
            this.pageSize=20;
            this.initList();
            setTimeout(function(){
                if(document.body.scrollTop){
                    document.body.scrollTop = 0
                }else {
                    document.documentElement.scrollTop = 0
                }
            },300)
        },
    },
}

</script>

<style>
.h3text{
        margin: 20px 0 15px 0;
    border: none;
    width: 100%;
    text-align: center;
    position: relative;
}
.courseContent button{
    margin-left: 50%;
    margin-bottom: 15px;
}
</style>