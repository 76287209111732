<template>
  <div>
    <header class="headerTop"><header-top class="headerTopCon"></header-top></header>
    <div class="mainContent"><router-view/></div>
    <footer class="footerBottom"><footer-bottom></footer-bottom></footer>
  </div>
</template>
<script>
import headerTop from '@/components/header/headerTop.vue'
import footerBottom from '@/components/footer/footerBottom'
export default{
    components:{
        headerTop,
        footerBottom,
    }
}

</script>
<style>
.headerTop{ background: #fff; height: 78px; position: relative; z-index: 9999; -moz-box-shadow:0px 0px 7px #ccc; -webkit-box-shadow:0px 0px 7px #ccc; box-shadow:0px 0px 7px #ccc;}
.headerTopCon{ width: 1200px; margin: 0 auto;}
.mainContent{min-height: 400px; font-size: 16px;}
</style>

