<template>
<div>
    <div class="ConRightNav">
                    <router-link :to="{name:'index'}">首页</router-link><strong>></strong><span>帮助中心</span><strong>></strong><span>常见问题</span>
                </div>
                <div class="ConRightText">
                    <div class="H_c_r_text">
                        <div class="H_c_r_t_title">（一）医+培训</div>
                        <div class="H_c_r_t_con">
                            <h3>（1）注册相关</h3>
                            <div class="H_c_h3con">
                                <p>Q1:新用户如何注册</p>
                                <p>A1:进入医+E培训首页（http://www.1pluse.com/）点击网站右上角“注册”按钮，根据提示进行注册即可。目前仅支持电子邮箱及中国大陆手机号注册。</p>
                            </div>
                            <div class="H_c_h3con">
                                <p>Q2:如何登陆</p>
                                <p>A2:进入医+E培训首页（http://www.1pluse.com/）点击网站右上角“登陆”按钮，输入您的电子邮箱/手机号/账号、密码和验证码，点击“登陆”即可。</p>
                            </div>
                            <div class="H_c_h3con">
                                <p>Q3:忘记密码怎么办</p>
                                <p>A3:请致电医+E客服400-6623-955重置密码；或在登陆界面点击“忘记密码”找回密码。</p>
                            </div>
                            <h3>（2）培训课程相关</h3>
                            <div class="H_c_h3con">
                                <p>Q1:如何购买医师定考培训课程</p>
                                <p>A1:</p>
                                <p>方法1：进入医+E培训首页（http://www.1pluse.com/）点击网站右上角“登陆”按钮，选择您注册所在地定考地市、考核专业，点击“立即购买”，支付宝支付完成即可在线学习。</p> 
                                <p>方法2：团购客户可拨打客服热线400-6623-955订购学习卡。</p> 
                                <p>注意事项：</p>
                                <p>1）购买前请先注册，否则无法购买。</p>
                                <p>2）为了避免购买错误课程包的发生，请您在购买前仔细核对课程包名称是否符合您当期定考需求，并在购买完成后立即查看课程包内容是否正确。</p>
                            </div>
                            <div class="H_c_h3con">
                                <p>Q2:如何观看课件</p>
                                <p>A2:使用浏览器打开医+E培训首页（http://www.1pluse.com/），登录后在个人中心点击“所购买的教程-前去学习-课件”即可在线观看课件进行学习。 课件使用小技巧：观看课件时您可以点击左边目录栏选择所需章节进行学习。 注意事项：课件由声音及图像组成，需要浏览器安装并支持flash播放。</p>
                            </div>
                            <div class="H_c_h3con">
                                <p>Q3:如何做模拟试题</p>
                                <p>A3:使用浏览器打开医+E培训首页（http://www.1pluse.com/），登陆后在个人中心点击“所购买的课程-前去学习-试卷”按钮即可在线做题。 模拟试题使用小技巧：在答题时您可以点击右边答题卡，展开答题卡后可快速定位试题，进行针对性的学习。</p>
                            </div>
                            <div class="H_c_h3con">
                                <p>Q4:可以在手机或ipad上观看课件及做模拟试题吗</p>
                                <p>A4:目前暂不支持在普通手机及ipad上观看课件及做模拟题。</p>
                            </div>
                            <div class="H_c_h3con">
                                <p>Q5:可以多人同时在线学习课程吗 </p>
                                <p>A5:不能。课程与购买账号绑定，且仅支持单一设备同一时间登陆使用。</p>
                            </div>
                            <div class="H_c_h3con">
                                <p>Q6:可以团购课程吗</p>
                                <p>A6:可以。10份课程以上即可拨打客服热线400-6623-955详细咨询。团购可享团购优惠。</p>
                            </div>
                            <div class="H_c_h3con">
                                <p>Q7:需要发票，如何开具 </p>
                                <p>A7:开具发票需提供：发票抬头，收件人，手机号，收件地址，购买教材订单号或支付宝流水号，发送到huangfeng@doc-exam.com，我们根据收到的邮件顺序开具发票并寄出。寄出邮件后会给您的电子邮件回复快递单号以便查询。</p>
                            </div>
                            <div class="H_c_h3con">
                                <p>Q8:无法观看课件怎么办</p>
                                <p>A8:建议更换浏览器并检查电脑网速是否稳定。目前课件需基于windows XP、mac os X系统及以上的电脑操作系统，并安装含有flash 7版本以上的浏览器，且需网络环境正常稳定时才能正常观看。</p>
                            </div>
                            <div class="H_c_h3con">
                                <p>Q9:课程有效期是多久</p>
                                <p>A9:自购买之日起有效期1年。</p>
                            </div>
                            <div class="H_c_h3con">
                                <p>Q10:我需要购买哪种课程 </p>
                                <p>A10:购买您本周期需要参加考试的课程。</p>
                            </div>
                            <div class="H_c_h3con">
                                <p>Q11:在哪里打印准考证 </p>
                                <p>A11:可登陆所在城市的医师协会官网进行准考证打印。</p>
                            </div>
                            <div class="H_c_h3con">
                                <p>Q12:政策问题如何咨询</p>
                                <p>A12:可拨打当地医师协会官网首页最下方的电话进行咨询。</p>
                            </div>
                            <div class="H_c_h3con">
                                <p>Q13:遇到问题如何反馈 </p>
                                <p>A13:可拨打医+E客服热线400-6623-955进行反馈。</p>
                            </div>
                            <div class="H_c_h3con">
                                <p>Q14:购买课程包支付方式有哪些</p>
                                <p>A14:目前课程包购买方式仅支持支付宝和学习卡。 </p>
                            </div>
                            <div class="H_c_h3con">
                                <p>Q15:学习卡如何使用 </p>
                                <p>A15:在选择支付方式时勾选“学习卡支付”输入卡片上的学习卡卡号和密码即可完成支付。 </p>
                            </div>
                            <div class="H_c_h3con">
                                <p>Q16:学习卡有效期是多久 </p>
                                <p>A16:学习卡有限期1年。逾期无论使用与否都将失效。</p>
                            </div>
                            <div class="H_c_h3con">
                                <p>Q17:学习卡如何购买？学习卡适合什么人群购买</p>
                                <p>A17:可拨打客服热线400-6623-955咨询购买。学习卡适合团购医师及没有支付宝或者不方便使用支付宝的医师购买。</p>
                            </div>
                            <div class="H_c_h3con">
                                <p>Q18:课程包内含有纸质资料吗</p>
                                <p>A18:抱歉，目前课程包内容不包含纸质资料。践行无纸化学习，让我们为环境贡献自己的一份力量。</p>
                            </div>
                            <div class="H_c_h3con">
                                <p>Q19:我有上一周期的教材，本周期可以使用吗 </p>
                                <p>A19:不建议使用，各地市每周期考纲、试题等会根据考核当年政策进行调整，建议医师购买本周期课程包进行学习。</p>
                            </div>
                            <div class="H_c_h3con">
                                <p>Q20:我有上一周期未使用的学习卡，本周期可以使用吗 </p>
                                <p>A20:学习卡有限期1年。逾期无论使用与否都将失效。</p>
                            </div>
                            <div class="H_c_h3con">
                                <p>Q21:定考成绩在哪里公布 </p>
                                <p>A21:定考成绩将会在定考结束后由医师协会公布，您可以登陆所在地市医师协会官网进行查询，或致电所在地市医师协会进行咨询了解。</p>
                            </div>
                            <div class="H_c_h3con">
                                <p>Q22:我可以在哪里获取最新的定考咨询</p>
                                <p>A22:您可以登陆所在地市医师协会官网、医+E网站（http://www.1pluse.com/）或关注医+E官方微信号获得最新的定考咨询。</p>
                            </div>
                            <div class="H_c_h3con">
                                <p>Q23:支付宝订单号（交易号）在哪里查询</p>
                                <p>A23:您可以在支付宝“我的-账单”中找到支付完成的课程包订单，点击订单进入“账单详情”后，可查看到“订单号（交易号）”。</p>
                            </div>
                            <div class="H_c_h3con">
                                <p>Q24:可以使用别人的支付宝支付吗</p>
                                <p>A24:可以。但是如果您需要开具发票，请记录支付人的订单号，方便我们查询交易记录。</p>
                            </div>
                            <div class="H_c_h3con">
                                <p>Q25:可以复制、下载课程内容吗 </p>
                                <p>A25:目前暂不支持复制和下载课程包内容，如有不便，请您谅解。</p>
                            </div>
                            <div class="H_c_h3con">
                                <p>Q26:课程包有优惠活动吗 </p>
                                <p>A26:课程包支持团购购买，具体优惠需根据团购人数确定，详情请致电医+E客服热线400-6623-955咨询了解。</p>
                            </div>
                            <!-- <div class="H_c_h3con">
                                <p>Q27:医+E网站有App吗？课程可以在App上学习吗</p>
                                <p>A27: 该功能已经实现，欢迎扫码下载APP进行体验。</p>
                            </div> -->
                            <div class="H_c_h3con">
                                <p>Q27:购买错了课程怎么办</p>
                                <p>A27:您可以在购买课程包后24小时内致电医+E客服热线400-6623-955进行核实并修改。购买课程包24小时后我们将不再受理更改需求。如有不便敬请谅解。</p>
                                <p>注意事项：为了避免购买错误课程包的发生，请您在购买前仔细核对课程包名称是否符合您当期定考需求，并在购买完成后立即查看课程包内容是否正确。</p>
                            </div>
                            <div class="H_c_h3con">
                                <p>Q28:一个账号可以购买几个课程</p>
                                <p>A28:为了方便您每个考核周期的使用，一个账号注册成功后可重复购买多个课程。</p>
                                <p>注意事项：一个账号只支持一个设备同一时间在线学习，因此我们不建议您多人使用同一账号。</p>
                            </div>
                            <div class="H_c_h3con">
                                <p></p>
                                <p></p>
                            </div>
                        </div>
                    </div>
                </div>
</div>

</template>

<script>
export default{

}

</script>

<style>
</style>