<template>
<div class="servicePage">
    <div class="servicePageList01" v-if="itemdata.isTrue">
        <div class="serviceIntroduce">
            <div class="serverImgLeft">
                <h3>{{itemdata.ListName}}</h3>
                <p>{{itemdata.listTitle}}</p>
                <!-- <a href="#">我要培训</a> -->
                <router-link v-if="itemdata.isButton" :to="{name:'courseList',params:{id:searveId}}">我要培训</router-link>
            </div>
            <div class="serverImgRigth">
                <el-image :src="itemdata.listImgUrl">
                <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                </div>
                </el-image>
            </div>
        </div>
    </div>
    <div class="servicePageList02" v-if="itemdata.isTrue">
        <div class="serviceTools">
            <div class="toolsTile">配套<span>服务工具</span>，打磨极致用户体验</div>
            <div class="toolsContent">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane :label="item.title" :name="'index'+index" v-for="(item,index) in itemdata.data" :key="index">
                        <div class="toolsItem">
                            <div class="toolsItemLeft">
                                <h3>{{item.conTitle}}</h3>
                                <p>{{item.conText}}</p>
                            </div>
                            <div class="toolsItemRight"><img :src="item.imgUrl"></div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </div>
    <div class="servicePageList03" v-if="itemdata.isTrue">
        <div class="serviceStudy">
            <div class="toolsTile"><span>专业培训</span>练习，轻松学习掌握</div>
        </div>
    </div>
    <div class="servicePageList04" v-else><img src="@/assets/images/jianshe.png"></div>
</div>

</template>

<script>
export default{
    
    data() {
        // <!-- 医师：physicians  双评：double  护理：nurse 医院管理：hospital 专科医师培训：subject 学协会:learnSociety-->
      return {
        searveId:'',
        activeName: 'index0',
        searveName:'',
        itemdata:[],
        serverData:[
                {
                    listTitle:'医师定期考核是根据《中华人民共和国执业医师法》明确规定的法定考核工作，它与医师资格考试、执业注册管理共同构成了我国执业医师管理体系。其重要性在于推进医师从业的终身制转为年检制，从单位人转为社会人，由医院人事管理转为国家制度管理。医师定期考核，包括执业医师业务水平、工作成绩和职业道德，旨在建立完善医师准入与退出机制，促进医师知识更新，提高医师执业水平和执业能力，确保医疗质量与医疗安全的重要手段。',
                    typeId:'physicians',
                    listImgUrl:require('@/assets/images/study01.png'),
                    ListName:'医师定期考核',
                    isTrue:true,
                    isButton:true,
                    data:[
                        {
                            id:'0001',
                            title:'医师定期考核信息管理系统',
                            conTitle:'医师定期考核信息管理系统',
                            conText:'承担《医师定考》规定的“考核办公室”“卫生机构”“考核机构”“执业医师”四个业务角色的职能服务。符合《医师定考》规范：定考业务流程、定考考核流程。完善的六大功能模块：基础信息、账号管理、定考管理、定考信息、历史信息、统计分析',
                            imgUrl:require('@/assets/images/toolsItem01.png'),
                        },
                        {
                            id:'0002',
                            title:'医+题库管理系统',
                            conTitle:'医+题库管理系统',
                            conText:'基于建有海量专业的题库，包含题目系统和考卷系统2个子系统。线上全数字化模式实现，基于覆盖国家定考专业委员会制定题目。涵盖70个临床、口腔、中医、公卫、及护理相关专业+15个专科。六大题型：A1/A2，A3/A4，B1，X，智能组卷：设定特殊策略进行智能组卷，人工组卷：支持人工在线组卷，海量题库：拥有医学专业20万+题目，标准接口：支持第三方考试系统标准接口',
                            imgUrl:require('@/assets/images/toolsItem03.png'),
                        },
                        {
                            id:'0003',
                            title:'医+培训服务系统',
                            conTitle:'医+培训服务系统',
                            conText:'设有考核周期内该专业/类别的定考指南书、视频课件、练习讲解、模拟练习试卷，涵盖定考资讯、国内外医疗行业资讯、临床路径、疾病指南、专家共识，量身打造定考信息资源。提供：课程包管理、课程包购买、在线学习、学习卡管理、新闻管理。',
                            imgUrl:require('@/assets/images/toolsItem04.png'),
                        },
                        {
                            id:'0004',
                            title:'医+考试管理系统',
                            conTitle:'医+考试管理系统',
                            conText:'专属于医师定考业务水平测评流程，含考务、监考和考试三大子系统。支持集群考试，满足1000+人次/场；有效防止作弊，实时随机组卷作弊；实时过程监控，远程实时监控考试过程；智能自动评卷，实现成绩分析信息化。满足：提供个性化考试计划、数字化一体化准考证、规范标准化试卷管理、数字智能化考场设置、全过程实时监考管理 智能化闭环考生考试',
                            imgUrl:require('@/assets/images/toolsItem02.png'),
                        },
                    ],

                },{
                    ListName:'非公双评',
                    listImgUrl:require('@/assets/images/study02.png'),
                    listTitle:'中国非公立医疗机构协会组织开展的信用与能力评价（简称“双评”），是由协会组织相关专家，根据评价标准，按照规定程序，对申报的非公立医疗机构进行信用与能力专业性、技术性综合评价。根据非公立医疗机构信用与能力评价标准，建有超过10000题题量库满足“双评”医院相关医疗、护理、医技、行政、后勤人员现场考核学习',
                    typeId:'double',
                    isTrue:true,
                    isButton:true,
                    data: [
                        {
                            id:'0001',
                            title:'双评医院应知应会测评系统',
                            conTitle:'双评医院应知应会测评系统',
                            conText:'为贯彻落实国家医药卫生体制改革精神，建立健全非公立医疗机构（以下简称医疗机构）综合评价体系与机制，推进非公立医疗行业自律和持续改进，提升医疗服务能力和管理水平，切实保障人民群众医疗安全，医东网络科技发展（上海）有限公司针对医疗机构，开发的一套应知应会评测系统，以评测非公立医疗机构应知应会水平。双评医院应知应会测评系统由考务、监考和考试三大子系统组成。能力评价对象为非公立医疗机构协会会员单位，包括综合医院、中医医院、中西医结合医院、专科医院、康复医院、门诊部、诊所等。',
                            imgUrl:require('@/assets/images/toolsItem05.png'),
                        },
                        {
                            id:'0002',
                            title:'信用与能力评价题库系统',
                            conTitle:'信用与能力评价题库系统',
                            conText:'专业的题库是医+E非公双评品牌生存的根本，因此信用与能力评价题库系统是医+E非公双评品牌的核心。信用与能力评价题库系统包含题目系统和考卷系统2个子系统。题库系统包含信用与能力试题的录入及修改，试卷系统主要负责信用与能力相关试卷的制作及修改。评价专家库专家涵盖社会医学与卫生事业管理、临床医学、药学、护理、医技、财经管理、信用管理、法律等专业。',
                            imgUrl:require('@/assets/images/toolsItem06.png'),
                        },
                        {
                            id:'0003',
                            title:'信用与能力在线培训系统',
                            conTitle:'信用与能力在线培训系统',
                            conText:'非公立医疗机构信用与能力在线培训系统专注针对医疗机构业务水平测评考前培训服务。考生可根据需要在线购买各专业/类别考前培训辅导，开启非公信用与能力评价考前辅导课程的学习。医+培训系统不仅包含当前考核周期内该专业/类别定考指南书、视频课件、课件练习及讲解、模拟练习试卷，还是涵盖双评资讯、双评问答等为医护人员量身打造的信息资源共享平台，在助力考生顺利通过考试的同时，让考生在专业新鲜的行业资源里真正有所收获。',
                            imgUrl:require('@/assets/images/toolsItem07.png'),
                        },
                        {
                            id:'0004',
                            title:'医院业务能力星级评价软件',
                            conTitle:'医院业务能力星级评价软件',
                            conText:'为贯彻落实国家医药卫生体制改革精神，建立健全非公立医疗机构（以下简称医疗机构）综合评价体系与机制，推进非公立医疗行业自律和持续改进，提升医疗服务能力和管理水平，切实保障人民群众医疗安全，医东网络科技发展（上海）有限公司针对 非公立医疗机构，开发的一套医院业务能力星级评价系统。用来规范行业信用行为、加强对失 信机构的惩戒力度、打击虚假广告和骗医行为。医院业务能力星级评价软件功能模块包括注册、提交评定申请、服务能力评定、信息管理等。',
                            imgUrl:require('@/assets/images/toolsItem08.png'),
                        },
                    ],
                },{
                     ListName:'护理专业培训',
                    listImgUrl:require('@/assets/images/study03.png'),
                    listTitle:'护理专业培训是稳定和加强护理队伍建设，提高护理人文关怀意识，树立正确的执业价值观念，将护理理论和临床实践有机结合，掌握基本及专科技能操作规范的重要手段。建有100000题题量库，并根据每年的考试大纲要求建立题库满足护士执业考试、护理职称晋升考试、新上岗护士轮岗实践考试的学习。',
                    // typeId:'',
                    typeId:'nurse',
                    isTrue:true,
                    isButton:true,
                    data: [
                        {
                            id:'0001',
                            title:'医师护理培训系统',
                            conTitle:'医师护理培训系统',
                            conText:'设有护士执业考试、护理职称晋升考试、新上岗护士轮岗实践考试的培训课件，视频，练习讲解，模拟练习试卷等，涵盖定考资讯、国内外医疗行业资讯、临床路径、疾病指南、专家共识，量身打造定考信息资源。提供课程包管理，课程包购买，在线学习等。',
                            imgUrl:require('@/assets/images/toolsItem13.png'),
                        },
                    ],
                },{
                    ListName:'医院管理培训',
                    listImgUrl:require('@/assets/images/study04.png'),
                    listTitle:'医院管理培训',
                    // typeId:'',
                    typeId:'hospital',
                    isTrue:false,
                    isButton:false,
                    data: [
                        {
                            id:'0001',
                            title:'医院管理培训',
                            conTitle:'医师定期考核信息登记管理系统111',
                            conText:'医师定期考核信息登记管理系统采用网络在线实时登记上报模式，协助各级医师协会、考核办、医疗机构及参加定考医师完成在线信息登记、录入与核对。该系统包含定考信息登记的所有流程，如医师名单上报、定考简易程序流程、考评流程、结果统计等等。让各级定考参与机构及负责人实现数字化、网络化办公。',
                            imgUrl:require('@/assets/images/toolsItem01.png'),
                        },
                    ],
                },{
                    ListName:'专科医师培训',
                    listImgUrl:require('@/assets/images/study04.png'),
                    listTitle:'专科医师培训',
                    // typeId:'',
                    typeId:'subject',
                    isTrue:false,
                    isButton:false,
                    data: [
                        {
                            id:'0001',
                            title:'专科医师培训',
                            conTitle:'医师定期考核信息登记管理系统111',
                            conText:'医师定期考核信息登记管理系统采用网络在线实时登记上报模式，协助各级医师协会、考核办、医疗机构及参加定考医师完成在线信息登记、录入与核对。该系统包含定考信息登记的所有流程，如医师名单上报、定考简易程序流程、考评流程、结果统计等等。让各级定考参与机构及负责人实现数字化、网络化办公。',
                            imgUrl:require('@/assets/images/toolsItem01.png'),
                        },
                    ],
                },{
                    ListName:'学协会信息化及会员服务系统',
                    listImgUrl:require('@/assets/images/study04.png'),
                    listTitle:'学协会官网网站建设是针对学协会网站及会员管理建设的一站式在线信息化建设。除了实现学协会建立自己门户网站和管理自己会员的需要外，还提供更多个性化的定制开发，满足学协会各项需求。包括工作动态、专科分会、活动专题、会员中心等几个模块。',
                    typeId:'learnSociety',
                    isTrue:true,
                    isButton:false,
                    data: [
                        {
                            id:'0001',
                            title:'学协会官方网站',
                            conTitle:'学协会官方网站',
                            conText:'学协会官网网站建设是针对学协会网站及会员管理建设的一站式在线信息化建设。除了实现学协会建立自己门户网站和管理自己会员的需要外，还提供更多个性化的定制开发，满足学协会各项需求。包括工作动态、专科分会、活动专题、会员中心等几个模块。',
                            imgUrl:require('@/assets/images/toolsItem09.png'),
                        },
                        {
                            id:'0002',
                            title:'分支机构网站',
                            conTitle:'分支机构网站',
                            conText:'学协会二级分会建设，除了实现分会建立二级网站建设需求外，还提供更多个性化的定制开发，满足分会各项其他需求。包括分会动态、定考导航、分会领导、专业培训等几个模块。',
                            imgUrl:require('@/assets/images/toolsItem10.png'),
                        },
                        {
                            id:'0003',
                            title:'会员服务平台',
                            conTitle:'会员服务平台',
                            conText:'会员服务平台是学协会用于对会员进行有效管理的IT系统。通过会员系统，可以实现在线注册、认证、缴费、定考培训等功能。学协会会员可在会员中心直接进入图书馆、查看热点动态及观看会员专属视频，还可在线进行定期考核培训及进行模拟试题测评，以保证会员顺利通过定期考核。包括在线注册、会员制证、定考培训、热点新闻等几个模块。',
                            imgUrl:require('@/assets/images/toolsItem11.png'),
                        },
                        {
                            id:'0004',
                            title:'办公室OA管理系统',
                            conTitle:'办公室OA管理系统',
                            conText:'它是通过先进的计算机技术、网络通信技术、处理单位内部的工作，实现协同审批、辅助办公整理，提升办公效率和规范管理。包括登录、行政工作管理、人事工作管理、公文管理等几个模块。',
                            imgUrl:require('@/assets/images/toolsItem12.png'),
                        },
                    ],
                }
                
            ],
      };
    },
    created(){
        this.searveId=this.$route.query.id;
        this.searveName=this.$route.query.name;
        console.log(this.searveId);

        var then=this;
        this.serverData.forEach(function (item,index){
            if(item.typeId==then.searveName){
                then.itemdata=item
            }
        })
    },
    beforeMount(){
        
    },
    methods: {
      handleClick(tab, event) {
        // console.log(tab, event);
      }
    }
}

</script>

<style>
/* .servicePageList01{ background: #35bcdc;}
.serviceIntroduce{ width: 1200px; background: url(../assets/images/study01.png) center right no-repeat ;margin: 0 auto; height: 360px;font-size: 14px; box-sizing: border-box; padding-left: 50px;}
.serviceIntroduce h3{font-size: 24px; color: #fff; font-weight: normal; padding-top: 40px;}
.serviceIntroduce p{ width: 400px; color: #fff; font-size: 16px; line-height: 32px;margin-top:10px;}
.serviceIntroduce a{ display: block; margin-top:20px;width: 140px;border-radius:5px;font-size: 18px; text-align: center; height: 42px; line-height: 42px; background-color: #f9a709; color: #fff;-moz-box-shadow:0px 0px 10px #999; -webkit-box-shadow:0px 0px 10px #999; box-shadow:0px 0px 10px #999;}
.serviceIntroduce a:hover{ background-color: #fcba3a;}

.servicePageList02{background: #f9f9f9;}
.serviceTools{ }
.toolsTile{ font-size: 24px; font-weight: normal; text-align: center; padding:40px 0 40px 0;}
.toolsTile span{ color: #1ba4e2;}
.serviceTools .el-tabs__item.is-active {color: #ffF;background-color: #409eff;position: relative;}
.serviceTools .el-tabs__item.is-active:after{ position:absolute; width: 52px; height: 26px; background: url(../assets/images/serviceSan.png) center center;}
.serviceTools .el-tabs__item{text-align: center;border-radius: 20px;font-size: 16px; padding: 0 30px; height: 34px; line-height: 34px;}
.serviceTools .el-tabs--top .el-tabs__item.is-top:nth-child(2){padding-left: 30px;}
 .serviceTools .el-tabs--top .el-tabs__item.is-top:last-child{padding-right: 30px;}
.serviceTools .el-tabs__nav-wrap::after{ height: 0px;}
.serviceTools .el-tabs__nav-scroll {    padding-bottom: 35px;}
.serviceTools .el-tabs__active-bar{ bottom:-38px; height: 26px; background: url(../assets/images/serviceSan.png) center center no-repeat; background-size: 40px;}
.serviceTools .el-tabs__header{ margin: 0;}
.serviceTools .el-tabs__header::after{    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: #E4E7ED;
    z-index: 1;}
.serviceTools .el-tabs__nav-wrap{width: 1200px; margin: 0 auto;}
.serviceTools .el-tabs__content{ background: #f7f7f7; padding:15px 0;}
.serviceTools .el-tab-pane{ width: 1200px; margin: 0 auto;}

.toolsItem{ overflow: hidden;}
.toolsItemLeft{ float:left; width: 500px;}
.toolsItemLeft h3{padding: 15px 0 20px 0; font-size: 20px; font-weight: normal;}
.toolsItemLeft p{ color: #576e91; line-height: 30px;}
.toolsItemRight{ float:right;} */
</style>