<template>
<div class="courseBg">
    <div class="courseMain clearfix">
        <div class="courseLeft on">
            <div class="courseDetailNav">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{name:ProjectName=='医师培训'?'BusinessAreaIndex':'newCourseList',params:{id:projiectID}}">{{ProjectName}}列表</el-breadcrumb-item>
                    <!-- <el-breadcrumb-item>活动列表</el-breadcrumb-item> -->
                    <el-breadcrumb-item>课程详情</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="courseDetailCon">
                <div class="courseDetailLeft"><img :src="CB_PCPICTUREURL"></div>
                <div class="courseDetailRight">
                    <h3>{{CB_NAME}}</h3>
                    <p><span>专业：{{ProfessionName}}</span><span>类型：{{ProjectName}}</span></p>
                    <p>课程：<span v-if="{kejianNum}>0">{{kejianNum}}个课件</span><span v-if="{videoNum}>0">{{videoNum}}个视频</span><span>{{shijuanNum}}个试题</span></p>
                    <p>有效期：<span>{{beginyxqDate}}{{endyxqDate}}</span></p>

                    <p>
                        <strong  v-if="CB_REALPRICE">￥{{CB_REALPRICE}}元</strong>
                        <strong class="green" v-if="!CB_REALPRICE && ChargesTypeName">{{ChargesTypeName}}</strong>
                    </p>

                    <!-- <router-link :to="{name:'pay',query:{code:paramsId}}">立即购买</router-link> -->
                    <i v-if="CB_AreaCode!=this.AreaCode" @click="onCourseBagId">{{buy}}</i>
                    <i v-else="CB_AreaCode==this.AreaCode" @click="onCourseBagId">{{Active}}</i>
                </div>
            </div>
            <div class="courseDetailTabs">
                <el-tabs type="border-card">
                    <el-tab-pane label="课程介绍">
                        <div class="detailTabsTitle">课程介绍</div>
                        <div class="detailTabsCon">
                          <div v-html="CB_DESCRIPTION"></div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="课件" v-if="{kejianNum}>0">
                        <div class="detailTabsTitle">课件</div>
                        <div class="detailTabsCon">
                            <ul v-if="courseDetail.CourseBagCoursewares==''?false:true">
                                <li v-for="(item,index) in courseDetail.CourseBagCoursewares" :key="index"><label>课件 {{index+1}}</label><span @click="onclickVideo(item.C_ID)">{{item.C_CoursewareName}}</span><!-- <a href="#">立即学习</a> --></li>
                            </ul>
                            <div class="zanwuDiv" v-else>
                                <img src="../assets/images/zanwu.png" height="400" width="400">
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="视频" v-if="{videoNum}>0">
                        <div class="detailTabsTitle">视频</div>
                        <div class="detailTabsCon">
                            <ul v-if="courseDetail.CourseBagVideos==''?false:true">
                                <li v-for="(item,index) in courseDetail.CourseBagVideos" :key="index"><label>视频 {{index+1}}</label><span>{{item.C_videoName}}</span><!-- <a href="#">立即学习</a> --></li>
                            </ul>
                            <div class="zanwuDiv" v-else>
                                <img src="../assets/images/zanwu.png" height="400" width="400">
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="试题">
                        <div class="detailTabsTitle">试题</div>
                        <div class="detailTabsCon">
                            <ul  v-if="courseDetail.CourseBagItems==''?false:true">
                               <li v-for="(item,index) in courseItems" :key="index"><label>试题 {{index+1}}</label><span>{{item.CI_Caption}}</span><!-- <a href="#">立即学习</a> --></li>
                                <!-- <li><label>课件1</label><span>本课程包针对参加简易程序考核序考核人文医学序考核人文医学人文医学的考生</span><a href="#">立即学习</a></li> -->
                            </ul>
                            <div class="zanwuDiv" v-else>
                                <img src="../assets/images/zanwu.png" height="400" width="400">
                            </div>
                            <div class="detailTabsCon">
                                <p>【{{CB_NAME}}】课程包一共有<span style="color:red;">{{shijuanNum}}</span>道题</p>
                            </div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
        <div class="courseRight">
            <course-right></course-right>
            <div class="courseRightTu"><img src="../assets/images/adver03.jpg"></div>
        </div>
    </div>
</div>

</template>

<script>
import courseRight from '@/components/course/courseRight.vue'
import api from "@/api/index.js";
import consts from "@/common/constants.js"
import { constants } from 'crypto';
import { dateFormat } from '@/common/DateFormat.js'
export default{
    components:{
        courseRight,
    },
    data(){
        return{
            projiectType:'',
            projiectID:'',
            ProjectName:'',
            ProfessionName:'',
            CB_NAME:'',
            CB_PCPICTUREURL:'',
            CB_REALPRICE:'',
            ChargesTypeName:'',
            CB_DESCRIPTION:'',
            CB_AreaCode:'',
            paramsId:'',
            courseItems:[],
            courseDetail:'',
            videoNum:null,
            kejianNum:null,
            shijuanNum:null,
            beginyxqDate:'',
            endyxqDate:'',
            backOrderData:'',
            buy:'立即购买',
            Active:'立即购买',
            AreaCode:consts.Dg_AreaCode
        }
    },
      filters: {
   dateFormat: function(time) {
        if(time!=null&&time!="")
        {
          var date = new Date(time);
          return formatDateTimeToStr(date, "yyyy-MM-dd");
        }else{
          return "";
        }
      }
  },
    created(){
        console.log(this.$route.params.id);
        this.paramsId=this.$route.params.id;
        this.getCourseDetail();
        console.log('数:',this.generateRandom(15));
        // this.dangekejain();
        // this.getxiangmu();
        //             this.postAllInfo();
        //             this.postDangekecehng();
    },
    methods:{
        // 立即购买
        onCourseBagId(){
            console.log("哈哈",this.$route.params.id);
            api.postAddOrder(this.paramsId).then(res=>{
                console.log("立即购买：",res)
                this.backOrderData=res.data;
            }).then(res=>{
                console.log(this.backOrderData.Status);
                if(this.backOrderData.Status == 1){
                    this.$router.push({
                        name: 'pay',
                        query: {
                            active: 0,
                            orderCode:this.backOrderData.rows
                        }
                    })
                }
                if(this.backOrderData.Status == 15){
                    console.log(this.backOrderData.Msg)
                    this.$alert(this.backOrderData.Msg, '购买提示', {
                    distinguishCancelAndClose: true,
                    confirmButtonText: '确定',
                    callback: action => {
                        // this.$message({
                        // type: 'info',
                        // message: `action: ${ action }`
                        // });
                        console.log("测试测试",action);
                        if(action === 'close'){
                            // this.$message({
                            // type: 'info',
                            // message: `关闭按钮`
                            // });
                        }else if(action === 'confirm'){
                            this.$router.push({
                                name: 'pay',
                                query: {
                                    active: 1,
                                    orderCode:this.backOrderData.rows
                                }
                            })
                        }
                    }
                    });

                }
                if(this.backOrderData.Status == 16){
                    console.log(this.backOrderData.Msg)
                    this.$alert(this.backOrderData.Msg, '购买提示', {
                    distinguishCancelAndClose: true,
                    confirmButtonText: '确定',
                    callback: action => {
                        console.log("测试测试",action);
                        if(action === 'close'){
                            return false
                        }else if(action === 'confirm'){
                            this.$router.push({
                                name: 'userCourse',
                                query: {
                                    active: 1,
                                    orderCode:this.backOrderData.rows
                                }
                            })
                        }
                    }
                    });

                }
                if(this.backOrderData.Status == 13){
                    console.log(this.backOrderData.Msg)
                    this.$alert(this.backOrderData.Msg, '购买提示', {
                    distinguishCancelAndClose: true,
                    confirmButtonText: '确定',
                    callback: action => {
                        console.log("测试测试",action);
                        if(action === 'close'){
                            return false
                        }else if(action === 'confirm'){
                        }
                    }
                    });

                }

            }).catch(err=>{
                console.log(err)
            })
        },
        // 项目列表
        getxiangmu(){
            api.getProjectCommboxList()
            .then(res=>{
                console.log('项目类型下拉框数据源',res)
                this.projiectType=res.data
            }).then(res=>{
                this.getProId();
            }).catch(error=>{
                console.log(error);
            })
        },
        getProId(){
            // console.log(this.projiectType);
            this.projiectType.forEach((item,index) => {
                // console.log(this.paramsId);
                if(item.Project_Name == this.ProjectName){
                    this.projiectID = item.ID;
                    console.log("项目Id:",this.projiectID)
                }
            });
        },
        // 获取课程所有信息
        postAllInfo(){
            console.log(this.courseDetail.CB_ID);
            api.postCourseBagInfo(this.courseDetail.CB_ID)
            .then(res=>{
                console.log("课程所有信息:",res)
            })
        },
        // // 查看单个 课程项目的全部内容
        // postDangekecehng(){
        //     console.log(this.courseDetail.CB_ID);
        //     api.PostCourseBagSingleAll(this.courseDetail.CB_ID)
        //     .then(res=>{
        //         console.log("单个课程项目的全部内容:",res)
        //     })
        // },
        // 课件详情
        getCourseDetail(){
           api.postCourseBagInfo(this.paramsId).then(res => {
                this.ProjectName=res.data.ProjectName;
                this.ProfessionName=res.data.ProfessionName;
                this.CB_NAME=res.data.CB_NAME;
                this.CB_PCPICTUREURL=res.data.CB_PCPICTUREURL;
                this.CB_REALPRICE=res.data.CB_REALPRICE;
                this.ChargesTypeName=res.data.CB_TYPE==1?"免费":"收费";
                this.CB_DESCRIPTION=res.data.CB_DESCRIPTION;
                this.shijuanNum=res.data.CB_ItemsCount;
                this.kejianNum=res.data.CB_CoursewareCount;
                this.videoNum=res.data.CB_VideoCount;
                this.CB_AreaCode=res.data.CB_AreaCode;
                if(res.data.CB_STARTTIME!=null)
                    this.beginyxqDate=res.data.CB_STARTTIME.toString().substring(0,10);
                if(res.data.CB_ENDTIME!=null)
                    this.endyxqDate=" 至 "+res.data.CB_ENDTIME.toString().substring(0,10);

            }).then(res=>{
                        this.getxiangmu();
            })
            .catch(err=>{
                        console.log(err)
            });
            api.getCourseBagSingle({
                params:{
                    code:this.paramsId,
                }
            })
            .then(res=>{
                this.courseDetail=res.data;
                if(this.courseDetail.CourseBagItems!="")
                {
                    var items=this.courseDetail.CourseBagItems;
                    for(var i=0;i<2;i++){
                         var itype=items[i].CI_ItemType;
                         switch(itype)
                         {
                           case 1:
                           case 2:
                           case 6:
                               items[i].CI_Caption=items[i].CI_Caption.substring(0,6)+"...";
                               break;
                           case 3:
                           case 4:
                               items[i].CI_Caption=items[i].CI_Caption.substring(10,20)+"...";
                               break;
                           case 5:
                               items[i].CI_Caption=items[i].CI_Caption.substring(1,5)+"...";
                               break;
                         }
                         this.courseItems.push(items[i]);
                    }
                }

            }).then(res=>{
                this.getxiangmu();
            })
            .catch(err=>{
                console.log(err)
            });
        },
        onclickVideo(data){
            api.PostCourseBagVideoGet({
                CourseBagVideoID:data,
                UID:this.generateRandom(15),
            }).then(res=>{
                console.log('单个视频:',res)
            })
        },
        // 生成随机数
        generateRandom(count){
            var array='';
            for(var i = 0 ; i <count; i++){
                var rand = parseInt(Math.random()*9);
                array+=rand;
            }
            return array;
        },
        formateDate(objTime){
            var dt=new Date(objTime);
            console.log("shijjian:"+dt);
            var dtYear=dt.getFullYear();
            var dtMonth=dt.getMonth();
            var dtDate=dt.getDate();

            return dtYear+'-'+getNow(dtMonth)+'-'+getNow(dtDate);
        },
        getNow(s) {
            return s < 10 ? '0' + s : s;
        },
        // // 单个课件详情
        // dangekejain(){
        //     api.getCourseBagCourseware({
        //         params:{
        //             CourseBagCoursewareID:"e18597fd-5765-41c0-8fbe-cb8f88d7327f",
        //         }
        //     }).then(res=>{
        //         console.log('单个课件详情:',res)
        //     }).catch(err=>{
        //         console.log(err);
        //     })
        // }
    },
}

</script>

<style>

</style>
