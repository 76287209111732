<template>
<el-carousel :interval="3000" arrow="always">
    <!-- <el-carousel-item v-for="item in bannerImg" :key="item">
      <h3><img src="../../assets/indexBg02.jpg"></h3>
    </el-carousel-item> -->
    <!-- <el-carousel-item>
      <h3 class="os01"></h3>
    </el-carousel-item> -->
    <el-carousel-item>
        <router-link :to="{name:'areaCourseShow',params:{id:'e2e499fd-48eb-40df-ab76-ef3b19b14fa4'}}" >
            <h3 class="os04"></h3>
        </router-link>
    </el-carousel-item>
    <el-carousel-item>
         <router-link :to="{name:'areaCourseShow',params:{id:this.AreaCode}}" >
            <h3 class="os01"></h3>
         </router-link>
    </el-carousel-item>
    <el-carousel-item>
         <router-link :to="{name:'areaCourseShow',params:{id:'8A10DBA6-7805-4684-AA64-7084BA097FBA'}}" >
            <h3 class="os02"></h3>
         </router-link>
    </el-carousel-item>
    <el-carousel-item>
         <router-link :to="{name:'areaCourseShow',params:{id:'87bb64be-a958-4605-a568-301c9fcc38e7'}}" >
            <h3 class="os03"></h3>
         </router-link>
    </el-carousel-item>
  </el-carousel>
</template>

<script>
import consts from "@/common/constants.js"
export default{
    data(){
        return{
            bannerImg:['../../../assets/index02.png','../../../assets/index05.jpg'],
            AreaCode:consts.Dg_AreaCode
        }
    }

}

</script>

<style>
.os01{ background: url(../../assets/index02.png) center center no-repeat;height: 400px;}
.os02{ background: url(../../assets/index05.jpg?v=1) center center no-repeat;height: 400px;}
.os03{ background: url(../../assets/hcBg.png) center center no-repeat;height: 400px;}
.os04{ background: url(https://cdn.doc-exam.com/cms/1pluse/szdqkh.png) center center no-repeat;height: 400px;}
.el-carousel,.el-carousel .el-carousel__container { height: 400px;}
.el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 400px;
    margin: 0;
    text-align: center;
  }
.el-carousel .el-carousel__arrow--left{left: 5%;}
.el-carousel .el-carousel__arrow--right{right:5%;}
.el-carousel .el-carousel__arrow{height: 45px; width: 45px; font-size: 24px;background-color: rgba(31,45,61,.4);}
.el-carousel .el-carousel__arrow:hover{background-color: rgba(31,45,61,.7);}
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
</style>
