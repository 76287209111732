import axios from '@/axios.js'
var api = {
    // 获取每日一题数据
    getDayTopic(params){
        return axios.get('/api/MondayQuestions/GetMondayQuestionsList',params)
    },
    // 根据CODE获取每周一题的详细内容
    getMondayQuestionsSingle(params){
        return axios.get('/api/MondayQuestions/GetMondayQuestionsSingle',params)
    },
    // 获取图形码
    geCodeImg(params){
        return axios.get('/api/CustomerAccount/VerifyRegister',params)
    },
    // 根据会员ID获取会员信息
    getCustomerAccount(params){
        return axios.get('/api/CustomerAccount/GetCustomerAccountByID',params)
    },
    // 登录
    getLogin(params){
        return axios.post('/api/CustomerAccount/LoginOn',params)
    },
    // 退出登录
    loginOut(data){
        return axios.post('/api/CustomerAccount/LoginOut',data)
    },
    // 注册
    getRegister(data){
        return axios.post('/api/CustomerAccount/RegisterData',data)
    },
    // 获取手机号验证码
    getPhoneCode(params){
        return axios.post('/api/CustomerAccount/GetCode',params)
    },
    // 修改会员信息
    postUpdateCustomerAccount(params){
        return axios.post('/api/CustomerAccount/UpdateCustomerAccount',params)
    },
    //找回密码
    postForgotPassword(params){
        return axios.post('/api/CustomerAccount/ForgotPassword',params)
    },


    // 查看用户的课程包列表
    getTCustomer_CourseBagList(params){
        return axios.post('/api/CustomerCourseBag/GetTCustomer_CourseBagList',params)
    },
    // 分页查看用户的课程包列表
    postTCustomer_CourseBagListByPage(params){
        return axios.post('/api/CustomerCourseBag/TCustomer_CourseBagListByPage?page='+ params.page + '&rows='+ params.rows)
    },

    // 获取项目类型课程列表数据
    getProjectData(params){
        return axios.post('/api/BizCourseBag/GetCourseBagList',params)
    },
    // 专科下拉框数据源
    getTreeComboList(params){
        return axios.get('/api/BizCourseBag/GetSpecialtyTreeComboList',params)
    },
    // 项目类型下拉框数据源
    getProjectCommboxList(params){
        return axios.get('/api/BizCourseBag/GetProjectCommboxList',params)
    },
    // 根据课程包ID获取培训课程详情
    getCourseBagSingle(params){
        return axios.get('/api/BizCourseBag/GetCourseBagSingle',params)
    },
    // 所有会员
    getAllUser(params){
        return axios.get('/api/Home/GetUsers',params)
    },
    // 查看单个课件
    getCourseBagCourseware(params){
        return axios.get('/api/CourseBagCourseware/GetCourseBagCourseware',params)
    },
    // 查看单个 课程项目的全部内容
    PostCourseBagSingleAll(params){
        return axios.post('/api/CourseBagItem/GetCourseBagItemSingleAll?CourseBagID=' + params)
    },
    // 按ID查单个视频
    PostCourseBagVideoGet(params){
        return axios.post('/api/CourseBagVideo/CourseBagVideoGet?CourseBagVideoID='+params.CourseBagVideoID+'&UID='+params.UID)
    },
    // PostCourseBagVideoGet(params){
    //     return axios.post('/api/CourseBagVideo/CourseBagVideoGet',params)
    // },
    // 获取单个课程包 的全部内容
    postCourseBagInfo(params){
        //return axios.post('/api/CourseBag/GetCourseBagSingleAll?CourseBagID=' + params)
        return axios.post('/api/CourseBag/CourseBag?CourseBagID=' + params)
    },
    // 按时间倒序 取前六条课程包数据作为 最热课程
    postCourseBagHotList(params){
        return axios.post('/api/CourseBag/CourseBagHotList?num=' + params)
    },
    // 按订单ID查单个课程包 的全部内容
    postCourseBagSingleAllGetByOrderID(params){
        //return axios.post('/api/CourseBag/CourseBagSingleAllGetByOrderID?OrderID=' + params)
        return axios.post('/api/CourseBag/CourseBagByOrderID?OrderID=' + params)
    },
    // 查单个课程包
    postTCustomerScoreByID(params){
        return axios.post('/api/TCustomer_Score/GetTCustomerScoreByID?TCustomer_ScoreID=' + params)
    },
    // 按课程包id 查单个课程包 的全部内容
    postkechengID(params){
        return axios.post('/api/CourseBag/GetCourseBagSingleAll?CourseBagID=' + params)
    },



    // 获取全部区域
    getAllAreaList(params){
        return axios.post('/api/SyaArea/GetAllAreaList',params)
    },
    // 获取某一区域及下属全部区域
    getSonAreaList(params){
        return axios.post('/api/SyaArea/GetAllAreaList',params)
    },
    // 用于绑定职业下拉框的数据源
    getProfessionList(params){
        return axios.get('/api/BizCourseBag/GetProfessionList',params)
    },
    // 用于绑定职称下拉框的数据源  api/BizCourseBag/GetProfessionaTitle?code={code}
    getProfessionaTitle(params){
        return axios.get('/api/BizCourseBag/GetProfessionaTitle',params)
    },



    // 会员所属订单列表
    getBizOrderListPage(params){
        return axios.get('/api/BizOrder/GetBizOrderListPage',params)
    },
    // 根据订单ID获取订单详情
    getBizOrderSingle(params){
         return axios.get('/api/BizOrder/GetBizOrderSingle',params)
    },
    // 查单个order的基本信息
    postOrder(params){
        return axios.post('/api/Order/GetOrder?OrderNo=' + params)
    },
    // 查单个order的 详细信息
    postOrderInfo(params){
        return axios.post('/api/Order/GetOrderInfo?OrderNo=' + params)
    },
    // 查批量全部
    postAllOrderList(params){
        return axios.post('/api/Order/GetAllOrderList',params)
    },
    // 分页查订单  api/Order/GetOrderList?page={page}&rows={rows}
    getAllOrderListPage(params){
        return axios.post('/api/Order/GetAllOrderList',params)
    },
    // 修改订单
    postUpdateOrder(params){
        return axios.post('/api/Order/UpdateOrder',params)
    },
    // 添加订单 api/Order/AddOrder?courseBagId={courseBagId}
    postAddOrder(params){
        return axios.post('/api/Order/AddOrder?courseBagId=' + params)
    },
    // 删除订单 api/Order/DelOrder?OrderID={OrderID}
    postDelOrder(params){
        return axios.post('/api/Order/DelOrder?orderNo=' + params)
    },
    // 查批量用户自己全部订单,按创建时间倒序排列
    postAllOrderListTime(params){
        return axios.post('/api/Order/GetAllOrderList?status='+ params.status)
    },
    // 查批量用户自己全部订单,按创建时间倒序排列 分页
    postOrderListTime(params){
        // return axios.post('/api/Order/GetAllOrderList',params)
        return axios.post('/api/Order/GetOrderList?page=' + params.page+ '&rows='+params.rows+ '&status='+params.status)
    },

    // 接受支付宝支付通知地址
    postAlipayNotify(params){
        return axios.post('/api/Pay/Alipay?orderNo=' + params)
    },
    // 接受微信支付通知地址
    postWxpayNotify(params){
        return axios.post('/api/Pay/Wxpay?orderNo=' + params)
    },
    //学习卡支付
    postLearningCardPay(params){
        return axios.post('/api/Pay/LearningCardPay',params);
    },
    //支付成功
    postOrderPayStatus(params){
        return axios.post('/api/Order/OrderPayStatus?orderNo='+params);
    },
    //获取课程包下的所有题ID不包含子题ID
    postCourseBagItemIDListGet(params){
        return axios.post('/api/CourseBagItem/CourseBagItemIDListGet?CourseBagID='+params);
    },
    // 查看用户某个课程包的可用次数
    canNumCb(params){
        return axios.post('/api/CustomerCourseBag/TCustomer_CourseBagExamTimes?courseBagId=' + params)
    },
    //模拟题的所有ID包含子题ID
    postGetMockExamItemIDList(params){
        return axios.post('/api/TCustomer_Score/SelectMockExamItemIDList?CourseBagID='+params);
    },
    //模拟题的单题信息（包含题干和选项）
    postGetSelectMockExamItemSingle(params){
        return axios.post('/api/TCustomer_Score/SelectMockExamItem?itemID='+params);
    },
    //刷题的所有ID包含子题ID
    postCourseBagItemIDAllListGet(params){
        return axios.post('/api/CourseBagItem/CourseBagItemIDAllListGet?CourseBagID='+params);
    },
    //刷题中提交的单题答案
    postAddTCustomerAnswer(params){
        return axios.post('/api/CustomerAnswer/AddTCustomer_Answer',params);
    },
    //模拟考中提交单题答案
    postReceiveCustomerAnswer(params){
        return axios.post('/api/TCustomer_Score/ReceiveCustomerAnswer',params);
    },
    //最终提交模拟试卷返回考试分数
    postSubmitMockExam(params){
        return axios.post('/api/TCustomer_Score/SubmitMockExam',params);
    },
    //按课程包id 查单个课程包里的所有课件 的基本信息
    postCourseBagCousewares(params){
        return axios.post('/api/CourseBag/CourseBagCousewares?CourseBagID='+params);
    },
    //按课程包id 查单个课程包的所有视频 的基本信息
    postCourseBagVideos(params){
        return axios.post('/api/CourseBag/CourseBagVideos?CourseBagID='+params);
    },

    //基本信息中所在区域，获取所有区域信息用于下拉框绑定
    postAllAreaList(){
        return axios.get('/api/SysArea/GetAllAreaList');
    },
    //编辑当前登录账户的扩展信息
    postUpdateGetCustomerExtend(params){
        return axios.post('/api/CustomerAccount/UpdateGetCustomerExtend',params);
    },
    //获取当前登录用户的扩展信息
    postGetCustomerExtend(){
        return axios.get('/api/CustomerAccount/GetCustomerExtend');
    },
    //根据课程包ID和题目ID获取单题的答案
    postGetTCustomerAnswerByCourseIDAndItemID(params){
        return axios.post('/api/CustomerAnswer/TCustomer_AnswerGetByCourseIDitemID?courseID='+params.courseID+'&ItemID='+params.ItemID);
    },
    //根据课程包ID获取与课程包相关的所有题的ID和题对应的答案
    postGetCourseBagItemIDAndAnswerList(params){
        return axios.post('/api/CourseBagItem/CourseBagItemIDAndAnswerListGet?CourseBagID='+params);
    },
    //查课程包中单个题目的全部内容
    postGetCourseBagItemSingleAll(params){
        return axios.post('/api/CourseBagItem/GetCourseBagItemSingleAll?CourseBagItemID='+params);
    },
    postGetBusinessAreaList(params){
        return axios.get('/api/SysArea/GetBusinessAreaList?pageIndex='+params.page+'&pageSize='+params.rows);
    },
    postGetBusinessAreaByCode(params){
        return axios.get('/api/SysArea/GetBusinessAreaByCode?code='+params);
    },
    proxy: {
        '/upload': { //这里为后端存放资源的一级路径(根据你后端返回的来定)，我这里是第一个图片url里面的的“/upload”
            target: ' http://localhost:8080/', //这里填写后端存放资源文件的域名
        },
    },
    //刷题中提交的单题答案
    AddTCustomer_AnswerAndViewAnalysis(params){
        return axios.post('/api/CustomerAnswer/AddTCustomer_AnswerAndViewAnalysis',params);
    },
    // 专科列表（根据地区四大类类型(项目)等查询条件查询专科）
    GetProssesionList(params){
        return axios.post('/api/CourseBag/GetProssesionList',params);
    },
    //课程包列表（根据项目类型等查询条件查询课程包，地区-四大类-专科）
    GetCourseBagList(params){
        return axios.post('/api/CourseBag/GetCourseBagList',params);
    },
    //获取PDF目录
    postEbookCatalog(params){
        return axios.get('/api/Ebook/EbookCatalog?bookIndex='+params);
    },
    //获取用户标签
    postEbookCustomerMark(params){
        return axios.get('/api/Ebook/EbookCustomerMark?bookIndex='+params);
    },
    postEbookCustomerAddMark(params){
        return axios.post('/api/Ebook/SetEbookCustomerMark',params);
    },

    postEbookCustomerDeleteMark(params){
        return axios.get('/api/Ebook/DelEbookCustomerMark?markId='+params);
    },
}
export default api;
