<template>
<div>
    <user-top></user-top>
    <div class="mainWrap">
        <div class="mainMiddle clearfix">
            <div class="userLeft">
                <el-menu
                
                class="el-menu-vertical-demo"
                @open="handleOpen"
                @close="handleClose">
                    <!-- <el-submenu index="1">
                        <template slot="title">
                        <i class="el-icon-location"></i>
                        <router-link :to="{name:'userOrder'}" tag="span">我的订单</router-link>
                        </template>
                        <el-menu-item-group>
                        <template slot="title">分组一</template>
                        <el-menu-item index="1-1">选项1</el-menu-item>
                        <el-menu-item index="1-2">选项2</el-menu-item>
                        </el-menu-item-group>
                        <el-menu-item-group title="分组2">
                        <el-menu-item index="1-3">选项3</el-menu-item>
                        </el-menu-item-group>
                        <el-submenu index="1-4">
                        <template slot="title">选项4</template>
                        <el-menu-item index="1-4-1">选项1</el-menu-item>
                        </el-submenu>
                    </el-submenu> -->
                    <router-link tag="el-menu-item" index="1" :to="{name:'userOrder'}">
                        <i class="el-icon-document"></i>
                        <span>我的订单</span>
                    </router-link>
                    <router-link tag="el-menu-item" index="2" :to="{name:'userCourse'}">
                        <i class="el-icon-s-grid"></i>
                        <span>我的课程</span>
                    </router-link>
                    <!-- <router-link tag="el-menu-item" index="3" :to="{name:'userHospital'}">
                        <i class="el-icon-office-building"></i>
                        <span>我的医院</span>
                    </router-link>
                    <router-link tag="el-menu-item" index="4" :to="{name:'userSchool'}">
                        <i class="el-icon-date"></i>
                        <span>我的学校</span>
                    </router-link> -->
                    <router-link tag="el-menu-item" index="5" :to="{name:'userBase'}">
                        <i class="el-icon-tickets"></i>
                        <span>基础信息</span>
                    </router-link>
                    <!-- <router-link tag="el-menu-item" index="6" :to="{name:'userZhang'}">
                        <i class="el-icon-setting"></i>
                        <span>账户管理</span>
                    </router-link> -->
                    <!-- <el-menu-item index="1">
                        <i class="el-icon-menu"></i>
                        <router-link :to="{name:'userOrder'}" tag="span">我的订单</router-link>
                    </el-menu-item>
                    <el-menu-item index="2">
                        <i class="el-icon-menu"></i>
                        <router-link slot="title" :to="{name:'userCourse'}" tag="span">我的课程</router-link>
                    </el-menu-item>
                    <el-menu-item  index="3">
                            <i class="el-icon-document"></i>
                            <router-link slot="title" :to="{name:'userHospital'}" tag="span">我的医院</router-link>
                    </el-menu-item>
                    <el-menu-item index="4">
                        <i class="el-icon-setting"></i>
                        <router-link slot="title" :to="{name:'userSchool'}" tag="span">我的学校</router-link>
                    </el-menu-item>
                    <el-menu-item index="5">
                        <i class="el-icon-setting"></i>
                        <router-link slot="title" :to="{name:'userBase'}" tag="span">基础信息</router-link>
                    </el-menu-item>
                    <el-menu-item index="6">
                        <i class="el-icon-setting"></i>
                        <router-link slot="title" :to="{name:'userZhang'}" tag="span">账户管理</router-link>
                    </el-menu-item> -->
                </el-menu>
            </div>
            <dir class="userRight">
                <router-view></router-view>
            </dir>
        </div>
    </div>
    <footer class="footerBottom"><footer-bottom></footer-bottom></footer>
  </div>

</template>

<script>
import headerTop from '@/components/header/headerTop.vue'
import userTop from '@/components/user/userTop.vue'
import footerBottom from '@/components/footer/footerBottom'
export default{
    components:{
        headerTop,
        footerBottom,
        userTop,
    },
    methods: {
      handleOpen(key, keyPath) {
        console.log(key, keyPath);
      },
      handleClose(key, keyPath) {
        console.log(key, keyPath);
      }
    }
}

</script>

<style>
/* .mainWrap{background-color:#f0f4f5}
.mainMiddle{ width: 1200px; margin: 0 auto;padding:20px 0;}
.userLeft{ float: left; width: 175px;}
.userRight{ float: right; width: 1005px;}

.userLeft .el-menu-item:focus,.userLeft .el-menu-item:hover{ background-color: #fff;}
.userLeft .el-menu-item:hover span{ color:#28a3dc; }
.userLeft>.el-menu{ border-radius: 10px; border: none; overflow: hidden;min-height: 500px; font-size: 16px;}
.userLeft .el-menu-item,.userLeft .el-submenu{ border-bottom: 1px solid #eee;}
.userLeft .active i{color: #28a3dc;}
.userLeft .active span{color: #28a3dc;} */
</style>