<template>
<div>
    <div class="ConRightNav">
      <router-link :to="{name:'index'}">首页</router-link>
      <strong>></strong>
      <span>帮助中心</span>
      <strong>></strong>
      <span>服务协议</span>
    </div>
    <div class="ConRightText">
        <div class="H_c_r_text">
                	<div class="H_c_r_t_title">服务协议</div>
                    <div class="H_c_r_t_con">
                    	<div class="H_c_h3con">
                        	<h2>1、协议生效条件</h2>
                            <p>医+E（医加E）服务平台的各项服务的所有权和运作权归平台所有。医+E（医加E）服务平台提供的服务将完全按照其发布的章程、服务条款和操作规则严格执行。用户完全完成注册程序，便成为医+E（医加E）服务平台的注册用户，将得到一个密码和帐号，同时此协议即时生效。用户有义务保证密码和帐号的安全。用户对利用该密码和帐号所进行的一切活动负全部责任；因此所衍生的任何损失或损害，医+E（医加E）服务平台无法也不承担任何责任。</p>
                        </div>
                        <div class="H_c_h3con">
                        	<h2>2、注册义务</h2>
                            <p>医+E（医加E）服务平台运用自己的操作系统通过国际互联网为用户提供网络服务。同时，用户必须：</p>
                            <p>(1) 自行配备上网的所需设备，包括个人电脑、调制解调器或其他必备上网装置；</p>
                            <p>(2) 自行负担个人上网所支付的与此服务有关的电话费用、网络费用。</p>
                            <p>为了能使用本服务，您同意以下事项：</p>
                            <p>(1) 依本服务注册表的提示提供您本人真实、正确、最新及完整的资料；</p>
                            <p>(2) 维持更新您个人用户信息，确保其真实、正确、最新及完整。若您提供任何错误、不实、过时或不完整的资料，并为平台所确知，或者平台有合理的理由怀疑前述资料为错误、不实、过时或不完整，医+E（医加E）服务平台有权暂停或终止您的帐号，并拒绝您于现在和未来使用医+E（医加E）服务平台全部或部分的服务。</p>
                            <p>(3) 您注册的用户名不能侵犯他人合法权益，不能对他人名誉权造成侵犯。平台有权收回对医+E（医加E）服务平台或者他人合法权益有侵犯嫌疑的帐号。</p>
                        </div>
                        <div class="H_c_h3con">
                        	<h2>3、隐私制度</h2>
                            <p>尊重用户个人隐私是医+E（医加E）服务平台的一项基本政策。所以，医+E（医加E）服务平台不会在未经合法用户授权时，公开、编辑或透露其个人信息及保存在医+E（医加E）服务平台中的非公开内容，除非有下列情况：</p>
                            <p>(1) 有关法律规定和程序，医+E（医加E）服务平台合法服务程序规定；</p>
                            <p>(2) 保持维护医+E（医加E）服务平台的商标所有权和其他合法权益；</p>
                            <p>(3) 在紧急情况下竭力维护用户个人和社会大众的隐私安全；</p>
                            <p>(4) 与医+E（医加E）服务平台的关联公司和战略合作伙伴分享客户资料，以及其他需要公开、编辑或透露个人信息的情况。</p>
                            <p>另外，在以下（包括但不限于）几种情况下，医+E（医加E）服务平台有权使用用户的个人信息：</p>
                            <p>(1) 在进行促销、抽奖、捐助等社会或商业活动时，医+E（医加E）服务平台可能会与赞助商共享用户的个人信息，在这些情况下医+E（医加E）服务平台会在发送用户信息之前进行提示，并且用户可以通过不参与来终止传送过程；</p>
                            <p>(2) 医+E（医加E）服务平台会通过透露合计用户统计数据，向未来的合作伙伴、广告商及其他第三方以及为了其他合法目的而描述医+E（医加E）服务平台的服务；</p>
                            <p>(3) 医+E（医加E）服务平台可以将用户信息与第三方数据匹配；</p>
                            <p>(4) 医+E（医加E）服务平台会向用户发送客户订制的信息或者其它医+E（医加E）服务平台认为用户会感兴趣的其他信息。如果用户不希望收到这样的信息，只需在提供个人信息时或其他任何时候告知即可；</p>
                            <p>(5) 另外，医+E（医加E）服务平台会采取行业惯用措施保护用户信息的安全，但医+E（医加E）服务平台不能确信或保证任何个人信息的安全性，用户须自己承担风险。比如用户联机公布可被公众访问的个人信息时，用户有可能会收到未经用户同意的信息；医+E（医加E）服务平台的合作伙伴和可通过医+E（医加E）服务平台访问的第三方因特网站点和服务；通过抽奖、促销等活动得知用户个人信息的第三方会进行独立的数据收集工作等活动。医+E（医加E）服务平台对用户及其他任何第三方的上述行为，不承担任何责任。</p>
                        </div>
                        <div class="H_c_h3con">
                        	<h2>4、用户信息的存储与限制</h2>
                            <p>医+E（医加E）服务平台不对用户所发布信息的删除或储存失败负责。平台并不承诺对用户的存储信息进行无限期保留。除非本协议中另有规定，否则医+E（医加E）服务平台不保证服务一定会满足用户的使用要求，也不保证服务不会受中断，对服务的及时性、安全性、准确性也不作担保。医+E（医加E）服务平台有判定用户的行为是否符合医+E（医加E）服务平台服务条款的要求和精神的保留权利，如果用户违背了服务条款的规定，医+E（医加E）服务平台有中断对其提供网络服务的权利。医+E（医加E）服务平台运用自己的操作系统通过国际互联网向用户提供丰富的网上资源，包括各种信息工具、网上论坛、个性化内容等。除非另有明确规定，增强或强化目前服务的任何新功能，包括新产品、栏目，均无条件地适用本服务条款。</p>
                        </div>
                        <div class="H_c_h3con">
                        	<h2>5、服务条款的修改</h2>
                            <p>医+E（医加E）服务平台有权在必要时修改服务条款。医+E（医加E）服务平台服务条款一旦发生变动，将会在重要页面上提示所修改的内容。如果不同意所改动的内容，用户可以主动取消获得的网络服务。如果用户继续享用网络服务，则视为接受服务条款的变动。医+E（医加E）服务平台保留随时修改或中断服务而不需照知用户的权利。医+E（医加E）服务平台行使修改或中断服务的权利，不需对用户或第三方负责。</p>
                        </div>
                        <div class="H_c_h3con">
                        	<h2>6、拒绝提供担保</h2>
                            <p>用户对网络服务的使用承担风险。医+E（医加E）服务平台对此不作任何类型的担保，不论是明确的或隐含的，但是不对商业性的隐含担保、特定目的和不违反规定的适当担保作限制。</p>
                        </div>
                        <div class="H_c_h3con">
                        	<h2>7、有限责任</h2>
                            <p>医+E（医加E）服务平台对任何直接、间接、偶然、特殊及继起的损害不负责任，这些损害可能来自：不正当使用网络服务，在网上购买商品或进行同类型服务，在网上进行交易，非法使用网络服务或用户传送的信息有所变动。</p>
                        </div>
                        <div class="H_c_h3con">
                        	<h2>8、服务的结束</h2>
                            <p>用户或医+E（医加E）服务平台可随时根据实际情况中断一项或多项网络服务。医+E（医加E）服务平台不需对任何个人或第三方负责而随时中断服务。用户对后来的条款修改有异议，或对医+E（医加E）服务平台的服务不满，可以行使如下权利：</p>
                        	<p>(1) 停止使用医+E（医加E）服务平台的网络服务；</p>
                            <p>(2) 通告医+E（医加E）服务平台停止对该用户的服务。结束用户服务后，用户使用网络服务的权利马上中止。从那时起，用户没有权利，医+E（医加E）服务平台也没有义务传送任何未处理的信息或未完成的服务给用户或第三方。</p>
                        </div>
                        <div class="H_c_h3con">
                        	<h2>9、用户管理</h2>
                            <p>用户单独承担发布内容的责任。用户对服务的使用是根据所有适用于医+E（医加E）服务平台的国家法律、地方法律和国际法律标准的。用户必须遵循：</p>
                            <p>(1) 从中国境内向外传输技术性资料时必须符合中国有关法规；</p>
                            <p>(2) 使用网络服务不作非法用途；</p>
                            <p>(3) 不干扰或混乱网络服务；</p>
                            <p>(4) 遵守所有使用网络服务的网络协议、规定、程序和惯例。</p>
                            <p>(5) 用户的密码和帐号遭到未授权的使用或发生其他任何安全问题，用户可以立即通知医+E（医加E）服务平台，并且用户在每次连线结束，应结束帐号使用，否则用户可能得不到医+E（医加E）服务平台的安全保护。</p>
                            <p>(6) 禁止用户从事以下行为：</p>
                            <p>1) 上载、张贴、发送或传送任何非法、反动、淫秽、粗俗、猥亵的，胁迫、骚扰、中伤他人、诽谤、侵害他人隐私或诋毁他人名誉或商誉的，种族歧视、危害未成年人或其他不适当的信息或电子邮件，包括但不限于资讯、资料、文字、软件、音乐、照片、图形、信息或其他资料（以下简称内容）。</p>
                            <p>2) 未经平台许可的广告行为。</p>
                            <p>3) 冒充任何人或机构，或以虚伪不实的方式谎称或使人误认为与任何人或任何机构有关。</p>
                            <p>4) 伪造标题或以其他方式操控识别资料，使人误认为该内容为医+E（医加E）服务平台所传送。</p>
                            <p>5) 上载、张贴、发送电子邮件或以其它方式传送无权传送的内容（例如内部资料、机密资料）。</p>
                            <p>6) 上载、张贴、发送电子邮件或以其它方式传送侵犯任何人的商标、著作权、商业秘密或其他专属权利之内容。</p>
                            <p>7) 在医+E（医加E）服务平台提供的专供张贴广告的区域之外，上载、张贴、发送电子邮件或以其他方式传送广告函件、促销资料、“垃圾邮件”等。</p>
                            <p>8) 上载、张贴、发送电子邮件或以其他方式传送有关干扰、破坏或限制任何计算机软件、硬件或通讯设备功能的软件病毒或其他计算机代码、档案和程序之资料。</p>
                            <p>9) 干扰或破坏本服务或与本服务相连的服务器和网络，或不遵守本服务协议之规定。</p>
                            <p>10) 故意或非故意违反任何相关的中国法律、法规、规章、条例等其他具有法律效力的规范。</p>
                            <p>11) 跟踪或以其他方式骚扰他人。</p>
                            <p>12) 其它被医+E（医加E）服务平台视为不适当的行为。</p>
                            <p>另外，用户对经由本服务上载、张贴、发送电子邮件或传送的内容负全部责任；对于经由本服务而传送的内容，医+E（医加E）服务平台不保证前述内容的正确性、完整性或品质。用户在接受本服务时，有可能会接触到令人不快、不适当或令人厌恶的内容。在任何情况下，医+E（医加E）服务平台均不对任何内容负责，包括但不限于任何内容发生任何错误或纰漏以及衍生的任何损失或损害。医+E（医加E）服务平台有权（但无义务）自行拒绝或删除经由本服务提供的任何内容。用户使用上述内容，应自行承担风险。</p>
                            <p>医+E（医加E）服务平台有权利在下述情况下，对内容进行保存或披露：</p>
                            <p>(1) 法律程序所规定；</p>
                            <p>(2) 本服务条款规定；</p>
                            <p>(3) 被侵害的第三人提出权利主张；</p>
                            <p>(4) 为保护医+E（医加E）服务平台、其使用者及社会公众的权利、财产或人身安全；</p>
                            <p>(5) 其他医+E（医加E）服务平台认为有必要的情况。</p>
                        </div>
                        <div class="H_c_h3con">
                        	<h2>10、用户的广告宣传</h2>
                        	<p>用户在自己发表的信息中加入宣传资料或参与广告策划，在医+E（医加E）服务平台的免费服务上展示他们的产品，任何这类促销方法，包括运输货物、付款、服务、商业条件、担保及与广告有关的描述都只是在相应的用户和广告销售商之间发生。平台不承担任何责任，平台没有义务为这类广告销售负任何一部分的责任。同时，如果该行为未经平台同意或者对平台产生利益侵犯，平台有权利进行删除、屏蔽，以及采取相应的技术、法律手段阻止此类信息的发布。</p>
                        </div>
                        <div class="H_c_h3con">
                        	<h2>11、关于用户在医+E（医加E）服务平台的公开使用区域的内容</h2>
                        	<p>公开使用区域”包括网上论坛、留言版、评论区和其它一般公众可以使用的网络内容区域；用户一旦在本服务公开使用区域张贴内容，即视为用户授予医+E（医加E）服务平台该内容著作权之免费及非独家许可使用权，医+E（医加E）服务平台有权为展示、传播及推广前述张贴的内容之服务目的，对上述内容进行复制、修改、出版。</p>
                            <p>由此展示、传播及推广行为所产生的损失或利润，均由医+E（医加E）服务平台承担或享受。医+E（医加E）服务平台有权自主决定是否给予此类用户鼓励或奖励。</p>
                            <p>因用户进行上述张贴，而导致任何第三方提出索赔要求或衍生的任何损害或损失，用户须承担全部责任。</p>
                            <p>其他访问者或者企业、单位，未经平台授权，不得对他人张贴在公开使用区域或本服务其他内容进行复制、出售或用作其他商业用途。</p>
                        </div>
                        <div class="H_c_h3con">
                        	<h2>12、关于手机服务</h2>
                        	<p>(1) 医+E（医加E）服务平台为用户提供医+E（医加E）服务平台手机短信服务。一旦用户在注册本服务的过程输入自己的手机号码和保护码并完成登记注册程序，则表明用户愿意接受医+E（医加E）服务平台发送的短信，此服务为免信息费服务，医+E（医加E）服务平台不向用户收取任何费用，用户主动发送的短信产生的费用，由用户向移动运营商支付，与医+E（医加E）服务平台无关。若用户通过注册并使用了医+E（医加E）服务平台的收费短信服务，则表示用户同意通过运营商支付信息费给医+E（医加E）服务平台，此服务产生的信息费由运营商代为收取。手机短信服务的具体收费说明见具体服务的相应页面。</p>
                            <p>(2) 医+E（医加E）服务平台不收取用户任何费用，用户上网产生可能产生的流量费，由用户向运营商支付，与医+E（医加E）服务平台无关。</p>
                            <p>(3) 医+E（医加E）服务平台不承担用户由于手机故障、欠费、丢失、借用或盗用后他人利用手机订购医+E（医加E）服务平台短信息服务而遭受的损失或并非由于医+E（医加E）服务平台的原因而导致的任何其它损失。对于用户出国并使用国际漫游服务但未取消已定制的短信息服务，或者在国际漫游状态下继续使用短信息点播服务，用户应当承担相关所有费用，医+E（医加E）服务平台不承担任何责任。</p>
                            <p>(4) 用户自己承担系统受损或资料丢失的所有风险和责任。医+E（医加E）服务平台对于删除和存储数据失败并不负有任何责任。</p>
                            <p>(5) 如因医+E（医加E）服务平台的过错，导致用户接收到错误的信息并支付了不应支付的信息费，医+E（医加E）服务平台的全部责任仅限于赔偿用户因错误信息而支付的信息费。</p>
                        </div>
                        <div class="H_c_h3con">
                        	<h2>13、关于链接</h2>
                        	<p>本服务可能会提供与其他国际互联网网站或资源进行链接。对于前述网站或资源是否可以利用，医+E（医加E）服务平台不予担保。因使用或依赖上述网站或资源所产生的损失或损害，医+E（医加E）服务平台也不承担任何责任。</p>
                        </div>
                        <div class="H_c_h3con">
                        	<h2>14、医+E（医加E）服务平台的知识产权及其他权利</h2>
                        	<p>医+E（医加E）服务平台对本服务及本服务所使用的软件和受知识产权或其他法律保护的资料享有相应的权利；</p>
                            <p>经由本服务传送的资讯及内容，受到著作权法、商标法或其他法律的保护；未经医+E（医加E）服务平台明示</p>
                            <p>授权许可，用户不得进行修改、出租、散布或衍生其他作品，用户本人创作并在公开使用区域张贴的内容除外。</p>
                            <p>用户对本服务所使用的软件有非专属性使用权，但自己不得或许可任何第三方复制、修改、出售或衍生产品。</p>
                            <p>医+E（医加E）服务平台、医+E（医加E）服务平台设计图样以及其他医+E（医加E）服务平台图样、产品及服务名称，均为平台所享有的商标，未经平台事先书面授权，任何人不得使用、复制或用作其他用途。</p>
                        </div>
                        <div class="H_c_h3con">
                        	<h2>15、免责声明</h2>
                        	<p>医+E（医加E）服务平台对于本服务包含的或用户经由或从任何与本服务有关的途径所获得的任何内容、信息或广告，不声明或保证其正确性或可靠性；并且对于用户经本服务上的广告、展示而购买、取得的任何产品、信息或资料，医+E（医加E）服务平台不负保证责任。用户自行承担担使用本服务的风险。</p>
                        	<p>(1) 医+E（医加E）服务平台有权但无义务，改善或更正本服务任何部分之任何疏漏、错误。</p>
                            <p>(2) 医+E（医加E）服务平台不保证以下事项（包括但不限于）：</p>
                            <p>1) 本服务适合用户的使用要求；</p>
                            <p>2) 本服务不受干扰，及时、安全、可靠或不出现错误；</p>
                            <p>3) 用户经由本服务取得的任何产品、服务或其他材料符合用户的期望；</p>
                            <p>4) 用户使用经由本服务下载的或取得的任何资料，其风险自行负担；因该使用而导致用户电脑系统损坏或资料流失，用户应负完全责任；</p>
                            <p>5) 对基于以下原因而造成的利润、商业信誉、资料的损失或其他有形或无形损失，医+E（医加E）服务平台不承担任何直接、间接、附带、衍生或惩罚性的赔偿；</p>
                            <p>6) 服务使用或无法使用；</p>
                            <p>7) 经由本服务购买或取得的任何产品、资料或服务；</p>
                            <p>8) 用户资料遭到未授权的使用或修改；</p>
                            <p>9) 其他与本服务相关的事宜。</p>
                            <p>(3) 用户在浏览网际网路时自行判断使用医+E（医加E）服务平台的检索目录。该检索目录可能会引导用户进入到被认为具有攻击性或不适当的网站，医+E（医加E）服务平台没有义务查看检索目录所列网站的内容，因此，对其正确性、合法性、正当性不负任何责任。</p>
                            <p>用户同意，对于平台向用户提供的下列产品或者服务的质量缺陷本身及其引发的任何损失，搜房无需承担任何责任：</p>
                            <p>1) 平台向用户免费提供的各项网络服务；</p>
                            <p>2) 平台向用户赠送的任何产品或者服务；</p>
                            <p>3) 平台向收费网络服务用户附赠的各种产品或者服务。</p>
                        </div>
                        <div class="H_c_h3con">
                        	<h2>16、法律</h2>
                        	<p>网络服务条款要与中华人民共和国的法律解释相一致，用户和医+E（医加E）服务平台一致同意服从高等法院所管辖。如发生医+E（医加E）服务平台服务条款与中华人民共和国法律相抵触时，则这些条款将完全按法律规定重新解释，而其它条款则依旧保持对用户产生法律效力和影响。</p>
                            <p>在任何情况下，医+E（医加E）服务平台用户不得利用医+E（医加E）服务平台进行违反或可能违反国家法律和法规的言论或行为，否则，平台可在任何时候不经任何事先通知终止向您提供服务。并且用户对自己的言论或行为负责。</p>
                            <p>用户应遵守以下法律及法规：</p>
                            <p>(1) 用户同意遵守《中华人民共和国保守国家秘密法》、《中华人民共和国计算机信息系统安全保护条例》、《计算机软件保护条例》、信息产业部2000年10月8日第4次部务会议通过的《互联网电子公告服务管理规定》，以及《互联网新闻信息服务管理规定》等有关计算机及互联网规定的法律和法规、实施办法。在任何情况下，平台合理地认为用户的行为可能违反上述法律、法规，平台可以在任何时候，不经事先通知终止向该用户提供服务。</p>
                            <p>(2) 用户应了解国际互联网的无国界性，应特别注意遵守当地所有有关的法律和法规。</p>
                        </div>
                    </div>
                </div>
    </div>
</div>

</template>

<script>
export default{

}

</script>

<style>
</style>
