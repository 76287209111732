<template>
  <div class="weTest">
    <div class="weTestTitle">
      随机练习题
      <span>[ 共计{{ courswareNum }}题 ]</span>
    </div>
    <div class="weTestCode">
      <el-popover placement="bottom" width="400" trigger="click">
        <div class="weTestCodeItem">
          <h3>
            <span></span>未作答&nbsp;&nbsp;&nbsp;
            <span class="blueLi"></span>已作答
          </h3>
          <ul>
            <li v-for="(item, index) in courswareNum" :id="item" :class="{ blueLi:index==selectIndex }" :key="index" @click="clickItem(item)">{{item}}</li>
            <!-- <li class="blueLi">2</li>
            <li>3</li>
            <li>4</li>
            <li>5</li>
            <li>6</li>
            <li class="blueLi">7</li>
            <li>8</li>
            <li>9</li>
            <li>10</li>
            <li>1</li>
            <li class="blueLi">2</li>
            <li>3</li>
            <li>4</li>
            <li>5</li>
            <li>6</li>
            <li class="blueLi">7</li>
            <li>8</li>
            <li>9</li>
            <li>10</li>
            <li>1</li>
            <li class="blueLi">2</li>
            <li>3</li>
            <li>4</li>
            <li>5</li>
            <li>6</li>
            <li class="blueLi">7</li>
            <li>8</li>
            <li>9</li>
            <li>10</li>
            <li>1</li>
            <li class="blueLi">2</li>
            <li>3</li>
            <li>4</li>
            <li>5</li>
            <li>6</li>
            <li class="blueLi">7</li>
            <li>8</li>
            <li>9</li>
            <li>10</li> -->
          </ul>
        </div>
        <el-button slot="reference" type="primary" size="medium ">答题卡</el-button>
      </el-popover>
    </div>
    <div class="weTestCourse">
      <el-button type="success" @click="backCourse()" size="medium">返回课件</el-button>
    </div>
    <div class="weTestCon">
      <div class="weTestConHeader">
        <span v-if="CI_ItemType==='X型'">【题型】{{CI_ItemType}}多选题：</span>
        <span v-else>【题型】{{CI_ItemType}}单选题：</span>
      </div>
      <!--
      <h3>机动车驾驶人违法驾驶造成重大交通事故构成犯罪的，依法追究什么责任？</h3>
      <div class="weTestLi">
       <ul>
          <li>
            <el-radio v-model="radio" label="1">
              <span>A：</span>备选项
            </el-radio>
          </li>
          <li>
            <el-radio v-model="radio" label="2">
              <span>B：</span>备选项
            </el-radio>
          </li>
          <li>
            <el-radio v-model="radio" label="3">
              <span>C：</span>备选项
            </el-radio>
          </li>
          <li>
            <el-radio v-model="radio" label="4">
              <span>D：</span>备选项
            </el-radio>
          </li>
        </ul>
        <el-form-item :label="Info_Item.CI_Caption" prop="isGive">
          <el-radio-group  v-model="currentLine.isGive">
            <el-radio :label="item.id" :key="item.id" v-for="item in isGive" >{{item.name}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="weTestError">
          <el-alert
            :title="'恭喜您回答正确 '+radio"
            type="success"
            show-icon
            :closable="false"
            v-if="successVisible"
          ></el-alert>
          <el-alert
            :title="'你答错了！  正确答案是: '+radio"
            type="error"
            show-icon
            :closable="false"
            v-if="errorVisible"
          ></el-alert>
        </div>
      </div>-->
      <template v-if="showA1A2XItem">
        <p>
          <span class="lable-title">{{itemSortNUM}}{{CI_Caption}}</span>
        </p>
        <hr/>
          <el-radio-group  v-model="selectedOption" v-if="showSingle">
            <el-radio @click.native.prevent="onRadioChange(item.O_ID)" :label="item.O_ID" :key="item.O_ID" v-for="item in Info_ItemOptions" class="elradio-item" ><span>{{formateStringToNum(item.CO_OptionSort)}}、</span>{{item.CO_Content}}</el-radio>
          </el-radio-group>
          <el-checkbox-group v-model="CheckItems" v-if="showMutil">
            <el-checkbox  @click.native.prevent="chooseItem(item.O_ID)" :label="item.O_ID" :key="item.O_ID" v-for="item in Info_ItemOptions" class="elradio-item" ><span>{{formateStringToNum(item.CO_OptionSort)}}、</span>{{item.CO_Content}}</el-checkbox>
          </el-checkbox-group>
      </template>
      <template v-if="showA3A4Item">
          <p>
            <span class="lable-title">{{itemRange}} {{CI_Caption}}</span>
          </p>
          <div class="div-empty"></div>
          <div class="div-childItem" v-for="(item,index) in childItems" :key="item.Info_Item.CI_ID">
            <p>
            <span class="lable-title">{{(A3A4ChidItemIndex+index)}}、{{item.Info_Item.CI_Caption}}</span>
            </p>
            <hr/>
            <!-- <el-radio-group  v-model="radioList[index]" v-if="showSingle">
              <el-radio @click.native.prevent="onRadioChange($event)" :label="childitem.O_ID" :id="1" :key="childitem.O_ID" v-for="childitem in item.Info_ItemOption" class="elradio-item" ><span>{{formateStringToNum(childitem.CO_OptionSort)}}、</span>{{childitem.CO_Content}}</el-radio>
            </el-radio-group> -->
            <div :key="childitem.O_ID" v-for="childitem in item.Info_ItemOption">
                <input type="radio" class="el-radio__input" :id="childitem.O_ID" :name="item.Info_Item.CI_ID" @click="onRadioA3A4Change(childitem.O_ID,item.Info_Item.CI_ID)"/>
                <label>{{formateStringToNum(childitem.CO_OptionSort)}}、{{childitem.CO_Content}}</label>
                <!-- <el-radio :id="childitem.O_ID" :label="childitem.O_ID" v-model="CheckItems" :name="item.Info_Item.CI_ID" @click.native.prevent="chooseItem(childitem.O_ID)">{{formateStringToNum(childitem.CO_OptionSort)}}、{{childitem.CO_Content}}</el-radio> -->
            </div>
            <div class="div-empty"></div>
          </div>
      </template>
      <template v-if="showB1Item">
        <p><span class="lable-title">{{itemRange}} {{CI_Caption}}</span></p>
        <hr/>
        <div class="div-option" v-for="item in Info_ItemOptions" :key="item.O_ID">
          <p><span>{{formateStringToNum(item.CO_OptionSort)}}、{{item.CO_Content}}</span></p>
        </div>
        <hr/>
        <div class="div-empty"></div>
        <div class="div-childItemB1"  v-for="(childItem,index) in childItems" :key="childItem.Info_Item.CI_ID">
          <p><span class="lable-title">{{(A3A4ChidItemIndex+index)}}、{{childItem.Info_Item.CI_Caption}}</span></p>
            <!-- <el-radio-group v-model="selectedOption" :v-model="childItem.Info_Item">
                  <el-radio  @click.native.prevent="onRadioChange(childItem.Info_Item.CI_ID+dx)" :name="childItem.Info_Item.CI_ID" :label="childItem.Info_Item.CI_ID+dx" :key="dx" v-for="(item,dx) in Info_ItemOptions" class="elradio-childItemOption" ><span>{{formateStringToNum(item.CO_OptionSort)}}</span></el-radio>
            </el-radio-group>          -->
           <div v-for="(item,dx) in Info_ItemOptions" :Key="dx">
            <input type="radio" :id="childItem.Info_Item.CI_ID" :name="childItem.Info_Item.CI_ID" @click="onRadioA3A4Change(item.O_ID,childItem.Info_Item.CI_ID)"/>
            <label>{{formateStringToNum(item.CO_OptionSort)}}</label>
           </div>
        </div>
      </template>
      <div class="weTestInput">
        <el-button type="primary" size="small" icon="el-icon-arrow-left" @click="prevCourseItem()">上一题</el-button>
        <el-button type="primary" size="small" @click="nextCourseItem()">下一题<i class="el-icon-arrow-right el-icon--right"></i></el-button>
        <!-- <el-button type="info" plain size="small" @click="dialogTableVisible = true">答案解析</el-button> -->
      </div>
      <!-- <el-dialog title="本题解析" :visible.sync="dialogTableVisible">
        <p>《道路交通安全法》第一百零一条：造成交通事故后逃逸的，由公安机关交通管理部门吊销机动车驾驶证，且终生不得重新取得机动车驾驶证。</p>
      </el-dialog> -->
    </div>
  </div>
</template>

<script>
import api from "@/api/index.js";
import { readlink } from "fs";
import { constants } from 'zlib';
export default {
  created() {
      this.courseId=this.$route.params.courseId;
      this.postCourseItemIDS();
      console.log(333333);
  },
  data() {
    return {
      radio:{
        radio01:'3',
        radio02:'3',
        radio03:'3',
        radio04:'3',
      },
      courswareNum: "",
      itemSortNUM:"",
      currentCoureItemIds: "",
      Info_Item:"",
      Info_ItemOptions:"",
      CI_ItemType:"",
      CI_Caption:"",
      itemRange:"",
      child_CI_Caption:"",
      childItems:[],
      arrayIds: [],
      CheckItems:[],
      radioList:[],
      selectIndex:-1,
      A3A4ChidItemIndex:1,
      radio: "A",
      dialogTableVisible: false,
      dialogFormVisible: false,
      successVisible: false,
      errorVisible: false,
      courseId:'',
      selectedOption:'',
      showSingle:true,
      showMutil:false,
      showA1A2XItem:true,
      showA3A4Item:false,
      showB1Item:false,
      // formLabelWidth: '120px'
    };
  },
  methods: {
    postCourseItemIDS() {
      //这个是测试id，实际应从我的课程功能块中获取
      //this.courseId = 'FF1C60C4-F098-4311-95CF-EC2B774F673B';  //A1A2型题
      //this.courseId='29B1FE69-4FCB-4C2B-AEDF-E339E031E0EC';  //X型题
      //this.courseId='CB2EA911-5EFC-4A27-ABC7-E1EFBC8A82C1';  //A3A4题
      //this.courseId='cf409a6c-491c-4811-b48a-591d83d774bc';    //B1题
      //console.log(111111);
      //访问刷题api接口，等到该课程包下的所有题目的ids(包含子题目ID)
      api.postCourseBagItemIDAllListGet(this.courseId).then(res => {
        // console.log(22222);
        // console.log(res.data.rows);
        if(res.data.rows==null) return;
        //将请求的数据转换成数组，之所以字符串截取，是因为数据末尾有个逗号
        this.arrayIds=res.data.rows.substring(0,res.data.rows.length-1).split(',');
        this.courswareNum = this.arrayIds.length;
        this.currentCoureItemIds = this.arrayIds[0];
        if(this.currentCoureItemIds==undefined) return false;
        
        this.GetSelectMockExamItem(this.currentCoureItemIds);
      });
    },
    prevCourseItem() {    
      //获取当前题目id的索引
      var valindex = this.indexOf(this.arrayIds, this.currentCoureItemIds);
      if(this.CI_ItemType=="A3型"||this.CI_ItemType=="A4型"||this.CI_ItemType=="B1型")
      {
         valindex = valindex + this.childItems.length; 
         this.A3A4ChidItemIndex=this.A3A4ChidItemIndex-this.childItems.length;    
      }else
      {
        //索引累减，当前事件的方法是上一题，因此要递减才能获取上一题的信息
        valindex--;
      }

      //索引最小值为0，小于0就直接返回
      if (valindex < 0) return;
      //给当前ID赋新值
      this.currentCoureItemIds = this.arrayIds[valindex];
      //当前ID赋新值后，获取新的当前题目信息
      this.GetSelectMockExamItem(this.currentCoureItemIds);
    },
    nextCourseItem() {
      console.log("this.selectedOption:"+this.CheckItems);
      //获取选中的答案
      var selectedCIID="";
      switch(this.CI_ItemType)
      {
        case "A1型":
        case "A2型": 
        selectedCIID=this.selectedOption;
        break;
        case "X型":
        selectedCIID=this.CheckItems.join(',');    
          break;
      }

      if(this.CI_ItemType=="A3型"||this.CI_ItemType=="A4型"||this.CI_ItemType=="B1型")
      {
          for(var i=0;i<this.radioList.length;i++)
          {
              var array=this.radioList[i].split(',');
              //提交答案
              api.postAddTCustomerAnswer({
                CourseScoreID:"",
                CourseBagID:this.courseId,
                ItemID:array[1],
                CustomerAnswer:array[0]
              });
              console.log(i);
          }
      }
      else
      {
          //提交答案
          api.postAddTCustomerAnswer({
            CourseScoreID:'',
            CourseBagID:this.courseId,
            ItemID:this.currentCoureItemIds,
            CustomerAnswer:selectedCIID
          }).then(res=>{
            console.log(res);
          })
      }

      //获取当前题目id的索引
      var valindex = this.indexOf(this.arrayIds, this.currentCoureItemIds);
      if(this.CI_ItemType=="A3型"||this.CI_ItemType=="A4型"||this.CI_ItemType=="B1型")
      {
         valindex = valindex + this.childItems.length; 
         this.A3A4ChidItemIndex=this.A3A4ChidItemIndex+this.childItems.length;    
      }else
      {
        //索引累加，当前事件的方法是下一题，因此要递加才能获取下一题的信息
        valindex++;
      }

      //索引最大值为数组的长度，大于或等于数组的长度就直接返回
      if (valindex >= this.arrayIds.length) return;
      //给当前ID赋新值
      this.currentCoureItemIds = this.arrayIds[valindex];
      //当前ID赋新值后，获取新的当前题目信息
      this.GetSelectMockExamItem(this.currentCoureItemIds);
    },
    indexOf(arr, str) {
      if (arr && arr.indexOf) {
        return arr.indexOf(str);
      }

      var len = arr.length;
      for (var i = 0; i < len; i++) {
        if (arr[i] == str) {
          return i;
        }
      }
      return -1;
    },
    backCourse() {
      this.$router.push({
        name: "userSchool"
      });
    },
    clickItem(obj)
    {
      this.selectIndex=obj-1;
      this.currentCoureItemIds=this.arrayIds[this.selectIndex];
      this.GetSelectMockExamItem(this.currentCoureItemIds);     
    },
    onRadioChange(e){
      console.log(e);     
      e === this.selectedOption ? this.selectedOption = '' : this.selectedOption = e;
    },
    onRadioA3A4Change(X,Y){
      var item=X+","+Y;
      this.radioList.push(item);
    },
    formateStringToNum(obj){
     return String.fromCharCode(64 + parseInt(obj));
    },
    GetSelectMockExamItem(obj){
      if(obj.indexOf('_')!=-1) obj=obj.split('_')[0];
        api.postGetSelectMockExamItemSingle(obj).then(res=>{
          console.log("CI_ID:"+obj);
          console.log(res.data.rows);
          //return;
          this.CI_ItemType=res.data.rows.CI_ItemType;
          this.CI_Caption=res.data.rows.Info_Item.CI_Caption;
          this.Info_ItemOptions=res.data.rows.Info_ItemOption;

          switch(this.CI_ItemType)
          {
            case "A1型":
            case "A2型": 
             this.showA1A2XItem=true;
             this.showA3A4Item=false;
             this.showB1Item=false;

             this.showSingle=true;
             this.showMutil=false;
             
             break; 
            case "A3型":
            case "A4型":  
             this.showA3A4Item=true;
             this.showA1A2XItem=false;
             this.showB1Item=false;
             this.childItems=res.data.rows.ItemChild;
             break; 
            case "B1型":
             this.showB1Item=true;
             this.showA3A4Item=false;
             this.showA1A2XItem=false;
             this.childItems=res.data.rows.ItemChild;
             break;
            case "X型":
               this.showA1A2XItem=true;
               this.showA3A4Item=false;
               this.showB1Item=false;

               this.showSingle=false;
               this.showMutil=true;
              break;
          }

          var valindex = this.indexOf(this.arrayIds, this.currentCoureItemIds);
          this.itemSortNUM=(valindex+1)+"、";
          
          var childItemCount=res.data.rows.CI_ChildLength;
          if(childItemCount>1)
          {
            var startNUM=(valindex+1);
            var endNUM=startNUM+(childItemCount-1);
            this.itemRange=startNUM+"~"+endNUM;
          }
        });
    },
    chooseItem(id){
      if(this.CheckItems.indexOf(id)==-1){
        this.CheckItems.push(id);
      }else{
        this.CheckItems.splice(this.CheckItems.indexOf(id), 1); 
      }
    },
  }
};
</script>

<style>
/* .weTestConHeader{height: 30px;}
.weTestInput{text-align: right;margin-right: 15px;}
.lable-title{font-size: 16px;font-family: Arial, Helvetica, sans-serif;}
.div-empty{height: 15px;}
.div-childItem{float: left; width: 100%;}
.div-childItem div{display: inline-block;width: 6%;height: 28px;line-height: 28px; padding-left: 15px;margin-top: 5px;}
.div-childItem div label{margin-left: 2px;font-size: 16px;}
.div-option{float: left;width:100%;height: 28px;line-height: 28px;padding-left: 15px;margin-top: 5px;font-size: 14px;font-family: Arial, Helvetica, sans-serif;}
.elradio-item{width: 100%;height: 28px;line-height: 28px; padding-left: 15px;margin-top: 10px;}
.elradio-childItemOption{width: 8%;float: left;height: 28px;line-height: 28px; padding-left: 15px;margin-top: 10px;} */

.weTestConHeader{height: 30px;}
.weTestInput{text-align: right;margin-right: 15px;}
.lable-title{font-size: 16px;font-family: Arial, Helvetica, sans-serif;}
.elradio-item{width: 100%;height: 28px;line-height: 28px; padding-left: 15px;margin-top: 10px;}
.div-empty{height: 15px;}
.div-childItem{float: left; width: 100%;}
.div-childItemB1{float: left; width: 100%;}
.div-option{float: left;width:100%;height: 28px;line-height: 28px; padding-left: 15px;margin-top: 5px;font-size: 16PX;}
.div-childItemB1 div{display: inline-block;width: 6%;height: 28px;line-height: 28px; padding-left: 15px;margin-top: 2px;margin-bottom: 5PX;}
.div-childItemB1 div label{margin-left: 2px;font-size: 16px;}
.div-childItem div{height: 28px;line-height: 28px; padding-left: 15px;margin-top: 5px;}
.div-childItem div label{margin-left: 2px;font-size: 16px;}
</style>
