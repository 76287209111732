<template>
  <div class>
    <div class="topicBanner"></div>
    <div class="topicCon">
      <div class="topicDetail">
        <div class="jp_news_view_box">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/dayTopic' }">每周一题列表</el-breadcrumb-item>
                <el-breadcrumb-item>题目详情</el-breadcrumb-item>
                <!-- <el-breadcrumb-item>活动详情</el-breadcrumb-item> -->
            </el-breadcrumb>
          <div class="jp_title_six">
            <h2 style="float: none; text-align: center;">{{topicData.MQ_Title}}</h2>
          </div>
          <div class="jp_title_info_two">
            <span>时间：<i>{{topicData.DisplayDate}}</i>
            </span>
            <span>类型：<i>{{topicData.T_TypeName}}</i>
            </span>
          </div>
          <div class="jp_news_view_info" v-html="topicData.MQ_MondayQuestions">
              {{topicData.MQ_MondayQuestions}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/index.js";
export default {
    created(){
        var topicId = this.$route.params.id;
        console.log(topicId);
        api.getMondayQuestionsSingle({
            params:{
                code:topicId
            }
        })
            .then(res=>{
                console.log("每日一题的详情:",res)
                this.topicData=res.data.rows;
            }).catch(error=>{
                console.log(error)
            })
    },
    data(){
        return{
            topicData:{},
        }
    }
};
</script>

<style>
</style>