<template>
<div class="weOrder" v-loading="loading">
    <el-tabs v-model="activeName" @tab-click="handleClick" class="clearfix">
        <el-tab-pane label="全部订单" name="first" >
            <div class="orderDetail" v-if="tableData == ''?false:true">
                 <el-table
                :data="tableData"
                style="width: 100%">
                <el-table-column
                    prop="O_ORDERNAME"
                    label="课件名称"
                    width="220">
                </el-table-column>
                <el-table-column
                    prop="O_ORDERNO"
                    label="订单号"
                    width="180">
                </el-table-column>
                <el-table-column
                    prop="O_ORDERTIME"
                    label="下单时间">
                    <template slot-scope="scope">
                      {{scope.row.O_ORDERTIME.split("T")[0]+' '+scope.row.O_ORDERTIME.split("T")[1].substring(0,5)}}
                        <!-- <span :class="scope.row.O_SHOULDPAY==0 ? 'tableblue':'tablered'">{{scope.row.O_SHOULDPAY==0 ? '免费':scope.row.O_SHOULDPAY}}</span> -->
                    </template>
                </el-table-column>
                <el-table-column
                    prop="O_SHOULDPAY"
                    width="100"
                    label="金额">
                    <template slot-scope="scope">
                        <span :class="scope.row.O_SHOULDPAY==0 ? 'tablegreed':''">{{scope.row.O_SHOULDPAY==0 ? '免费':scope.row.O_SHOULDPAY}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="状态"  width="100">
                     <template slot-scope="scope">
                        <span :class="scope.row.O_PayStatus==0  ? 'tablered':'tableblue'">{{scope.row.O_PayStatus==0 ? '未激活':'已激活'}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="190">
                    <template slot-scope="scope">
                        <div v-if="scope.row.O_PayStatus==0 && scope.row.O_SHOULDPAY!=0">
                              <el-button
                              size="mini"
                              type="danger"
                              @click="goPay(scope.$index, scope.row)">去激活</el-button>
                              <el-button
                              size="mini"
                              @click="delOrder(scope.$index, scope.row)">删除</el-button>
                        </div>
                        <div v-else>
<!--                            <el-button
                            size="mini"
                            type="primary"
                            @click="learnCourse(scope.$index, scope.row)">去学习</el-button>-->
                            <el-button
                              size="mini"
                              @click="delOrder(scope.$index, scope.row)">删除</el-button>
                            
                            <!-- <el-button
                            size="mini"
                            @click="delOrder(scope.$index, scope.row)">删除</el-button> -->
                        </div>
                        
                    </template>
                </el-table-column>
                </el-table>
            </div>
            <div class="wuImg" v-else><img src="@/assets/images/bcym01.png"></div>
            <div class="coursePage" v-if="pageRows > 5 ? true:false">
                <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="pageRows"
                    :page-size="pageSize"
                    :current-page="currentPage"
                    @current-change="handleCurrentChange">
                </el-pagination>
            </div>
        </el-tab-pane>
        <el-tab-pane label="已完成" name="second">
            <div class="orderDetail" v-if="successTableData == ''?false:true">
                 <el-table
                :data="successTableData"
                style="width: 100%">
                <el-table-column
                    prop="O_ORDERNAME"
                    label="课件名称"
                    width="220">
                </el-table-column>
                <el-table-column
                    prop="O_ORDERNO"
                    label="订单号"
                    width="180">
                </el-table-column>
                <el-table-column
                    prop="O_ORDERTIME"
                    label="下单时间">
                    <template slot-scope="scope">
                      {{scope.row.O_ORDERTIME.split("T")[0]+' '+scope.row.O_ORDERTIME.split("T")[1].substring(0,5)}}
                        <!-- <span :class="scope.row.O_SHOULDPAY==0 ? 'tableblue':'tablered'">{{scope.row.O_SHOULDPAY==0 ? '免费':scope.row.O_SHOULDPAY}}</span> -->
                    </template>
                </el-table-column>
                <el-table-column
                    prop="O_SHOULDPAY"
                    width="100"
                    label="金额">
                    <template slot-scope="scope">
                        <span :class="scope.row.O_SHOULDPAY==0 ? 'tablegreed':''">{{scope.row.O_SHOULDPAY==0 ? '免费':scope.row.O_SHOULDPAY}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="状态"  width="100">
                     <template slot-scope="scope">
                        <span :class="scope.row.O_PayStatus==0  ? 'tablered':'tableblue'">{{scope.row.O_PayStatus==0 ? '未激活':'已激活'}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="190">
                    <template slot-scope="scope">
                        <div v-if="scope.row.O_PayStatus==0 && scope.row.O_SHOULDPAY!=0">
                              <el-button
                              size="mini"
                              type="danger"
                              @click="goPay(scope.$index, scope.row)">去激活</el-button>
                              <el-button
                              size="mini"
                              @click="delOrder(scope.$index, scope.row)">删除</el-button>
                        </div>
                        <div v-else>
                            <el-button
                            size="mini"
                            type="primary"
                            @click="learnCourse(scope.$index, scope.row)">去学习</el-button>
                            <el-button
                            size="mini"
                            @click="delOrder(scope.$index, scope.row)">删除</el-button>
                        </div>
                        
                    </template>
                </el-table-column>
                </el-table>
            </div>
            <div class="wuImg" v-else><img src="@/assets/images/bcym01.png"></div>
        </el-tab-pane>
        <el-tab-pane label="未完成" name="third">
              <div class="orderDetail" v-if="NotableData == ''?false:true">
                 <el-table
                :data="NotableData"
                style="width: 100%">
                <el-table-column
                    prop="O_ORDERNAME"
                    label="课件名称"
                    width="220">
                </el-table-column>
                <el-table-column
                    prop="O_ORDERNO"
                    label="订单号"
                    width="180">
                </el-table-column>
                <el-table-column
                    prop="O_ORDERTIME"
                    label="下单时间">
                    <template slot-scope="scope">
                      {{scope.row.O_ORDERTIME.split("T")[0]+' '+scope.row.O_ORDERTIME.split("T")[1].substring(0,5)}}
                        <!-- <span :class="scope.row.O_SHOULDPAY==0 ? 'tableblue':'tablered'">{{scope.row.O_SHOULDPAY==0 ? '免费':scope.row.O_SHOULDPAY}}</span> -->
                    </template>
                </el-table-column>
                <el-table-column
                    prop="O_SHOULDPAY"
                    width="100"
                    label="金额">
                    <template slot-scope="scope">
                        <span :class="scope.row.O_SHOULDPAY==0 ? 'tablegreed':''">{{scope.row.O_SHOULDPAY==0 ? '免费':scope.row.O_SHOULDPAY}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="状态"  width="100">
                     <template slot-scope="scope">
                        <span :class="scope.row.O_PayStatus==0  ? 'tablered':'tableblue'">{{scope.row.O_PayStatus==0 ? '未激活':'已激活'}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="190">
                    <template slot-scope="scope">
                        <div v-if="scope.row.O_PayStatus==0 && scope.row.O_SHOULDPAY!=0">
                              <el-button
                              size="mini"
                              type="danger"
                              @click="goPay(scope.$index, scope.row)">去激活</el-button>
                              <el-button
                              size="mini"
                              @click="delOrder(scope.$index, scope.row)">删除</el-button>
                        </div>
                        <div v-else>
                            <el-button
                            size="mini"
                            type="primary"
                            @click="learnCourse(scope.$index, scope.row)">去学习</el-button>
                            <!-- <el-button
                            size="mini"
                            @click="delOrder(scope.$index, scope.row)">删除</el-button> -->
                        </div>
                        
                    </template>
                </el-table-column>
                </el-table>
            </div>
            <div class="wuImg" v-else><img src="@/assets/images/bcym01.png"></div>
        </el-tab-pane>
    </el-tabs>
</div>

</template>

<script>
import api from "@/api/index.js";
export default{
  created(){
    console.log(this.activeName,this.$route.params);
    this.activeName=this.$route.params.activeName || 'first';
    console.log(this.activeName);
    this.getOrderlist();
    this.getOrderlistAll();
  },
data() {
      return { 
        activeName: 'first',
        loading: true,
        NotableData:[],
        successTableData:[],
        tableData: [],
        tableDataAll: [],
        currentPage:1,
        pageRows:null,
        pageSize:5,
        params:{
          status: ''
        }
      };
    },
    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
        if(tab.name == "second"){
          this.successTableData=this.tableDataAll.filter((item,index)=>{
            return item.O_PayStatus == 1 || item.O_SHOULDPAY==0;
          })
          console.log("已完成的订单:",this.successTableData)
        }else{
          // this.successTableData='';
        }
        if(tab.name == "third"){
          this.NotableData=this.tableDataAll.filter((item,index)=>{
            return item.O_PayStatus ==0 && item.O_SHOULDPAY!=0;
          })
          console.log("未完成的订单1:",this.NotableData)
        }
      },
      // 去学习
      learnCourse(index, row) {
        console.log(index, row);
        this.$router.push({
          name: 'userCourselearn',
          query: {
            OrderID:row.O_ID
          }
        })
      },
      // 去付款
      goPay(index, row) {
        console.log(index, row);
        this.$router.push({
          name: 'pay',
          query: {
            active: 1,
            orderCode:row.O_ORDERNO
          }
        })
      },
      // 获取全部订单列表
      getOrderlistAll(){
        api.postAllOrderListTime(this.params)
        .then(res=>{
            console.log('所有订单列表:',res);
            this.tableDataAll = res.data.rows;
            // this.loading = false;
          })
        .then(res=>{
          if(this.activeName=='third'){
            this.NotableData=this.tableDataAll.filter((item,index)=>{
                  return item.O_PayStatus ==0 && item.O_SHOULDPAY!=0;
                })
                console.log("未完成的订单:",this.NotableData)
          }
        })
        .catch(error=>{
          console.log(error);
        })
      },
      // 获取订单列表
      getOrderlist(){
        api.postOrderListTime({page:this.currentPage,rows:this.pageSize,status:this.params.status
        })
        .then(res=>{
            console.log('订单列表:',res);
            this.tableData = res.data.rows;
            this.pageRows = res.data.total;
            this.loading = false;
          })
        .catch(error=>{
          console.log(error);
        })
      },
      // 删除订单
      delOrder(index, row){
        api.postDelOrder(row.O_ORDERNO)
            .then(res=>{
              console.log('删除订单：',res)
              if(res.data.Status==1){
                this.$message({
                  message: '订单删除成功',
                  type: 'success'
                });
                this.getOrderlist()
                this.getOrderlistAll();
              }
            }).catch(err=>{
              console.log(err);
            })
      },
      // 分页
      handleCurrentChange(val){
            this.currentPage=val;
            this.getOrderlist();
            if(document.body.scrollTop){
                document.body.scrollTop = 0
            }else {
                document.documentElement.scrollTop = 0
            }
        },
    }
}

</script>

<style>
</style>