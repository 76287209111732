<template>
<div class="messageCen">
    <el-tabs v-model="activeName" @tab-click="handleClick" class="clearfix">
        <el-tab-pane label="全部通知" name="first" >
            <div class="noMessage" v-if="isMessage">
                <img src="@/assets/images/null-page-draw.png">
                <p>您暂时还没有消息！</p>
            </div>
            <div class="messageCon" v-else>
                <div class="messageList">
                    <div class="messageTitle">
                        <strong>通知公告</strong><span>2019-07-07 12:30</span>
                    </div>
                    <div class="messageItem">
                        <p>有一个很精彩的视频《教任何人一个小时内学会做网站》</p>
                        <p>有一个很精彩的视频《教任何人一个小时内学会做网站》</p>
                    </div>
                </div>
                <div class="messageList">
                    <div class="messageTitle">
                        <strong>通知公告</strong><span>2019-07-07 22:30</span>
                    </div>
                    <div class="messageItem">
                        <p>有一个很精彩的视频《教任何人一个小时内学会做网站》</p>
                        <p>有一个很精彩的视频《教任何人一个小时内学会做网站》</p>
                    </div>
                </div>
                <div class="messageList">
                    <div class="messageTitle">
                        <strong>通知公告</strong><span>2019-07-07</span>
                    </div>
                    <div class="messageItem">
                        <p>有一个很精彩的视频《教任何人一个小时内学会做网站》</p>
                        <p>有一个很精彩的视频《教任何人一个小时内学会做网站》</p>
                    </div>
                </div>
            </div>
        </el-tab-pane>
        <el-tab-pane label="公告" name="second">
            <div class="noMessage" v-if="!isMessage">
                <img src="@/assets/images/null-page-draw.png">
                <p>您暂时还没有消息！</p>
            </div>
        </el-tab-pane>
        <!-- <el-tab-pane label="未学习" name="third">
            <div class="wuImg"><img src="@/assets/images/bcym01.png"></div>
        </el-tab-pane> -->
    </el-tabs>
</div>

</template>

<script>
export default{
data() {
      return {
        activeName: 'first',
        isMessage:false,
        }
},
methods:{
    handleClick(tab, event){
        console.log(tab, event);
    }
}
}

</script>

<style>
</style>