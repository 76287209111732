<template>
  <div class>
    <div class="topicBanner"></div>
    <div class="topicCon">
      <div class="topicCon_list">
        <div class="xny_news_list_box">
          <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>每周一题列表</el-breadcrumb-item>
            </el-breadcrumb>
          <ul id="dvList">
            <li v-for="(item,index) in topicListData" :key="index">
              <div class="xny_news_list_box_tup"></div>
              <div class="xny_news_list_box_time">
                <span class="xny_news_list_box_time_1">{{item.DisplayDate}}</span>
                <span class="xny_news_list_box_time_4">{{item.T_TypeName}}</span>
              </div>
              <div class="xny_news_list_box_li">
                <div class="xny_news_list_box_li_left">
                  <router-link :to="{name:'dayTopicDetail',params:{id: item.MQ_ID}}">
                    <img
                      src="../assets/images/topicList.jpg"
                      width="158px"
                      height="106px"
                    >
                  </router-link>
                </div>
                <div class="xny_news_list_box_li_right">
                  <div class="xny_news_list_box_li_title">
                    <router-link :to="{name:'dayTopicDetail',params:{id: item.MQ_ID}}">{{item.MQ_Title}}</router-link>
                  </div>
                  <div
                    class="xny_news_list_box_li_cont"
                  >{{item.MQ_Title}}</div>
                  <div class="xny_news_list_box_li_more">
                    <router-link :to="{name:'dayTopicDetail',params:{id: item.MQ_ID}}">查看详情</router-link>
                  </div>
                </div>
              </div>
            </li>
            
            <div class="clear"></div>
          </ul>
        </div>
        <div class="coursePage">
                <!-- <course-page :itemPageRows="pageRows"></course-page> -->
                <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="pageRows"
                    :page-size="pageSize"
                    :current-page="currentPage"
                    @current-change="handleCurrentChange">
                </el-pagination>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/index.js";
export default {
  created(){
    this.getDayTopicListInt();
  },
  data(){
    return{
      topicListData:null,
      currentPage:1,
      pageRows:null,
      pageSize:5,
    }
  },
  methods:{
    getDayTopicListInt(){
      api.getDayTopic({
          params: {
            T_TypeName:'',
            StartCreateDate:'',
            EndCreateDate:'',
            T_Type:'',
            pageIndex: this.currentPage,
            pageSize: this.pageSize,
          }
        })
        .then(res => {
          console.log("每日一题:",res.data);
          this.topicListData=res.data.rows;
          this.pageRows=res.data.total;
        }).catch(function (error) {
         console.log(error);
        });
    },
    // 返回顶部
    handleCurrentChange(val){
            this.currentPage=val;
            this.getDayTopicListInt();
            if(document.body.scrollTop){
                document.body.scrollTop = 0
            }else {
                document.documentElement.scrollTop = 0
            }
        },
    // 获得每日一题数据
    // gettopicListData(){
    //   api.getDayTopic({
    //       params: {
    //         pageIndex: 1,
    //         pageSize: 5
    //       }
    //     })
    //     .then(res => {
    //       console.log(res.data);
    //       this.topicListData=res.data.rows
    //     }).catch(function (error) {
    //      console.log(error);
    //     });
    // },
  }
};
</script>

<style>
</style>