<template>  
<div>
    <div class="clearfix">
        <div class="header-left">
            <div class="hederLeft clearfix">
               <div class="headerLogo"><router-link :to="{name:'index'}"><img src="@/assets/logo01.png"><h3>医师定期考核培训课程</h3></router-link></div>
            </div>
        </div>
        <div class="header-right">
            <div class="headerAccount">
                <div v-if="!this.$store.state.isLogin">
                    <div class="hederLogin" ><router-link :to="{name:'login'}">登录</router-link></div>
                    <div class="hederRegister"><router-link :to="{name:'register'}">注册</router-link></div>
                </div>
                <div v-else class="compLogin">
                    <span>欢迎您，<router-link :to="{name:'userCenter'}">{{this.$store.state.userName}}</router-link></span>|<strong @click="exitLogin">[ 退出 ]</strong>
                </div>    
           </div>
        </div>
    </div>
    <div class="servicePage">
            <div class="courseRemark">
                <h1>{{baTitle}}</h1>
              <div class="desc" v-html="baRemark">
              </div>
            </div>
            <div class="courseShowList">
                <course-item v-for="(item,index) in courseData" :key="index" :itemCourseData="item"></course-item>
            </div>
    </div>
</div>
</template>
<script>
import api from "@/api/index.js";
import courseItem from '@/components/course/courseItem.vue'
export default{
    created(){
        this.areaid=this.$route.params.id;
        //this.searveName=this.$route.query.name;
        console.log("地区ID:"+this.areaid);
        api.postGetBusinessAreaByCode(this.areaid).then(res=>{
           if(res.data.rows!=null){
               this.baTitle=res.data.rows.BA_Title;
               this.baRemark=res.data.rows.BA_Remark;
           }
        });
        this.intList();
    },
    components:{
        courseItem
    },
    data() {
      return {
        loading: true,
        areaid:'',
        paramsId:'',
        searveName:'',
        baTitle:'',
        baRemark:'',
        itemdata:[],
        courseData:[],
        currentPage:1,
        pageRows:null,
        pageSize:100,
      };
    },
    methods: {
      handleClick(tab, event) {
        // console.log(tab, event);
      },
      exitLogin(){
        api.loginOut()
            .then(res=>{
                console.log(res);
                if(res.data.Status==1){
                    this.$store.commit("setUserName",'')
                    localStorage.setItem('setCourseListUrl','')
                    this.$store.commit("changeLogin",false)
                    this.$store.commit('del_token')
                    this.$router.push({name:'index'});
                    this.$message({
                    message:'你已经成功退出登陆状态',
                    type: 'success',
                  });
                }else{
                    this.$message({
                    message:'退出失败',
                    type: 'success',
                  });
                }
            }).then(res=>{
                // this.$store.commit('del_token')
            })
        // this.$store.commit('del_token');
      },
      // 初始化加载课程列表
      intList(){
            api.getProjectData({
                CB_NAME: '',
                CB_KEYWORDS: '',
                CB_COURSETYPE: '',
                CB_TYPE: '',
                CB_PROJECTCODE: '',
                CB_AreaCode:this.areaid,
                CB_PROFESSIONCODE: '',
                page: this.currentPage,
                rows: this.pageSize
            }).then(res=>{
                // Vue.delete(this.courseData,this.courseData.length);
                this.courseData=res.data.rows;
                //this.pageRows=res.data.total;
                this.loading=false;
                console.log("课程列表:",res);
            }).then(res=>{

            })
            .catch(error=> {
                    console.log(error);
            })
        },
    }
}

</script>
<style>
.header-left{ float: left;}
.header-right{ float: right;}
.hederLeft{ height: 78px;}
.headerLogo{ float: left;height: 78px; line-height: 78px;margin-left: 30px;}
.headerLogo img{ margin-right: 40px;  vertical-align: middle;}
.headerLogo h3{float: left; line-height: 78px; font-size: 36px;color: gray;vertical-align: middle;}
.hederLogin,.hederRegister{ float: left; font-size: 14px; margin-top: 30px; margin-left: 10px;}
.hederLogin a{padding:5px 15px; background-color:#0e6eb8; color:#fff; border-radius: 5px; border:1px solid #0e6eb8}
.hederLogin a:hover{ color: #fff;}
.headerAccount{float: right;margin-right: 30px;}
.hederRegister a{padding:5px 15px; background-color:#fff; color:#333; border-radius: 5px;border:1px solid #ccc;}
.hederRegister a:hover{ background: #ecf5ff; color: #fff;}
.compLogin{ line-height: 78px; font-size: 14px;}
.compLogin span{}
.compLogin a{ color: #0e6eb8; margin-right: 5px;}
.compLogin strong{ font-weight: normal ;margin-left:5px; color:#999;}
.compLogin strong:hover{ color: #0e6eb8; cursor: pointer;}
.servicePage{float: left;width: 100%;}
.courseShowList{margin: -100px auto; margin-left: 24.2%;border: 0px solid #000;width: 60%; text-align: left;}
.courseRemark{margin: 0 auto;;margin-top: 20px;width: 950px;height: 260px;text-align: center; }
.courseRemark h1{text-align: center;}
.courseRemark p{font-size: 20px;margin-top: 20px;text-align: left;}
.courseRemark .desc{font-size: 18px}
.toolsAeaTile{ font-size: 24px; font-weight: normal; text-align: center; padding:-40px 0 40px 0;}
.toolsAeaTile a{ display: block; margin-top:20px;width: 140px;border-radius:5px;font-size: 18px; text-align: center; height: 42px; line-height: 42px; background-color: #f9a709; color: #fff;-moz-box-shadow:0px 0px 10px #999; -webkit-box-shadow:0px 0px 10px #999; box-shadow:0px 0px 10px #999;}
.toolsAeaTile a:hover{ background-color: #fcba3a; color: #fff;}
.weimg{ text-align: center;}
.toolsTile{font-size: 24px;
    text-align: center;
    color: #f9a709;
    display: block;
    margin: -62px 0 0 -70px;
    width: 140px;
    left: 50%;
    position: absolute;
    padding:0;
    border-radius: 5px;
    font-size: 18px;
    text-align: center;
    height: 42px;
    line-height: 42px;
    background-color: #f9a709;
    color: #fff;
    -webkit-box-shadow: 0px 0px 10px #999;
    box-shadow: 0px 0px 10px #999;}
.servicePageListArea01 a{ display: block; width: 100%;}
</style>