<template>
    <div class="aboutGray">
        <div class="width1200">
            <ul>
                <li>
                    <strong>2013</strong>
                    <span>公司成立</span>
                </li>
                <li>
                    <strong>100+</strong>
                    <span>合作伙伴</span>
                </li>
                <li>
                    <strong>200000+</strong>
                    <span>题库</span>
                </li>
            </ul>
            <p>医东网络科技发展（上海）有限公司是一家专业从事互联网医疗信息化服务的公司。公司坚持以技术为根本、以医学为先导，崇尚创新、关注用户，在互联网医疗信息化开发和应用上取得了备受肯定的成就。 我公司依托强大的研发团队、一流的技术开发实力、高素质的医学专业人才，推出自主的综合性医师服务平台“医+E”，其主要服务领域包含：医+培训、医+会议、医+人才及医+执业等医师服务项目。</p>
            <p>医东公司以高起点、高质量、高信誉立足于市场，经历不断地在医疗行业的摸索，陆续推出相关医学产品：如医东在线教育系统、医东智能化题库系统、医东电子化考试系统、医东医师定期考核信息登记管理系统、医东学协会信息化管理系统、医东电子化会议管理系统、医东资源管理系统、会员系统等。经过多年的成长与壮大,医东公司与各省市地方医师协会、医学会、非公立医疗机构协会达成了战略合作关系,就医疗技术合作项目开展了深入的合作。</p>
            <p>医东公司自创立以来，就立志成为一个专业的互联网医疗信息化服务企业，这是医东人的追求目标，也是医东公司的发展宗旨。公司建立了开放、规范、先进的经营管理体制，以及明确专注于互联网医疗信息化服务的发展战略。医东人的理想是“做最出色的医疗卫生信息化建设优秀服务提供商，提供更加强大的软件开发、维护实力及更具实用性、操作性、成熟性的现代化信息化产品。” 为此，我们全面提高自身的整体竞争能力，加速医东公司的国际化进程，不断发展壮大，创造更多的价值。医东公司将以更加成熟和稳健的步伐，担负起对用户和社会的责任，与用户共同成长，为人类的健康事业做出卓越的贡献。</p>
        </div>
        
    </div>

</template>

<script>
export default{

}

</script>

<style>
.aboutGray{ background: #f6f6f6;}
.width1200{
    width: 1200px; margin: 0px auto;
}
.width1200 ul{ text-align: center; margin-bottom: 20px;}
.width1200 ul li{ display: inline-block; padding:0 50px;}
.width1200 ul li span{ display: block;font-size: 18px; margin-top: 3px;}
.width1200 ul li strong{ font-size: 28px; font-weight: normal;}
.aboutGray p{ font-size: 16px; margin-top: 10px; text-indent: 2em; color: #777; line-height: 28px;}
</style>