<template>
<div class="hederLeft clearfix">
    <div class="headerLogo"><router-link :to="{name:'index'}"><img src="@/assets/logo01.png"></router-link></div>
    <ul class="logoNav clearfix">
        <li><router-link :to="{name:'index'}">首页</router-link></li>
        <!-- <li><router-link :to="{name:'service'}" @click="goto('index','box01')">项目服务</router-link></li> -->
        <li><a href="#box01" @click="goto('index','box01')">项目服务</a></li>
        <li><router-link :to="{name:'help'}">服务中心</router-link></li>
        <!-- <li><router-link :to="{name:'about'}">关于我们</router-link></li> -->
        <li><a href="http://www.doc-exam.com/contact_us.html" target="_blank">关于我们</a></li>
        <li class="codeOn"><a href="#">微信公众号</a><img src="@/assets/images/erweima.jpg"></li>
        <li><a href="https://other.yixiecloud.com/official/yd.html" target="_blank">下载医东APP</a></li>

    </ul>
</div>

</template>

<script>
export default{
    methods:{
        goto(page,selectId){
                var path = page;
                var selectId = selectId;
                sessionStorage.setItem("toId",selectId);
                this.$router.push({path:'/'+ path});
            }
    },
}

</script>

<style>
@import url(../../assets/css/base.css);
.hederLeft{ height: 78px;}
.headerLogo{ float: left;height: 78px; line-height: 78px;}
.headerLogo img{  margin-right: 40px;  vertical-align: middle;}
.logoNav{ float: left;}
.logoNav li{ float: left; font-size: 16px; margin-right: 25px; }
.logoNav li a{ display: block; line-height: 78px; position: relative;}
.logoNav li a:hover{ color:#0e6eb8;}
.logoNav li a.active{ color:#0e6eb8; }
.logoNav li a.active:after{ position:absolute; content: ''; width: 100%; height: 3px;background: #0e6eb8; bottom: 0px; left: 0; }
.codeOn{ position: relative;}
.codeOn:hover img{display: block;}
.codeOn img{ display: none; position:absolute; bottom:-199px; left: 50%; transform: translateX(-50%);}
</style>
