import Vue from 'vue'
import Vuex from 'vuex'
import cookie from 'vue-cookies'

Vue.use(Vuex)
Vue.use(cookie)
export default new Vuex.Store({
  state: {
    userNameID:Vue.prototype.$cookies.get('userNameID') ? Vue.prototype.$cookies.get('userNameID') : '',
    userName:Vue.prototype.$cookies.get('userName') ? Vue.prototype.$cookies.get('userName') : '',
    isLogin:JSON.parse(Vue.prototype.$cookies.get('isLogin')) || false,
    token:Vue.prototype.$cookies.get('yddl_token') ? Vue.prototype.$cookies.get('yddl_token') : '',
    ItemIDsAnswer:Vue.prototype.$cookies.get('ItemIDsAnswer'),
    courseListUrl:Vue.prototype.$cookies.get('courseListUrl') ? Vue.prototype.$cookies.get('courseListUrl') : '',
    serverUrl:'http://www.1pluse.com/',
  },
  mutations: {
    setCourseListUrl(state,val){
      state.courseListUrl=val;
      Vue.prototype.$cookies.set('courseListUrl',val)
    },
    setUserNameID(state,val){
      state.userNameID=val;
      Vue.prototype.$cookies.set('userNameID',val)
    },
    setUserName(state,val){
      state.userName=val;
      Vue.prototype.$cookies.set('userName',val)
    },
    changeLogin(state,val){
      state.isLogin=val;
      Vue.prototype.$cookies.set('isLogin',val)
    },
    set_token(state, val) {
      state.token = val;
      Vue.prototype.$cookies.set('yddl_token',val)
    },
    setItemIDsAnswer(state,val){
      state.ItemIDsAnswer=val;
      Vue.prototype.$cookies.set('ItemIDsAnswer',val);
    },
    del_token(state) {
      state.token = ''
      Vue.prototype.$cookies.remove('yddl_token')
    }
  },
  actions: {

  }
})
