<template>
  <div class="weTest">
    <div class="weTestTitle">
      随机练习题
      <span>[ 共计{{ courswareNum }}题 ]</span>
    </div>
    <div class="weTestCode">
      <el-popover placement="bottom" width="400" trigger="click">
        <div class="weTestCodeItem">
          <h3>
            <span></span>未作答&nbsp;&nbsp;&nbsp;
            <span class="blueLi"></span>已作答
            <span class="redLi"></span>答错
          </h3>
          <ul>
            <li v-for="(item, index) in ItemIDAns" :id="item.ItemID" :class="{ 'blueLi':item.IsAnswered,'redLi':item.IsAnswered&&item.Correct==false }" :key="index" @click="clickItem(index+1)">{{index+1}}</li>

          </ul>
        </div>
        <el-button slot="reference" type="primary" size="medium ">答题卡</el-button>
      </el-popover>
    </div>
    <div class="weTestCourse">
      <el-button type="warning" @click="backCourse()" size="medium">返回课件</el-button>
    </div>
    <div class="weTestAnswer">
      <el-button type="info" v-if="isShowAnwswer" @click="answerRemark()" size="medium">答案解析</el-button>
    </div>
    <div class="weTestCon">
      <div class="weTestConHeader">
        <span v-if="CI_ItemType==='X型'">【题型】{{CI_ItemType}}多选题：</span>
        <span v-else>【题型】{{CI_ItemType}}单选题：</span>
      </div>
      <template v-if="showA1A2XItem">
        <p :class="isMw?'p_mw':''">
          <span :class="isMw?'lable-title_mw':'lable-title'" >{{itemSortNUM}}</span><span :class="isMw?'lable-title_mw':'lable-title'" v-html="CI_Caption"></span>
        </p>
        <img v-if="isImg" :src="itemImage" alt="">
          <el-radio-group  v-model="selectedOption" v-if="showSingle" :class="{'radioList':(ItemIDAns[itemSortNUM02-1].Correct==false)}">
            <el-radio @click.native.prevent="onRadioChange(item.O_ID)"  v-for="(item) in Info_ItemOptions" :label="item.O_ID" :key="item.O_ID"  :class="isMw?'elradio-item_mw':'elradio-item'" ><span :class="isMw?'el-radio__label':''">{{formateStringToNum(item.CO_OptionSort)}}、</span><span :class="isMw?'mongolianLanguage':''"  v-html="item.CO_Content.length>64?(item.CO_Content.substring(0,64)+'<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'+item.CO_Content.substring(64,item.CO_Content.length)):item.CO_Content"></span></el-radio>
          </el-radio-group>
          <el-checkbox-group v-model="CheckItems" v-if="showMutil">
            <el-checkbox  @click.native.prevent="chooseItem(item.O_ID)" :label="item.O_ID" :key="item.O_ID" v-for="item in Info_ItemOptions" :class="isMw?'elradio-item_mw':'elradio-item'" ><span :class="isMw?'el-checkbox__label':''">{{formateStringToNum(item.CO_OptionSort)}}、</span><span :class="isMw?'mongolianLanguage':''"  v-html="item.CO_Content.length>64?(item.CO_Content.substring(0,64)+'<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'+item.CO_Content.substring(64,item.CO_Content.length)):item.CO_Content"></span></el-checkbox>
          </el-checkbox-group>
        <div style="clear:both"></div>
      </template>
      <template v-if="showA3A4Item">
          <p :class="isMw?'p_mw':''">
            <span :class="isMw?'lable-title_mw':'lable-title'">{{itemRange}}</span><span :class="isMw?'lable-title_mw':'lable-title'" v-html="CI_Caption"></span>
          </p>
        <img v-if="isImg" :src="itemImage" alt="">
          <div class="div-empty"></div>
          <div class="div-childItem" v-for="(item,index) in childItems" :key="item.Info_Item.CI_ID">
            <p :class="isMw?'p_mw':''">
            <span :class="isMw?'lable-title_mw':'lable-title'">{{(A3A4ChidItemIndex+index)}}、</span><span  :class="isMw?'lable-title_mw':'lable-title'" v-html="item.Info_Item.CI_Caption"></span>
            </p>
            <el-radio-group  v-model="checkedValue[index]" fill="red" :class="{'radioList':(ItemIDAns[A3A4ChidItemIndex+index-1].Correct==false)}">
              <el-radio   @change="onRadioA3A4Change(childitem.O_ID,item.Info_Item.CI_ID)" :label="childitem.O_ID" :key="childitem.O_ID" v-for="childitem in item.Info_ItemOption" :class="isMw?'elradio-item_mw':'elradio-item'" ><span>{{formateStringToNum(childitem.CO_OptionSort)}}、</span><span class="mongolianLanguage" v-html="childitem.CO_Content.length>64?(childitem.CO_Content.substring(0,64)+'<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'+childitem.CO_Content.substring(64,childitem.CO_Content.length)):childitem.CO_Content"></span></el-radio>
            </el-radio-group>
            <div style="clear:both"></div>
            <!-- <div :key="childitem.O_ID" v-for="childitem in item.Info_ItemOption">
                <input type="radio" class="el-radio__input" v-model="checkedValue['checkedValue'+index]" :id="childitem.O_ID" :name="item.Info_Item.CI_ID" @click="onRadioA3A4Change(childitem.O_ID,item.Info_Item.CI_ID)"/>
                <label>{{formateStringToNum(childitem.CO_OptionSort)}}、{{childitem.CO_Content}}</label>
            </div> -->
            <div class="div-empty"></div>
          </div>
      </template>
      <template v-if="showB1Item">
        <p><span :class="isMw?'lable-title_mw':'lable-title'">{{itemRange}} </span><span :class="isMw?'lable-title_mw':'lable-title'" v-html="CI_Caption"></span></p>
        <div class="div-option" v-for="item in Info_ItemOptions" :key="item.O_ID">
          <p><span>{{formateStringToNum(item.CO_OptionSort)}}、</span><span class="mongolianLanguage" v-html="item.CO_Content.length>64?(item.CO_Content.substring(0,64)+'<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'+item.CO_Content.substring(64,item.CO_Content.length)):item.CO_Content"></span></p>
        </div>
        <div class="div-empty"></div>
        <div class="div-childItemB1"  v-for="(childItem,index) in childItems" :key="childItem.Info_Item.CI_ID">
          <p><span :class="isMw?'lable-title_mw':'lable-title'">{{(A3A4ChidItemIndex+index)}}、</span><span  :class="isMw?'lable-title_mw':'lable-title'" v-html="childItem.Info_Item.CI_Caption"></span></p>
          <el-radio-group v-model="checkedValue[index]" :class="{'radioList':(ItemIDAns[A3A4ChidItemIndex+index-1].Correct==false)}">
                <el-radio  @change="onRadioA3A4Change(cItem.O_ID,childItem.Info_Item.CI_ID)" :label="cItem.O_ID" :key="cItem.O_ID" v-for="cItem in Info_ItemOptions" class="elradio-childItemOption" ><span>{{formateStringToNum(cItem.CO_OptionSort)}}</span></el-radio>
          </el-radio-group>
          <div style="clear:both"></div>
           <!-- <div v-for="(item,dx) in Info_ItemOptions" :Key="dx">
            <input type="radio" :id="item.O_ID" :name="childItem.Info_Item.CI_ID" @click="onRadioA3A4Change(item.O_ID,childItem.Info_Item.CI_ID)"/>
            <label>{{formateStringToNum(item.CO_OptionSort)}}</label>
           </div> -->
        </div>
      </template>


      <div class="weTestInput">
<!--        <el-button type="success" v-if="isShowAnwswer" @click="answerRemark()" size="medium">答案解析</el-button>-->
        <el-button type="primary" size="small" icon="el-icon-arrow-left" @click="prevCourseItem()" :disabled="prevdisabled">上一题</el-button>
        <el-button type="primary" size="small" @click="nextCourseItem()" :disabled="nextdisabled">下一题<i class="el-icon-arrow-right el-icon--right"></i></el-button>
        <!-- <el-button type="info" plain size="small" @click="dialogTableVisible = true">答案解析</el-button> -->
      </div>

      <div v-if="dialogTableVisible">
            <div style="font-size:20px;border-bottom:1px solid #eee;padding:0 0 5px 0">答案解析:</div>
          <p style="color: #ed0d0d; margin: 10px 0 5px 0;font-size: 14px;font-weight: bold;"><span>正确答案：</span> <span v-html="optAnswer"></span></p>
          <p style="color:#666; font-size:14px;line-height: 28px;">{{itemRemark}}</p>
        </div>
       <!-- <div class="weTestError" v-if="dialogTableVisible">
          <el-alert
            :title="'恭喜您回答正确 '+radio"
            type="success"
            show-icon
            :closable="false" v-if="this.backAnswer.correct">
          </el-alert>
          <el-alert
            :title="'你答错了！  正确答案是: '+radio"
            type="error"
            show-icon
            :closable="false" v-else>
          </el-alert>
        </div> -->
      <!-- <el-dialog title="本题解析" :visible.sync="dialogTableVisible">
        <p style="color:red;margin-bottom:10px;"><span>正确答案：</span> <span v-html="optAnswer"></span></p>
        <p>{{itemRemark}}</p>
      </el-dialog> -->
    </div>
  </div>
</template>

<script>
import api from "@/api/index.js";
import { readlink, truncate } from "fs";
import { constants } from 'zlib';
import { fips } from 'crypto';
import consts from "@/common/constants.js";
import {decodeUnicode} from "../../common/decode";
export default {
  created() {
      this.courseId=this.$route.query.courseId;
      this.postCourseItemIDS();
      document.addEventListener('copy', function(e) {
        e.preventDefault();
      });
      document.addEventListener('contextmenu', function(e) {
        e.preventDefault();
      });
  },
  data() {
    return {
      radio:{
        radio01:'3',
        radio02:'3',
        radio03:'3',
        radio04:'3',
      },
      courswareNum: "",
      itemSortNUM:"",
      itemSortNUM02:0,
      currentCoureItemIds: "",
      prevCourseItemIds:"",
      nextCourseItemIds:"",
      Info_Item:"",
      Info_ItemOptions:"",
      CI_ItemType:"",
      CI_Caption:"",
      CI_ItemTagsID:"",
      itemRange:"",
      optAnswer:"",
      itemRemark:"",
      childItems:[],
      arrayIds: [],
      CheckItems:[],
      SelectedItemIDS:[],
      radioList:[],
      ItemIDAns:[],
      checkedValue:[],
      selectIndex:-1,
      selectedNum:0,
      A3A4ChidItemIndex:1,
      //radio: "A",
      dialogTableVisible: false,
      dialogFormVisible: false,
      successVisible: false,
      errorVisible: false,
      courseId:'',
      selectedOption:'',
      showSingle:true,
      showMutil:false,
      showA1A2XItem:true,
      showA3A4Item:false,
      showB1Item:false,
      prevdisabled:false,
      nextdisabled:false,
      isShowAnwswer:true,
      selectedItemID:'',
      // formLabelWidth: '120px'
      selIndex:'',
      backAnswer:{},
      itemImage:'',
      isImg:false,
      isMw:false
    };
  },
  methods: {
    //根据课程包ID获取与课程包相关的所有题的ID和题对应的答案
    postCourseItemIDS() {
      //获取答题卡中需要绑定的数据
      api.postGetCourseBagItemIDAndAnswerList(this.courseId).then(res=>{
       //判断访问接口返回的数据是否为null，为null说明该课程包下无试题信息
        if(res.data.Status==2){
          alert(res.data.Msg);
        }
        if(res.data.rows==null) return;
        //获取返回的数据长度（是个数组对象），用于显示答题卡的题号
        this.courswareNum=res.data.rows.length;
        //将返回的答题卡数据赋值给this.ItemIDAns
        this.ItemIDAns=res.data.rows;
        console.log('第一次加载：',this.ItemIDAns)
        //将返回的答题卡的数据存入store中，便于其他地方调用
        this.$store.commit('setItemIDsAnswer',res.data.rows);

        //清空题目id集合中的数据
        this.arrayIds=[];
        //通过遍历答题卡数据集合，获取集合中的题目id，将其存入到题目id集合中
        for(var i=0;i<this.ItemIDAns.length;i++){
          this.arrayIds.push(this.ItemIDAns[i].ItemID);
        }

        //将数组中的第一个题目id赋值给当前题目id变量
        this.currentCoureItemIds=this.arrayIds[0];
        //判断当前题目id是否是有效值，不是就直接返回，不执行后续动作
        if(this.currentCoureItemIds==undefined) return false;

        //调用显示试题的方法
        this.GetSelectMockExamItem(this.currentCoureItemIds);

        //默认加载的题项索引等于为0，上一题按钮禁用掉
        this.prevdisabled=true;

        //若默认加载的试题是已做的试题，就加载已做的答案
        this.loadItemAnswer();
      });
    },
    prevCourseItem() {

      this.nextdisabled=false;
      //保存答案
      // this.saveItemAnswer();

      //获取当前题目id的索引
      var valindex = this.indexOf(this.arrayIds, this.prevCourseItemIds);
      var subCount=0;
      var pID=null;
      for(var i=0;i<this.ItemIDAns.length;i++)
      {
        if(this.ItemIDAns[i].ItemID==this.prevCourseItemIds)
        {
          pID=this.ItemIDAns[i].PItemID;
        }
      }
      if(pID!=null){
        var ids=[];
          for(var i=0;i<this.ItemIDAns.length;i++)
          {
              if(this.ItemIDAns[i].PItemID==pID)
              {
                ids.push(this.ItemIDAns[i].ItemID);
                subCount++;
              }
          }

          var starindex=this.indexOf(this.arrayIds,ids[0]);
          this.A3A4ChidItemIndex=(starindex+1);
          valindex=starindex;
      }

      //索引等于为0，上一题按钮就禁用掉
      if (valindex === 0)
      {
        this.prevdisabled=true;
      }

      this.currentCoureItemIds=this.prevCourseItemIds;
      //当前ID赋新值后，获取新的当前题目信息
      this.GetSelectMockExamItem(this.currentCoureItemIds);
      //加载上一试题的答案
      this.loadItemAnswer();
      this.dialogTableVisible=false
      // if(this.selectedOption){
      //   this.answerRemark();
      // }else{
      //     this.dialogTableVisible=false
      //   }
      console.log('第二次加载：',this.ItemIDAns)
      this.backTop();
    },
    backTop() {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
    },
    nextCourseItem() {

      this.prevdisabled=false;
      this.dialogTableVisible=false
      //保存答案
      // this.saveItemAnswer();

      //获取当前题目id的索引
      var valindex = this.indexOf(this.arrayIds, this.nextCourseItemIds);

      var subCount=0;
      var pID=null;
      for(var i=0;i<this.ItemIDAns.length;i++)
      {
        if(this.ItemIDAns[i].ItemID==this.nextCourseItemIds)
        {
          pID=this.ItemIDAns[i].PItemID;
        }
      }

      if(pID!=null){
          var ids=[];
          for(var i=0;i<this.ItemIDAns.length;i++)
          {
              if(this.ItemIDAns[i].PItemID==pID)
              {
                ids.push(this.ItemIDAns[i].ItemID);
                subCount++;
              }
          }

          var starindex=this.indexOf(this.arrayIds,ids[0]);
          this.A3A4ChidItemIndex=(starindex+1);
          valindex=this.indexOf(this.arrayIds,ids[subCount-1]);
      }

      //索引最大值为数组的长度，大于或等于数组的长度就直接返回
      if (valindex === this.arrayIds.length-1)
      {
        this.nextdisabled=true;
      }
      this.currentCoureItemIds=this.nextCourseItemIds;
      //当前ID赋新值后，获取新的当前题目信息
      this.GetSelectMockExamItem(this.currentCoureItemIds);
      //加载答案
      this.loadItemAnswer();
      // if(this.selectedOption){
      //   this.answerRemark();
      // }else{
      //     this.dialogTableVisible=false
      //   }
      this.backTop();
      console.log('第三次加载：',this.ItemIDAns)

    },
    indexOf(arr, str) {
      if (arr && arr.indexOf) {
        return arr.indexOf(str);
      }

      var len = arr.length;
      for (var i = 0; i < len; i++) {
        if (arr[i] == str) {
          return i;
        }
      }
      return -1;
    },
    backCourse() {
      this.$router.push({
        name: "userCourselearn",
        query:{courseId:this.courseId},
      });
    },
    // 答题卡选题
    clickItem(obj)
    {
      this.selectIndex=obj-1;
      this.prevdisabled=false;
      this.nextdisabled=false;
      this.dialogTableVisible=false;

      this.currentCoureItemIds=this.arrayIds[this.selectIndex];
      var pID=null;
      var lastIndex=0;

      for(var i=0;i<this.ItemIDAns.length;i++)
      {
        if(this.ItemIDAns[i].ItemID==this.currentCoureItemIds)
        {
          pID=this.ItemIDAns[i].PItemID;
        }
      }
      if(pID!=null){
          var ids=[];
          for(var i=0;i<this.ItemIDAns.length;i++)
          {
              if(this.ItemIDAns[i].PItemID==pID)
              {
                ids.push(this.ItemIDAns[i].ItemID);
              }
          }

          lastIndex=this.indexOf(this.arrayIds,ids[ids.length-1]);
          var starindex=this.indexOf(this.arrayIds,ids[0]);
          this.selectIndex=starindex;
          this.A3A4ChidItemIndex=(starindex+1);

          if(lastIndex===this.arrayIds.length-1)
          {
              this.nextdisabled=true;
          }
      }

      //索引等于为0，上一题按钮就禁用掉
      if (this.selectIndex === 0)
      {
        this.prevdisabled=true;
      }

      //索引是最后一题，下一题按钮就禁用掉
      if(lastIndex===0&&this.selectIndex===this.arrayIds.length-1){
        this.nextdisabled=true;
      }

      this.GetSelectMockExamItem(this.currentCoureItemIds);
      this.loadItemAnswer();
    },
    //X型试题（多选题），id是已做完的答案题项id
    chooseItem(X,Y){
       if(this.CheckItems.indexOf(X)==-1){
          this.CheckItems.push(X);

          //更新store
          for(var i=0;i<this.ItemIDAns.length;i++){
            if(this.ItemIDAns[i].ItemID==this.currentCoureItemIds){
              this.ItemIDAns[i].IsAnswered=true;
              //this.ItemIDAns[i].AnswerIDList=[];
              this.ItemIDAns[i].AnswerIDList.push(X);
            }
          }
          this.$store.commit('setItemIDsAnswer',this.ItemIDAns);
        }else{
          this.CheckItems.splice(this.CheckItems.indexOf(X), 1);
        }
        console.log('多选题：',this.CheckItems)
        this.saveItemAnswer(X,Y);
        // this.answerRemark();
    },
    onRadioChange(X,Y){
      // if(this.selectedOption){
      //   console.log(this.selectedOption)
      //   this.$message({
      //     message: '你已作答了，不能选择了',
      //     type: 'warning'
      //   });
      //   return false;
      // }
      this.selectedOption=X
      // X === this.selectedOption ? this.selectedOption = '' : this.selectedOption = X;
      //将以做完的A1A2题的答案题项id更新store
      for(var i=0;i<this.ItemIDAns.length;i++){
        if(this.ItemIDAns[i].ItemID==this.currentCoureItemIds){
          this.ItemIDAns[i].IsAnswered=true;
          this.ItemIDAns[i].AnswerIDList=[];
          this.ItemIDAns[i].AnswerIDList.push(X);
        }
      }
      this.$store.commit('setItemIDsAnswer',this.ItemIDAns);
      this.saveItemAnswer(X,Y);
      // this.answerRemark();
      // console.log('第五次加载：',this.ItemIDAns)
    },
    onRadioA3A4Change(X,Y){
      //将已做完的A3A4试题的答案更新到store中
      for(var i=0;i<this.ItemIDAns.length;i++){
        if(this.ItemIDAns[i].ItemID==Y){
          this.ItemIDAns[i].IsAnswered=true;
          this.ItemIDAns[i].AnswerIDList=[];
          this.ItemIDAns[i].AnswerIDList.push(X);
          this.ItemIDAns[i].Correct=this.backAnswer.correct
        }
      }
      this.$store.commit('setItemIDsAnswer',this.ItemIDAns);
      this.saveItemAnswer(X,Y);
      // this.answerRemark();
    },
    //保存试题数据
    saveItemAnswer(X,Y){
      var parentItemID=null;
      //遍历this.checkedValue，拿到A3A4B1型试题的已做完的答案题项id
      for(var i=0;i<this.ItemIDAns.length;i++)
      {
        if(this.CI_ItemType=="A3型"||this.CI_ItemType=="A4型"||this.CI_ItemType=="B1型"){
            //判断答题卡数据集合中的题目id是否和当前题目id相同
            if(this.ItemIDAns[i].ItemID===this.currentCoureItemIds){
              //题目父级id
              parentItemID=this.ItemIDAns[i].PItemID;
            }
        }
      }
       console.log('ppp:',this.currentCoureItemIds)
      for(var i=0;i<this.ItemIDAns.length;i++)
      {
          if(parentItemID!=null){
            console.log('lllll:',X,Y)
              if(this.ItemIDAns[i].IsAnswered && this.ItemIDAns[i].ItemID==Y){
                  //提交答案
                  console.log('mmmmmmm:',{
                    CourseScoreID:"",
                    CourseBagID:this.courseId,
                    ItemID:this.ItemIDAns[i].ItemID,
                    CustomerAnswer:this.ItemIDAns[i].AnswerIDList[0]
                  })
                  api.AddTCustomer_AnswerAndViewAnalysis({
                    CourseScoreID:"",
                    CourseBagID:this.courseId,
                    ItemID:this.ItemIDAns[i].ItemID,
                    CustomerAnswer:X
                  }).then(res=>{
                      console.log(res);
                      this.backAnswer=res.data.rows;
                      for(var i=0;i<this.ItemIDAns.length;i++){
                        if(this.ItemIDAns[i].ItemID==Y){
                          this.ItemIDAns[i].Correct=this.backAnswer.correct
                        }
                      }
                      this.$store.commit('setItemIDsAnswer',this.ItemIDAns);
                      console.log('更新的信息：',this.ItemIDAns)
                  });
              }
          }
          else{
            console.log('333333',X,Y)
              if( this.ItemIDAns[i].IsAnswered && this.ItemIDAns[i].ItemID==this.currentCoureItemIds){
                 console.log('4444')
                // let aa = {
                //     CourseScoreID:"",
                //     CourseBagID:this.courseId,
                //     ItemID:this.ItemIDAns[i].ItemID,
                //     CustomerAnswer:this.ItemIDAns[i].AnswerIDList[0]
                //   }


                  // console.log(aa,this.ItemIDAns[i],bb.toString());
                  //提交答案
                  if(this.CI_ItemType=='X型'){
                    let bb=[];
                    this.ItemIDAns[i].AnswerIDList.forEach((item,index)=>{
                      if(bb.indexOf(item)==-1){
                        bb.push(item)
                      }
                    })
                    api.AddTCustomer_AnswerAndViewAnalysis({
                      CourseScoreID:"",
                      CourseBagID:this.courseId,
                      ItemID:this.ItemIDAns[i].ItemID,
                      CustomerAnswer:bb.toString()
                    }).then(res=>{
                        console.log('mmmmmm',res);
                        this.backAnswer=res.data.rows;
                        for(var i=0;i<this.ItemIDAns.length;i++){
                          if(this.ItemIDAns[i].ItemID==this.currentCoureItemIds){
                            this.ItemIDAns[i].Correct=this.backAnswer.correct
                          }
                        }
                        this.$store.commit('setItemIDsAnswer',this.ItemIDAns);
                    });
                  }else{
                      console.log('mmmmmmm:',{
                      CourseScoreID:"",
                      CourseBagID:this.courseId,
                      ItemID:this.ItemIDAns[i].ItemID,
                      CustomerAnswer:this.ItemIDAns[i].AnswerIDList[0]
                    })
                    api.AddTCustomer_AnswerAndViewAnalysis({
                      CourseScoreID:"",
                      CourseBagID:this.courseId,
                      ItemID:this.ItemIDAns[i].ItemID,
                      CustomerAnswer:this.ItemIDAns[i].AnswerIDList[0]
                    }).then(res=>{
                        console.log(res);
                        this.backAnswer=res.data.rows;
                        this.selectedOption=X;
                        // X === this.selectedOption ? this.selectedOption = '' : this.selectedOption =X;
                        console.log('第六次加载：',this.ItemIDAns,X)
                        //将以做完的A1A2题的答案题项id更新store
                        for(var i=0;i<this.ItemIDAns.length;i++){
                          if(this.ItemIDAns[i].ItemID==this.currentCoureItemIds){
                            this.ItemIDAns[i].Correct=this.backAnswer.correct
                          }
                        }
                        this.$store.commit('setItemIDsAnswer',this.ItemIDAns);
                        // if(this.backAnswer.correct){

                        // }
                    });
                  }

              }
          }
      }
    },
    formateStringToNum(obj){
     return String.fromCharCode(64 + parseInt(obj));
    },
    //获取题目信息
    GetSelectMockExamItem(obj){
        for(var i=0;i<this.ItemIDAns.length;i++){
          if(this.ItemIDAns[i].ItemID == obj && this.ItemIDAns[i].PItemID != null){
              obj=this.ItemIDAns[i].PItemID;
          }
        }

        api.postGetSelectMockExamItemSingle(obj).then(res=>{
          this.CI_ItemType=res.data.rows.CI_ItemType;
          this.CI_Caption= decodeUnicode(res.data.rows.Info_Item.CI_Caption.replace(/&#/g, '\\u'));
          console.log("ci_pation:"+this.CI_Caption);
          this.CI_ItemTagsID=res.data.rows.Info_Item.CI_ItemTagsID;
          this.itemImage=res.data.rows.Info_Item.CI_ItemCaptionImg;
          this.isImg =this.itemImage!="" && this.itemImage!=null;
          this.isMw=this.CI_ItemTagsID==consts.Nmg_MW;
          let Info_ItemOption=[];
          for(let item of res.data.rows.Info_ItemOption) {
            item.CO_Content=decodeUnicode(item.CO_Content.replace(/&#/g, '\\u'));
            Info_ItemOption.push(item);
          }
          this.Info_ItemOptions=Info_ItemOption;
          console.log("obj:"+obj+"\n res.data.rows.ItemChild:"+res.data.rows.ItemChild.length);
          switch(this.CI_ItemType)
          {
            case "A1型":
            case "A2型":
             this.showA1A2XItem=true;
             this.showA3A4Item=false;
             this.showB1Item=false;

             this.showSingle=true;
             this.showMutil=false;
             this.childItems=[];
             break;
            case "A3型":
            case "A4型":
             this.showA3A4Item=true;
             this.showA1A2XItem=false;
             this.showB1Item=false;
             this.childItems=res.data.rows.ItemChild;
             break;
            case "B1型":
             this.showB1Item=true;
             this.showA3A4Item=false;
             this.showA1A2XItem=false;
             this.childItems=res.data.rows.ItemChild;
             break;
            case "X型":
               this.showA1A2XItem=true;
               this.showA3A4Item=false;
               this.showB1Item=false;

               this.showSingle=false;
               this.showMutil=true;
               this.childItems=[];
              break;
          }

          var childItemCount=this.childItems.length;
          var valindex =0;
          console.log("1.childItemCount:"+childItemCount);
          valindex = this.indexOf(this.arrayIds, this.currentCoureItemIds);
          valindex=valindex<=0?0:valindex;

          if(childItemCount>1)
          {
            if(obj!=null){
                var ids=[];
                for(var i=0;i<this.ItemIDAns.length;i++)
                {
                    if(this.ItemIDAns[i].PItemID==obj)
                    {
                      ids.push(this.ItemIDAns[i].ItemID);
                    }
                }

                var starindex=this.indexOf(this.arrayIds,ids[0]);

                var startNUM=(starindex+1);
                var endNUM=startNUM+(childItemCount-1);
                this.itemRange=startNUM+"~"+endNUM;
            }

            this.nextCourseItemIds=this.arrayIds[endNUM];
            //console.log(" this.valindex:"+ valindex+"\n this.A3A4ChidItemIndex:"+this.A3A4ChidItemIndex+" \n endNUM:"+endNUM);
            this.prevCourseItemIds=this.arrayIds[startNUM-2];

          }else{
             console.log("2.valindex:"+(valindex+1));
            this.itemSortNUM=(valindex+1)+"、";
            this.itemSortNUM02=(valindex+1);
            this.prevCourseItemIds=this.arrayIds[valindex-1];
            this.nextCourseItemIds=this.arrayIds[valindex+1];
          }
        });
    },

    answerRemark(){
      if(this.dialogTableVisible==true){
        this.dialogTableVisible=false
        return false
      }
      var obj=this.currentCoureItemIds;
      for(var i=0;i<this.ItemIDAns.length;i++){
          if(this.ItemIDAns[i].ItemID == obj && this.ItemIDAns[i].PItemID != null){
              obj=this.ItemIDAns[i].PItemID;
          }
      }
      console.log("obj:"+obj);
      api.postGetCourseBagItemSingleAll(obj).then(res=>{
          this.dialogTableVisible=true;
          // console.log(JSON.stringify(res.data));
          switch(this.CI_ItemType){
            case "A1型":
            case "A2型":
              var optNum=1;
              if(res.data.SelecttedOptionSorts.indexOf(',')!=-1){
                optNum=res.data.SelecttedOptionSorts.substring(0,res.data.SelecttedOptionSorts.length-1);
              }else{
                optNum=res.data.SelecttedOptionSorts;
              }
              this.optAnswer=this.formateStringToNum(optNum);
              break;
            case "A3型":
            case "A4型":
            case "B1型":
              var vAnswer="";
              for(var i=0;i<res.data.ItemChild.length;i++){
                if(i===(res.data.ItemChild[i].Info_Item.CI_Sort-1)){
                  var valindex = this.indexOf(this.arrayIds, res.data.ItemChild[i].Info_Item.CI_ID);
                  vAnswer+=(valindex+1)+".&#160;"+this.formateStringToNum(res.data.ItemChild[i].SelecttedOptionSorts)+"&#160;&#160;&#160;&#160;";
                }
              }
              this.optAnswer=vAnswer;
              break;
            case "X型":
              var optArray=[];
              var vAnswer="";
              if(res.data.SelecttedOptionSorts.indexOf(',')!=-1){
                var X= res.data.SelecttedOptionSorts.substring(0,res.data.SelecttedOptionSorts.length-1);
                optArray=X.split(',');
                if(optArray!=null&&optArray.length>0){
                    for(var i=0;i<optArray.length;i++){
                      if(vAnswer=="")
                          vAnswer=this.formateStringToNum(optArray[i]);
                      else
                          vAnswer+=",&#160;"+this.formateStringToNum(optArray[i]);
                    }
                }
              }
              else{
                vAnswer=this.formateStringToNum(res.data.SelecttedOptionSorts);
              }
              this.optAnswer=vAnswer;
              break;
          }

          this.itemRemark=res.data.Info_Item.CI_Remark;
      }).then(res=>{
        console.log('pppppp',this.optAnswer,this.selIndex);
        // this.formateStringToNum(item.SelecttedOptionSorts)
      });
    },
    loadItemAnswer(){
        //定义一个题目父级id变量，主要用于A3A4B1型试题
        var vpid=null;
        //存储多选题的题项id
        this.CheckItems=[];
        //清空this.ItemIDAns变量的中的值
        this.ItemIDAns=[];
        //获取存入store中的答题卡数据，赋值给this.ItemIDAns
        this.ItemIDAns=this.$store.state.ItemIDsAnswer;

        //遍历答题卡数据集合，获取A1A2X型试题的答案和其父级id
        for(var i=0;i<this.ItemIDAns.length;i++){
              //判断答题卡数据集合中的题目id是否和当前题目id相同
              if(this.ItemIDAns[i].ItemID===this.currentCoureItemIds){
                //这个地方对题型没有做判断，故this.selectedOption是A1A2型题已做完的答案题项id
                this.selectedOption=this.ItemIDAns[i].AnswerIDList[0];
                //this.CheckItems是X型题已做完的答案题项id
                this.CheckItems=this.ItemIDAns[i].AnswerIDList;
                //题目父级id
                vpid=this.ItemIDAns[i].PItemID;
              }
        }
        //父级id不为null，说明该型题试A3A4B1型题
        if(vpid!=null)
        {
          //this.checkedValue存放A3A4B1型试题已做完的题项id，清空this.checkedValue中的值
          this.checkedValue=[];
          //再次遍历答题卡数据集合
          for(var i=0;i<this.ItemIDAns.length;i++){
                //答题卡中题目的父级id和变量vpid相同，说明这些都是当前试题的子题
                if(this.ItemIDAns[i].PItemID===vpid){
                  //存储各个子题的已做完的题项id，便于页面显示
                  this.checkedValue.push(this.ItemIDAns[i].AnswerIDList[0]);
                }
          }
        }
        // if(this.selectedOption){
        //   this.answerRemark();
        // }else{
        //   this.dialogTableVisible=false
        // }
    },

  }
};
</script>

<style>

.weTestConHeader{height: 30px;}
.weTestCodeItem {
    max-height: 400px;
    overflow-y: scroll;
}
.weTestInput{text-align: right;margin-right: 15px;}
.lable-title{font-size: 16px;font-family: Arial, Helvetica, sans-serif;}
.elradio-item{width: 100%;height: 28px;line-height: 28px; padding-left: 15px;margin-top: 10px;}
.div-empty{height: 15px;}
.div-childItem{float: left; width: 100%;height: 270px;}
.div-childItemB1{float: left; width: 100%;height: auto;margin-top: 10px;}
.div-option{float: left;width:100%;height: 28px;line-height: 28px; padding-left: 15px;margin-top: 5px;font-size: 16PX;}
.div-childItemB1 div{display: inline-block;width: 100%;height: 28px;line-height: 28px; padding-left: 15px;margin-top: 2px;margin-bottom: 5PX;}
.div-childItemB1 div label{margin-left: 2px;font-size: 16px;}
.div-childItem div{height: 28px;line-height: 28px; padding-left: 15px;margin-top: 5px;}
.div-childItem div label{margin-left: 2px;font-size: 16px;}
.elradio-childItemOption{width:10%;float: left;height: 28px;line-height: 28px; padding-left: 15px;margin-top: 3px;}

.radioList .el-radio__input.is-checked+.el-radio__label{color: red}
.radioList .el-radio__input.is-checked .el-radio__inner{background: #ff0000;border-color: red}

.weTestCon .p_mw {float: left; width: 30px;border-right: 1px solid #ddd;   margin-right: 30px;}

.elradio-item_mw {width: auto; padding-left: 15px;margin-top: 10px;}
.el-radio__label .span_mw {display: block;}
.el-checkbox__label .span_mw {display: block;}
.el-checkbox__label  {display: contents;}
.mongolianLanguage {
  font-family:'Menk Sidam Tig' ;
  word-wrap: break-word;
  writing-mode: vertical-rl;
  text-orientation: sideways;
  text-align: right;
  margin-top: 10px;
}
.lable-titleSortNUM{font-size: 16px;font-family: Arial, Helvetica, sans-serif;float: left;}
.lable-title_mw {
  font-size: 16px;
  font-family: 'Menk Sidam Tig';
  word-wrap: break-word;
  text-orientation: sideways;
  writing-mode: vertical-rl;
  text-align: right;
}
</style>
