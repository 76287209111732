<template>
<div class="payWarp" v-loading="loading">
    <el-steps :active="active" align-center  finish-status="success">
        <el-step title="请确认订单" description=""></el-step>
        <el-step title="在线激活" description=""></el-step>
        <el-step title="激活完成" description=""></el-step>
    </el-steps>
    <div class="payInfo" v-if="active==0">
        <div class="payBox01">订单信息</div>
        <div class="payBox02">
            <h3>{{orderInfoData.O_ORDERNAME}}</h3>
            <p>类型：<span>{{orderInfoData.ProjectName}}</span></p>
            <p>专业：<span>{{orderInfoData.ProfessionName}}</span></p>
            <!-- <p>运费：<i>00.00</i></p> -->
            <p v-if="CB_AreaCode!=this.AreaCode">课程价格：<strong>￥{{orderInfoData.O_SHOULDPAY}}</strong></p>

        </div>
        <div v-if="CB_AreaCode!=this.AreaCode" class="payBox03">实付金额：<strong>¥{{orderInfoData.O_SHOULDPAY}}</strong><span>（课程总应付 ¥{{orderInfoData.O_SHOULDPAY}}）</span></div>
        <div class="paybutton">
            <el-button type="primary" size="small"  @click="next">确认订单</el-button>
        </div>
    </div>
    <div class="payMonery" v-if="active==1">
        <div class="pay_txt">
            <p style="color:red">*请务必确认自身考试专业相应课程后再购买“电子培训资料”，购买后不支持退订！。</p>
            <p v-if="CB_AreaCode!=this.AreaCode" class="pay_txt1 f-f0">订单提交成功！请选择激活方式，尽快付款！</p>
            <p v-if="CB_AreaCode==this.AreaCode" class="pay_txt1 f-f0">订单提交成功！尽快激活！</p>
            <p class="pay_txt2 f-f0"> 您的订单号：<strong>{{orderInfoData.O_ORDERNO}}</strong> 请您在<span>30分钟</span>之内完成</p>
            <p v-if="CB_AreaCode!=this.AreaCode" class="pay_txt3 f-f0">应付金额：<span>¥</span><em>{{orderInfoData.O_SHOULDPAY}}</em></p>
        </div>
        <div class="payFang">
            <ul class="pay_sty_tit f-f0">
                <li style="border-left:0;" class="class_switch_button current" id="platTitle">支付平台</li>
            </ul>
            <div class="pay_sty_btn">
                <ul class="class_switch_area" id="platArea" style="display: block;">
                    <li  class="weixin" id="qr_normal">
                        <img alt="" src="@/assets/images/weixin.jpg">
                            <em></em>
                            <div class="qr_img_wrapper">
                                <img id="tenPayImg" alt="" :src="'data:image/png;base64,'+ wxSrc">
                                <p>微信扫码支付</p>
                            </div>
                    </li>
                    <li v-if="CB_AreaCode!=this.AreaCode" @click="zhifubaoPay">
                        <input id="PayMode36" type="radio" name="PayMode" platformid="8" bankid="8">
                        <a href="#"><img  alt="" bankid="PayMode36" src="@/assets/images/zfb2.jpg" title="支付宝"></a>
                    </li>
<!--                    <li @click="learningCardPay">-->
<!--                        <a href="#"><img alt="" src="@/assets/images/learningCard.jpg" title="学习卡激活" /></a>-->
<!--                    </li>-->
                </ul>
                 <el-form ref="form" :model="learningCard" v-if="fromVisable" label-width="80px" class="erty">
                            <el-form-item label="卡号">
                                <el-input v-model="learningCard.LC_CardNumber"></el-input>
                            </el-form-item>
                            <el-form-item label="密码">
                                <el-input type="password" v-model="learningCard.LC_Password"></el-input>
                            </el-form-item>
                            <el-form-item label="验证码">
                                <el-input
                                    placeholder="请输入验证码"
                                    v-model="learningCard.code"
                                    @focus="onLoginCodeImg"
                                    class="inputDuan">
                                    <template slot="prepend">
                                    <img src="@/assets/images/loginCode.png">
                                    <div class="inputTu"><img @click="onLoginCodeImg" :src="loginCodeImg" alt="验证码"></div>
                                    </template>
                                </el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" style="margin-left:100px;" @click="onSubmit">激活</el-button>
                            </el-form-item>
                            </el-form>
            </div>

        </div>
        <div class="wxti">
                        <!-- <h4>温馨提示：</h4>
                        <p>1、无需开通网银支付可选择银联在线进行付款。</p>
                        <p>2、支持以下第三方支付平台：支付宝、微信， 请根据实际情况选择。</p>
                        <p>3、支付成功之后，如果页面没有自动跳转到网上报名中心，请务必在支付成功页面点击去商场取货（通知商户）链接。</p> -->
                        </div>
        <div class="paybutton">
            <!-- <el-button type="primary" size="small"  @click="next">确认订单</el-button> -->
        </div>

    </div>
    <div class="paySuccess" v-if="active==2">
        <div class="Audit clearfix">
                        <div class="AuditLeft"><i class="el-icon-circle-check"></i></div>
                        <div class="AuditRight">
                            <ul>
                                <li><strong>用户,您好!</strong></li>
                                <li><span>您已成功激活了我们的培训课程！</span></li>
                                <li>您现在可以：<router-link :to="{name:'userCourse'}">去用户中心查看课程》</router-link></li>
                            </ul>
                        </div>
                    </div>
    </div>
</div>

</template>

<script>
import api from "@/api/index.js";
import consts from "@/common/constants.js"
export default{
    created(){
        console.log(this.$route.query)
        this.paramsId=this.$route.query.code;
        this.paramsData= this.$route.query;
        this.active= (+this.paramsData.active) || 0;
        this.orderCode = this.paramsData.orderCode;
        console.log(this.orderCode);
        this.orderOne();
        this.orderOneInfo();
        if(+this.paramsData.active==1){
            this.weixinPay();
        }
        this.onLoginCodeImg();
    },
    data(){
        return{
            orderCode:"",
            loading: false,
            active:0,
            paramsId:'',
            orderInfoData:'',
            wxSrc:'',
            learningCard:{
                LC_CardNumber:'',
                LC_Password:'',
                code:''
            },
            uid:'',
            fromVisable:false,
            CB_AreaCode:'',
            AreaCode:consts.Dg_AreaCode
        }
    },
    methods: {
      next() {
        //   if(this.active){}
        if (this.active++ > 2) this.active = 0;
        this.$route.query.active=1;
        console.log(this.$route.query.active);
        this.weixinPay();
      },
    //   基本信息
      orderOne(){
          api.postOrder(this.orderCode)
            .then(res=>{
                console.log('订单基本信息：',res);
                this.orderInfoData = res.data.rows;
                this.CB_AreaCode=this.orderInfoData.CB_AreaCode;
                console.log("orderInfoData:"+JSON.stringify(this.orderInfoData));
            })
      },
    //   详细
      orderOneInfo(){
          api.postOrderInfo(this.orderCode)
            .then(res=>{
                console.log('订单详情：',res)
            })
      },
    //   支付宝支付
    zhifubaoPay(){
        this.loading=true;
        api.postAlipayNotify(this.orderCode)
            .then(res=>{
                console.log('支付宝支付：',res);
                if (res.data.Status === 1) {
                    const div = document.createElement('div')
                    /* 此处form就是后台返回接收到的数据 */
                    div.innerHTML = res.data.rows
                    document.body.appendChild(div)
                    document.forms[0].submit()
                    this.loading=false;

                    //this.backSucessPage();
                    const timer = setInterval(() =>{
                        // 某些定时器操作
                        this.backSucessPage();
                    }, 5000);
                    // 通过$once来监听定时器，在beforeDestroy钩子可以被清除。
                    this.$once('hook:beforeDestroy', () => {
                        clearInterval(timer);
                    })
                }
            }).catch(err=>{
                console.log(err);
            })
    },
    //支付成功返回成功页面
    backSucessPage(){
        api.postOrderPayStatus(this.orderCode).then(res=>{
            if(res.data.rows.payStatus===1){
                this.active=2;
            }
        });
    },
    // 微信支付
    weixinPay(){
        api.postWxpayNotify(this.orderCode)
        .then(res=>{
            console.log('微信支付：',res);
            this.wxSrc=res.data.rows;

            //this.backSucessPage();
            const timer = setInterval(() =>{
                // 某些定时器操作
                this.backSucessPage();
            }, 10000);
            // 通过$once来监听定时器，在beforeDestroy钩子可以被清除。
            this.$once('hook:beforeDestroy', () => {
                clearInterval(timer);
            })
        }).catch(err=>{
                console.log(err);
        })
    },
    //加载验证码
    onLoginCodeImg(){
        this.uid=(new Date()).getTime();
        console.log(this.uid);
        api.geCodeImg({
          params:{
            uid:this.uid,
          }
        }).then(res=>{
        this.loginCodeImg="/api/CustomerAccount/VerifyRegister?uid=" + this.uid;
      }).catch(function (error) {
        console.log(error);
      });
   },
   learningCardPay(){
       this.fromVisable=!this.fromVisable;
   },
   //学习卡支付
   onSubmit(){
      console.log("this.orderCode:"+this.orderCode+"\n this.learningCard.LC_CardNumber:"+this.learningCard.LC_CardNumber);
      console.log("this.learningCard.LC_Password:"+this.learningCard.LC_Password);
      console.log("this.learningCard.code:"+this.learningCard.code);
      console.log("this.uid:"+this.uid);

      api.postLearningCardPay({
              OrderNo:this.orderCode,
              LearningCardNo:this.learningCard.LC_CardNumber,
              LearningCardPwd:this.learningCard.LC_Password,
              VerifyCode:this.learningCard.code,
              uid:this.uid
        }).then(res=>{
            console.log("res.data:"+JSON.stringify(res.data));
            if (res.data.Status === 1) {
                this.active=2;
            }else{
              this.$message({
                      message:res.data.Msg,
                      type: 'warning',
              });
            }
        });
   },

    }
}

</script>

<style>
.erty{
    /* float: left; */
    padding:20px 20px;
    border:1px solid #eee;
    width: 410px;
}
.inputDuan {
  width: 73%;
}
.inputTu {
  background: #eee;
  height: 40px;
  position: absolute;
  width: 90px;
  top: -1px;
  left: 235px;
  cursor: pointer;
  text-align: center;
  line-height: 40px;
}
.inputTu img{height: 40px; line-height: 40px; border:1px solid #ccc;}
</style>
