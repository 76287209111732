<template>
<div class="about1200">
    <div class="H_c_r_text">
                        <div class="H_c_r_t_title">行政前台</div>
                        <div class="H_c_r_t_con">
                            <p>一、岗位描述：</p>
                            <p>1、严格执行公司的接待服务规范，保持良好的礼节礼貌；</p>
                            <p>2、负责前台电话的接听和转接，做好来电咨询，重要事项需认真记录并传达给相关人员；</p>
                            <p>3、负责来访客户的接待，基本咨询和引见；</p>
                            <p>4、收发公司邮件、报刊、传真和物品，并做好登记管理以及转递工作；</p>
                            <p>5、办公用品的管理，同事名片的联络印制等日常行政辅助工作；</p>
                            <p>6、负责部分行政人事事务工作；</p>
                            <p>7、完成领导交给的其他事务性工作。</p>
                            <p>二、任职资格</p>
                            <p>1、文秘、行政管理等相关专业大专及以上学历； </p>
                            <p>2、熟悉前台工作流程，熟练掌握各种办公自动化设备和相关办公软件；</p>
                            <p>3、具备良好的协调能力、沟通能力，有责任心；</p>
                            <p>4、形象气质佳，性格活泼开朗，具有亲和力，普通话准确流利；</p>
                            <p>应聘简历请发邮箱：<a href="mailto:wyh@doc-exam.com" style="color:#39C">wyh@doc-exam.com</a></p>
                            <p>医东网络科技发展（上海）有限公司正处于急速发展期，期待您的加入！公司地址：上海市虹口区东体育会路1188号6楼</p>
                            <p>联系方式：021-55969966</p>
                        </div>
                    </div>
</div>

</template>

<script>
export default{

}

</script>

<style>
.about1200{ width: 1200px; margin: 0 auto; background: #fff;}
</style>