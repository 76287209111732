<template>
<div>
    <div class="ConRightNav">
      <router-link :to="{name:'index'}">首页</router-link>
      <strong>></strong>
      <span>帮助中心</span>
      <strong>></strong>
      <span>站点地图</span>
    </div>
    <div class="ConRightText">
        <div class="H_c_r_text">
                	<div class="H_c_r_t_title">医+E首页</div>
                    <div class="H_c_r_t_con">
                    	<!--<div class="H_c_liest"><a href="#" title="服务平台">服务平台</a></div>-->
                        <div class="H_c_liest"><a href="http://www.1pluse.com/items_list.html" target="_blank" title="产品介绍">产品介绍</a></div>
                        <div class="H_c_liest"><a href="http://www.1pluse.com/help_bzzx01.html" title="帮助中心" target="_blank">帮助中心</a></div>
                        <div class="H_c_liest"><a href="http://www.1pluse.com/about_gsjs.html" title="关于我们" target="_blank">关于我们</a></div>
                    </div>
                </div>
                <div class="H_c_r_text">
                	<div class="H_c_r_t_title">服务平台</div>
                    <div class="H_c_r_t_con">
                    	<div class="H_c_liest"><a href="http://www.1pluse.com/" title="医师定期考核" target="_blank">医师定期考核</a></div>
                        <div class="H_c_liest"><a href="http://www.1pluse.com/PeriodicAssess/DoubleEval" title="非公信用与能力评价" target="_blank">非公信用与能力评价</a></div>
                    </div>
                </div>
                <div class="H_c_r_text">
                	<div class="H_c_r_t_title">非公信用与能力评价</div>
                    <div class="H_c_r_t_con">
                    	<div class="H_c_liest"><a href="http://www.1pluse.com/PeriodicAssess/DoubleEvalList" title="双评课件" target="_blank">双评课件</a></div>
                        <div class="H_c_liest"><a href="http://www.1pluse.com/PeriodicAssess/NewsDynamics/?DoubleEval=True" title="双评资讯" target="_blank">双评资讯</a></div>
                        <div class="H_c_liest"><a href="http://www.1pluse.com/items_detail_new03.html#detail03_a" title="双评服务系统" target="_blank">双评服务系统</a></div>
                    </div>
                </div>
                <div class="H_c_r_text">
                	<div class="H_c_r_t_title">医师定期考核</div>
                    <div class="H_c_r_t_con">
                    	<div class="H_c_liest"><a href="http://www.1pluse.com/PeriodicAssess/NewsDynamics/10" title="新闻资讯" target="_blank">新闻资讯</a></div>
                        <!--<div class="H_c_liest"><a href="#" title="地方定期考核" >地方定期考核</a></div>-->
                        <div class="H_c_liest"><a href="http://www.1pluse.com/items_detail_new02.html#detail02_b" title="定考服务系统" target="_blank">定考服务系统</a></div>
                    </div>
                </div>
                <div class="H_c_r_text">
                	<div class="H_c_r_t_title">定考新闻资讯</div>
                    <div class="H_c_r_t_con">
                    	<div class="H_c_liest"><a href="http://www.1pluse.com/PeriodicAssess/NewsDynamics/14" title="政策文件" target="_blank">政策文件</a></div>
                        <div class="H_c_liest"><a href="http://www.1pluse.com/ExamineInfo/NewsExamineInfo/5" title="定考资讯" target="_blank">定考资讯</a></div>
                        <div class="H_c_liest"><a href="http://www.1pluse.com/PeriodicAssess/NewsDynamics/12" title="视频资料" target="_blank">视频资料</a></div>
                        <div class="H_c_liest"><a href="http://www.1pluse.com/PeriodicAssess/NewsDynamics/13" title="文件下载" target="_blank">文件下载</a></div>
                        <div class="H_c_liest"><a href="http://www.1pluse.com/help/index" title="定考Q&amp;A" target="_blank">定考Q&amp;A</a></div>
                    </div>
                </div>
                <div class="H_c_r_text">
                	<div class="H_c_r_t_title">地方定期考核</div>
                    <div class="H_c_r_t_con">
                    	<!--<div class="H_c_liest"><a href="#" title="全国">全国</a></div>-->
                        <div class="H_c_liest"><a href="http://www.1pluse.com/Dkindex/Index?id=3KcLKYoBU2E=" title="珠海" target="_blank">珠海</a></div>
                        <div class="H_c_liest"><a href="http://www.1pluse.com/Dkindex/Index?id=Rmkgn0oqNc8=" title="深圳" target="_blank">深圳</a></div>
                    </div>
                </div>
    </div>
</div>

</template>

<script>
export default{

}

</script>

<style>
</style>