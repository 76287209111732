<template>
<div>
    <div class="ConRightNav">
      <router-link :to="{name:'index'}">首页</router-link>
      <strong>></strong>
      <span>帮助中心</span>
      <strong>></strong>
      <span>法律声明</span>
    </div>
    <div class="ConRightText">
        <div class="H_c_r_t_con">
                    	<p>医+E（医加E）服务平台旗下包含医师定期考核和非公信用与能力评价等服务。医+E（医加E）在国家工商行政管理总局商标局注册并拥有<img src="../../assets/images/helplogoBg01.png"><img src="../../assets/images/helplogoBg02.png">等一系列商标。</p>
                    </div>
    </div>
</div>

</template>

<script>
export default{

}

</script>

<style>
</style>