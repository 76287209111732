<template>
<div class="courseBg">
    <div class="courseMain clearfix">
        <div class="courseLeft" v-loading="loading">
            <div class="courseDetailNav aa"> 
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>{{ProName}}列表</el-breadcrumb-item>
                    <!-- <el-breadcrumb-item>活动列表</el-breadcrumb-item> -->
                    <!-- <el-breadcrumb-item>定考详情</el-breadcrumb-item> -->
                </el-breadcrumb>
            </div>
            <div class="courseSearch">
                <!-- <course-search></course-search> -->
                <div class="courseSearchBox">
                    <!-- <div class="courseSeaItem">
                        <label>所属地区：</label>
                        <ul>
                            <li><a href="#" class="SeaItemOn">全部</a></li>
                            <li><a href="#">上海</a></li>
                            <li><a href="#">北京</a></li>
                            <li><a href="#">深圳</a></li>
                            <li><a href="#">安徽</a></li>
                        </ul>
                    </div> -->
                    <div class="courseSeaItem">
                        <label>项目类别：</label>
                        <ul>
                            <li  @click="allCourseDataList"><router-link :to="{name:'courseList',params:{id:'all'}}" >全部</router-link></li>
                            <!-- <li><a href="#" :class="{SeaItemOn:!projectCodeArr,}" @click="allCourseDataList">全部</a></li> -->
                            <!-- <li v-for="(item,index) in projiectType" :key="index"><a href="#" :dataId="item.ID" :class="{SeaItemOn:item.ID==projectCodeArr,}" @click="getProjectType(item.ID)">{{item.Project_Name}}</a></li> -->
                            <li v-for="(item,index) in projiectType" :key="index" @click="getProjectType(item.ID)"><router-link :to="{name:'courseList',params:{id:item.ID}}" >{{item.Project_Name}}</router-link></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="courseMainList">
                <course-item v-for="(item,index) in courseData" :key="index" :itemCourseData="item"></course-item>
            </div>
            <div class="coursePage">
                <!-- <course-page :itemPageRows="pageRows"></course-page> -->
                <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="pageRows"
                    :page-size="pageSize"
                    :current-page="currentPage"
                    @current-change="handleCurrentChange">
                </el-pagination>
            </div>
        </div>
        <div class="courseRight">
            <course-right></course-right>
            <div class="courseRightTu"><img src="../assets/images/adver03.jpg"></div>
        </div>
    </div>
</div>


</template>

<script>
import Vue from 'vue'
import coursePage from '@/components/course/coursePage.vue'
import courseSearch from '@/components/course/courseSearch.vue'
import courseItem from '@/components/course/courseItem.vue'
import courseRight from '@/components/course/courseRight.vue'
import api from "@/api/index.js";
import { setTimeout } from 'timers';
export default{
    created(){
        this.paramsId=this.$route.params.id;
        console.log("传过来的id值:",this.paramsId);
        this.getXiangmu();
        // this.intList();
        // this.getZhuangke();
    },
    components:{
        coursePage,
        courseSearch,
        courseItem,
        courseRight
    },
    data(){
        return{
            loading: true,
            ProName:'',
            projiectType:'',
            paramsId:'',
            projectCodeArr:'',
            currentPage:1,
            pageRows:null,
            pageSize:9,
            courseData:[],
            
        }
    },
    methods:{
        // 项目类型下拉框
        getXiangmu(){
            api.getProjectCommboxList()
            .then(res=>{
                console.log('项目类型下拉框数据源',res)
                this.projiectType=res.data
            }).then(res=>{
                this.intList();
                if(this.paramsId=='all'){
                    this.allCourseDataList();
                }
            }).catch(error=>{
                console.log(error);
            })
        },
        // 专科下拉框
        getZhuangke(){
            api.getTreeComboList()
            .then(res=>{
                console.log('专科下拉框数据源:',JSON.parse(res.data));
            }).catch(error=>{
                console.log(error);
            })

        },
        // 初始化加载课程列表
        intList(){
            api.getProjectData({
                CB_NAME: '',
                CB_KEYWORDS: '',
                CB_COURSETYPE: '',
                CB_TYPE: '',
                CB_PROJECTCODE: this.paramsId,
                CB_PROFESSIONCODE: '',
                page: this.currentPage,
                rows: this.pageSize
            }).then(res=>{
                // Vue.delete(this.courseData,this.courseData.length);
                // for(var i=0;i<res.data.rows.length;i++){
                //     this.courseData.push(res.data.rows[i]);
                // };
                this.courseData=res.data.rows;
                this.pageRows=res.data.total;
                this.loading=false;
                console.log("课程列表:",res);
            }).then(res=>{

            })
            .catch(error=> {
                    console.log(error);
            })
            this.getProName();
        },
        handleCurrentChange(val){
            this.currentPage=val;
            this.intList();
            setTimeout(function(){
                if(document.body.scrollTop){
                    document.body.scrollTop = 0
                }else {
                    document.documentElement.scrollTop = 0
                }
            },300)
        },
        // 全部分类数据的列表
        allCourseDataList(){
            this.currentPage=1;
            this.paramsId='';
            this.intList();
            this.ProName='全部';
        },
        // 单个分类数据列表
        getProjectType(val){
            Vue.delete(this.courseData,this.courseData.length);
            this.currentPage=1;
            this.paramsId = val;
            this.intList();
        },
        // 列表的名字
        getProName(){
            this.projiectType.forEach((item,index) => {
                if(item.ID== this.paramsId){
                    this.ProName=item.Project_Name;
                    console.log(this.ProName);
                }
            });
        }
    },
}

</script>

<style>

</style>