import Vue from 'vue'
import Router from 'vue-router'
const defaultPage = () =>import('@/layout/default.vue')
const blankPage = () =>import('@/layout/blank.vue')
const userCenter = () =>import('@/page/userCenter.vue')
const loginPage = () =>import('@/page/login.vue')
const registerPage = () =>import('@/page/register.vue')
const backPassword = () =>import('@/page/backPassword.vue')
//const indexPage = () =>import('@/page/indexPage.vue')
const newIndexPage=()=>import('@/page/newIndexPage.vue')
const courseList = () =>import('@/page/courseList.vue')
const newCourseList =()=>import('@/page/newCourseList.vue')
const courseDetail = () =>import('@/page/courseDetail.vue')
const helpPage = () =>import('@/page/help.vue')
const servicePage = () =>import('@/page/servicePage.vue')
const dayTopic = () =>import('@/page/dayTopic.vue')
const dayTopicDetail = () =>import('@/page/dayTopicDetail.vue')
const AreaCity=()=>import('@/page/AreaCity.vue')
const areaCourseList=()=>import('@/page/areaCourseList.vue')
const areaCourseShow=()=>import('@/page/areaCourseShow.vue')
const areaCourseShowList=()=>import('@/page/areaCourseShowList.vue')
const BusinessAreaIndex=()=>import('@/page/BusinessAreaIndex.vue')
const pay = () =>import('@/page/pay.vue')
const aboutPage = () =>import('@/page/about.vue')
const aboutIntroduce = () =>import('@/components/about/aboutIntroduce.vue')
const aboutNews = () =>import('@/components/about/aboutNews.vue')
const aboutDeed = () =>import('@/components/about/aboutDeed.vue')
const aboutAptitude = () =>import('@/components/about/aboutAptitude.vue')
const aboutInfo = () =>import('@/components/about/aboutInfo.vue')
const aboutPartner = () =>import('@/components/about/aboutPartner.vue')
const aboutUs = () =>import('@/components/about/aboutUs.vue')
import userIndex from '@/components/user/userIndex.vue'
import userZhang from '@/components/user/userZhang.vue'
import userOrder from '@/components/user/userOrder.vue'
import userCourse from '@/components/user/userCourse.vue'
import userHospital from '@/components/user/userHospital.vue'
import userSchool from '@/components/user/userSchool.vue'
import userBase from '@/components/user/userBase.vue'
import userMessage from '@/components/user/userMessage.vue'
const userTestList= () =>import('@/components/user/userTestList.vue')
const usersImulation= () =>import('@/components/user/usersImulation.vue')
const pdf= () =>import('@/components/user/pdf.vue')
const userCourselearn= () =>import('@/components/user/userCourselearn.vue')

const helpProblem= () =>import('@/components/help/help01.vue')
const helpNew= () =>import('@/components/help/help02.vue')
const helpMap= () =>import('@/components/help/help03.vue')
const helpStatement= () =>import('@/components/help/help04.vue')
const helpAgreement= () =>import('@/components/help/help05.vue')
const helpPrivacy= () =>import('@/components/help/help06.vue')
const helpLiability= () =>import('@/components/help/help07.vue')

Vue.use(Router)

// 解决报错
const originalPush = Router.prototype.push
const originalReplace = Router.prototype.replace
// push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
Router.prototype.replace = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass:'active',
  scrollBehavior(to,from,saveTop){
    if(saveTop){
      return saveTop;
    }else{
      return {x:0,y:0}
    }},
  linkExactActiveClass:'ex-li-active',
  routes: [
    {
      path: '/',
      name: 'default',
      component: defaultPage,
      redirect: '/index',
      children:[
        {
          path: '/index',
          name: 'index',
          component: newIndexPage,
        },
        {
          path: '/pdf',
          name: 'pdf',
          component: pdf,
        },
        {
          path: '/help',
          name: 'help',
          component: helpPage,
          redirect: '/helpProblem',
          children:[
            {
              path: '/helpProblem',
              name: 'helpProblem',
              component: helpProblem,
            },
            {
              path: '/helpNew',
              name: 'helpNew',
              component: helpNew,
            },
            {
              path: '/helpMap',
              name: 'helpMap',
              component: helpMap,
            },
            {
              path: '/helpStatement',
              name: 'helpStatement',
              component: helpStatement,
            },
            {
              path: '/helpAgreement',
              name: 'helpAgreement',
              component: helpAgreement,
            },
            {
              path: '/helpPrivacy',
              name: 'helpPrivacy',
              component: helpPrivacy,
            },
            {
              path: '/helpLiability',
              name: 'helpLiability',
              component: helpLiability,
            },
          ]
        },
        {
          path: '/pay',
          name: 'pay',
          component: pay,
        },
        {
          path: '/dayTopic',
          name: 'dayTopic',
          component: dayTopic,
        },
        {
          path: '/dayTopicDetail/:id',
          name: 'dayTopicDetail',
          component: dayTopicDetail,
        },
        {
          path: '/service/content',
          name: 'service',
          component: servicePage,
          beforeEnter: (to, from, next) => {
            console.log('路由-前置-钩子')
            next()
          }
        },
        {
          path: '/service/courseList/:id',
          name: 'courseList',
          component: courseList,
        },
        {
          path: '/service/newCourseList/:id',
          name: 'newCourseList',
          component: newCourseList,
        },
        {
          path:'/service/BusinessAreaIndex',
          name:'BusinessAreaIndex',
          component:BusinessAreaIndex,
        },
        {
          path: '/service/courseDetail/:id',
          name: 'courseDetail',
          component: courseDetail,
        },
        {
          path: '/service/areaCourseList/:id/:areaid',
          name: 'areaCourseList',
          component: areaCourseList,
        },
        {
          path: '/about',
          name: 'about',
          component: aboutPage,
          redirect: '/about/aboutIntroduce',
          children:[
            {
                path: 'aboutIntroduce',
                name: 'aboutIntroduce',
                component: aboutIntroduce,
            },
            {
              path: 'aboutNews',
              name: 'aboutNews',
              component: aboutNews,
            },
            {
              path: 'aboutDeed',
              name: 'aboutDeed',
              component: aboutDeed,
            },
            {
              path: 'aboutAptitude',
              name: 'aboutAptitude',
              component: aboutAptitude,
            },
            {
              path: 'aboutInfo',
              name: 'aboutInfo',
              component: aboutInfo,
            },
            {
              path: 'aboutPartner',
              name: 'aboutPartner',
              component: aboutPartner,
            },
            {
              path: 'aboutUs',
              name: 'aboutUs',
              component: aboutUs,
            },
          ]
        },
      ]
    },
    {
      path: '/AreaCity/:id',
      name: 'AreaCity',
      component: AreaCity,
    },
    {
      path:'/areaCourseShow/:id',
      name:'areaCourseShow',
      component:areaCourseShow,
    },
    {
      path:'/areaCourseShowList',
      name:'areaCourseShowList',
      component:areaCourseShowList,
    },
    {
      path: '/blank',
      name: 'blank',
      component: blankPage,
      children:[
        {
          path: 'login',
          name: 'login',
          component: loginPage,
        },
        {
          path: 'register',
          name: 'register',
          component: registerPage,
        },
        {
          path: 'backPassword',
          name: 'backPassword',
          component: backPassword,
        },
      ]
    },
    {
      path: '/userCenter',
      name: 'userCenter',
      component: userCenter,
      redirect: '/userIndex',
      children:[
        {
          path: '/userIndex',
          name: 'userIndex',
          component: userIndex,
        },
        {
          path: '/userZhang',
          name: 'userZhang',
          component: userZhang,
        },
        {
          path: '/userOrder',
          name: 'userOrder',
          component: userOrder,
        },
        {
          path: '/userHospital',
          name: 'userHospital',
          component: userHospital,
        },
        {
          path: '/userSchool',
          name: 'userSchool',
          component: userSchool,
          // children:[
          //   {
          //     path: '/userTestList',
          //     name: 'userTestList',
          //     component: userTestList,
          //   }
          // ]
        },
        {
          path: '/userCourse/userTestList',
          name: 'userTestList',
          component: userTestList,
        },
        {
          path: '/userCourse/usersImulation',
          name: 'usersImulation',
          component: usersImulation,
        },
        {
          path: '/userCourse',
          name: 'userCourse',
          component: userCourse,
        },
        {
          path:'/userCourse/userCourselearn',
          name:'userCourselearn',
          component:userCourselearn,
        },
        {
          path: '/userBase',
          name: 'userBase',
          component: userBase,
        },
        {
          path: '/userMessage',
          name: 'userMessage',
          component: userMessage,
        },
      ]
    },
    // {
    //   path: '/about',
    //   name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
      // component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    // }
  ]
})
var then = this;
// 路由守卫
router.beforeEach((to, from, next) => {
  // const isLogin = localStorage.ele_login ? true : false;
  console.log( to,from,Vue);
  next();
  if(to.name=='index'){
    localStorage.setItem('setCourseListUrl','');
  }
  else if(to.name == 'courseList'){
    // console.log(then);
    // const isLogin = localStorage.ele_login ? true : false;
    //Vue.$store.commit('setCourseListUrl',to.path);
    var aa=Vue.prototype.$cookies.get('userName');

    localStorage.setItem('setCourseListUrl',to.path);
    console.log(localStorage.getItem('userName'));
    localStorage.getItem('userName') ? next() : next('/blank/login');
  }
  else if(to.name == 'areaCourseList'){
    localStorage.setItem('setCourseListUrl',to.path);
    localStorage.getItem('userName') ? next() : next('/blank/login');
  }
  else if(to.name=='areaCourseShow'){
    localStorage.setItem('setCourseListUrl',to.path);
    //localStorage.getItem('userName') ? next() : next('/blank/login');
  }
  else if(to.name=='newCourseList'){
    localStorage.setItem('setCourseListUrl',to.path);
    //localStorage.getItem('userName') ? next() : next('/blank/login');
  }
  else{
    next();
  }

  // if (to.path == '/login') {
  //   next();
  // } else {
  //   // 是否在登录状态下
  //   isLogin ? next() : next('/login');
  // }
});


export default router;
