<template>
<div class="footerBottomCon">
    <div class="footBotGuan">
        <div class="width200">

            <div class="footBotBox02">
                <ul>
                    <li>
                        <dt>关于我们</dt>
                        <!-- <dd><router-link :to="{name:'aboutIntroduce'}">公司介绍</router-link></dd>
                        <dd><router-link :to="{name:'aboutAptitude'}">公司资质</router-link></dd>
                        <dd><router-link :to="{name:'aboutPartner'}">合作伙伴</router-link></dd>
                        <dd><router-link :to="{name:'aboutInfo'}">招聘信息</router-link></dd> -->
                        <dd><a href="http://www.doc-exam.com/contact_us.html" target="_blank">公司介绍</a></dd>
                        <dd><a href="http://www.doc-exam.com/qualification.html" target="_blank">公司资质</a></dd>
                        <dd><a href="http://www.doc-exam.com/partner.html" target="_blank">合作伙伴</a></dd>
                        <!-- <dd><a href="http://www.doc-exam.com/recruitment.html" target="_blank">招聘信息</a></dd> -->
                    </li>
                    <li>
                        <dt>服务条款</dt>
                        <dd><router-link :to="{name:'helpStatement'}">法律声明</router-link></dd>
                        <dd><router-link :to="{name:'helpAgreement'}">服务协议</router-link></dd>
                        <dd><router-link :to="{name:'helpPrivacy'}">隐私条款</router-link></dd>
                    </li>
                    <li>
                        <dt>帮助中心</dt>
                        <dd><router-link :to="{name:'helpProblem'}">常见问题</router-link></dd>
                        <dd><router-link :to="{name:'helpNew'}">新手上路</router-link></dd>
                        <!-- <dd><router-link :to="{name:'helpMap'}">站点地图</router-link></dd> -->
                        <dd><router-link :to="{name:'helpLiability'}">免责条款</router-link></dd>
                    </li>
                    <li>
                        <dt>联系我们</dt>
                        <!-- <dd><router-link :to="{name:'aboutUs'}">联系我们</router-link></dd>
                        <dd><router-link :to="{name:'aboutInfo'}">招聘信息</router-link></dd>
                        <dd><router-link :to="{name:'aboutPartner'}">合作伙伴</router-link></dd> -->
                        <dd><a href="http://www.doc-exam.com/about_lxwm.html" target="_blank">联系我们</a></dd>
                        <dd><a href="http://www.doc-exam.com/recruitment.html" target="_blank">招聘信息</a></dd>
                        <!-- <dd><a href="http://www.doc-exam.com/partner.html" target="_blank">合作伙伴</a></dd> -->
                    </li>
                </ul>
            </div>
            <div class="footBotBox01" style="text-align: center; color:white;    border-left: 1px solid #fafafa;
    padding-left:9px;"><img style="width:140px;" src="https://prd-yidong.oss-cn-shanghai.aliyuncs.com/cms/1pluse/gzh.png">医+E公众号</div>
            <div class="footBotBox01" style="text-align: center; color:white"> <img style="width:140px;" src="https://prd-yidong.oss-cn-shanghai.aliyuncs.com/cms/1pluse/ydapp.png" />医东APP</div>
            <div class="footBotBox03">
                <h3>客服QQ：1516938814</h3>
                <P>客服电话：400-6623-955</P>
                <P>邮箱：zxm@doc-exam.com</P>
                <P>工作时间：周一至周五 9:30 - 17:30</P>
            </div>
        </div>
    </div>
    <div class="footBotBei">
        <div class="width200">
            <p>©医东网络科技发展（上海）有限公司版权所有</p>
          <p><a target="_blank" href="http://www.beian.gov.cn" style="color: white;">沪公网安备 31010902001559号</a> <a target="_blank" href="http://beian.miit.gov.cn" style="color: white;">沪ICP备14003938号-2</a></p>
        </div>
    </div>
</div>

</template>

<script>
export default{

}

</script>

<style>
.footerBottomCon{ font-size: 14px; }
.footBotGuan{  background: #2880a8;padding:15px 0;}
.footBotBox01{ float: left; width: 150px; margin-left: 17px;height: 192px;}
.footBotBox01 img{display: block;}
.footBotBox02{float: left; }
.footBotBox02 ul{overflow: hidden;}
.footBotBox02 ul li{ color: #fff; float: left;  margin-left:50px; margin-right: 15px; text-align: center;}
.footBotBox02 ul li a{ color: #eee;}
.footBotBox02 ul li dt{ font-weight: bold; padding: 25px 0;}
.footBotBox02 ul li dd{ margin-bottom: 15px; font-size: 12px;}
.footBotBox02 ul li dd a:hover{ color: #fff;}
.footBotBox03{float: right;color: #fff; width: 300px; text-align: center; padding:0 20px 0 50px; border-left: 1px solid #fafafa; height: 192px;}
.footBotBox03 h3{ background-color: #0ba4ea; border-radius:20px; width: 240px; margin: 0 auto; margin-top: 15px; font-size: 14px; color: #fff; font-weight: normal; line-height: 35px;}
.footBotBox03 p{ margin-top: 25px;}
.width200{width: 1200px; margin: 0 auto;overflow: hidden;}
.footBotBei{background:#145370; padding:12px 0; text-align: center; color: #fff;}
.footBotBei p{ margin-top: 10px;}
</style>
