<template>
<div class="courseColList" :title="itemCourseData.CB_NAME">
            <router-link :to="{name:'courseDetail',params:{id:itemCourseData.CB_ID}}"><img :src="itemCourseData.CB_PCPICTUREURL" class="image"></router-link>
            <div style="padding:5px 0px;" class="courseItemTitle">
                <h3><router-link :to="{name:'courseDetail',params:{id:itemCourseData.CB_ID}}">{{itemCourseData.CB_NAME.toString().length>30?itemCourseData.CB_NAME.toString().substring(0,30)+"...":itemCourseData.CB_NAME}}</router-link></h3>
                <p><span>{{itemCourseData.ProfessionName}}</span><strong>{{itemCourseData.ProjectName}}</strong></p>

                <p v-if="itemCourseData.CB_AreaCode!=this.AreaCode"><span class="red" v-if="itemCourseData.CB_REALPRICE">￥{{itemCourseData.CB_REALPRICE}}元</span>
                    <span class="green" v-if="!itemCourseData.CB_REALPRICE&&itemCourseData.ChargesTypeName">{{itemCourseData.ChargesTypeName}}</span></p>
            </div>
            <!-- <div class="courseOn"><span v-if="itemCourseData.CB_TOTALCT">{{itemCourseData.CB_TOTALCT}}个课件</span><span v-if="itemCourseData.CB_TOTALCT">{{itemCourseData.CB_TOTALCT}}套试卷</span><span v-if="itemCourseData.CB_TOTALCT">{{itemCourseData.CB_TOTALCT}}个指南书</span></div> -->
        </div>

</template>

<script>
    import consts from "@/common/constants.js"
export default{
    props:["itemCourseData"],
    data() {
    return {
      //itemCourseData:[],
      //url:'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1563356619680&di=67889c713cf0b672314a60bbd7e1f6ea&imgtype=0&src=http%3A%2F%2Fhiphotos.baidu.com%2Ffeed%2Fpic%2Fitem%2Fb999a9014c086e06fb8324bc09087bf40ad1cb1a.jpg',
        url:'',
        cbName:'',
        AreaCode:consts.Dg_AreaCode
    }
  },
  created(){
      //this.itemCourseData=this.itemCourseData;
  },
  mounted(){
    this.imgInt();
  },
  methods:{
    // 图片加载
    imgInt(){
      // var newImg = new Image();
      // newImg.src = this.itemCourseData.CB_PCPICTUREURL;
      // // newImg.src = 'http://attach.bbs.miui.com/forum/201512/16/170538u1was1fsgrfwbnhq.jpg';
      // newImg.onerror = () => { // 图片加载错误时的替换图片
      //   newImg.src = 'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1563356514963&di=bc800eb040490320d6d0e187dd975efa&imgtype=0&src=http%3A%2F%2Fimg.ui.cn%2Fdata%2Ffile%2F1%2F8%2F9%2F220981.gif'
      // }
      // newImg.onload = () => { // 图片加载成功后把地址给原来的img
      //   this.url = newImg.src

      // }

      this.url=this.itemCourseData.CB_PCPICTUREURL;
      this.cbName=this.itemCourseData.CB_NAME;
    },
    SubNAMEString(obj){

      if(obj.toString().length>30){
          obj=obj.toString().substring(0,30)+"...";
      }
      return obj;
    }
  },
}

</script>

<style>
.courseItemTitle{font-size: 14px;}
.courseItemTitle h3{ font-weight: normal; font-size: 14px; line-height: 20px; height: 40px; margin-bottom: 5px;}
.courseItemTitle p{ color: #999;}
.courseItemTitle p span{ }
.courseItemTitle p span.red{ color: red; font-size: 14px; font-weight: bold; }
.courseItemTitle p span.green{ color: green; font-size: 14px; }
.courseItemTitle p strong{ float:right; font-weight: normal; }
.courseColList{ display: inline-block; border:1px solid transparent;    position: relative; margin: 5px; padding:10px; width: 285px; box-sizing: border-box;}
.courseOn{position:absolute; top:125px; background-color: rgba(0,0,0,.5); width: 100%; color: #fff; font-size: 12px; line-height: 24px; padding-left: 5px; box-sizing: border-box; width: 274px;}
.courseOn span{ margin-right: 5px;}
.image {
    width: 100%;
    height: 140px;
    display: block;
  }

.courseColList:hover{
    webkit-box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
    border: 1px solid #eee;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);background-color: #fff;
}

</style>