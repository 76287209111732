<template>
<div >
    <div v-if="!this.$store.state.isLogin">
        <div class="hederLogin" ><router-link :to="{name:'login'}">登录</router-link></div>
        <div class="hederRegister"><router-link :to="{name:'register'}">注册</router-link></div>
    </div>
    <div v-else class="compLogin">
        <span>欢迎您，<router-link :to="{name:'userCenter'}">用户中心</router-link></span>|<strong @click="exitLogin">[ 退出 ]</strong>
    </div>

</div>

</template>

<script>
import api from "@/api/index.js";
export default{
    created(){
        console.log('登录状态:',this.$store.state.isLogin);
    },
    methods:{
        exitLogin(){
        api.loginOut()
            .then(res=>{
                console.log(res);
                if(res.data.Status==1){
                    this.$store.commit("setUserName",'')
                    localStorage.setItem('setCourseListUrl','')
                    this.$store.commit("changeLogin",false)
                    this.$store.commit('del_token')
                    this.$router.push({name:'index'});
                    this.$message({
                    message:'你已经成功退出登陆状态',
                    type: 'success',
                  });
                }else{
                    this.$message({
                    message:'退出失败',
                    type: 'success',
                  });
                }
            }).then(res=>{
                // this.$store.commit('del_token')
            })
        // this.$store.commit('del_token');
      }
    }

}

</script>

<style>
.hederLogin,.hederRegister{ float: left; font-size: 14px; margin-top: 30px; margin-left: 10px;}
.hederLogin a{padding:5px 15px; background-color:#0e6eb8; color:#fff; border-radius: 5px; border:1px solid #0e6eb8}
.hederLogin a:hover{ color: #fff;}
.hederRegister a{padding:5px 15px; background-color:#fff; color:#333; border-radius: 5px;border:1px solid #ccc;}
.hederRegister a:hover{ background: #ecf5ff; color: #fff;}
.compLogin{ line-height: 78px; font-size: 14px;}
.compLogin span{}
.compLogin a{ color: #0e6eb8; margin-right: 5px;}
.compLogin strong{ font-weight: normal ;margin-left:5px; color:#999;}
.compLogin strong:hover{ color: #0e6eb8; cursor: pointer;}
</style>
