<template>
<div>
    <div class="ConRightNav">
      <router-link :to="{name:'index'}">首页</router-link>
      <strong>></strong>
      <span>帮助中心</span>
      <strong>></strong>
      <span>隐私条款</span>
    </div>
    <div class="ConRightText">
        <div class="H_c_r_text">
                	<div class="H_c_r_t_title">隐私条款</div>
                    <div class="H_c_r_t_con">隐私权是用户的重要权利，对于医+E（医加E）服务平台的所有用户，我们均会以负责的态度对待您的个人信息，特此制定医+E（医加E）服务平台（http://www.1pluse.com, http://www.1pluse.cn, http://www.1pluse.com，以下称"本网站"）对用户相关信息所采取的收集、使用和保护政策。 医+E（医加E）服务平台隐私保护声明系本网站保护用户个人隐私的公告和承诺，请您务必仔细阅读</div>
                	<div class="H_c_r_t_con">
                    	<div class="H_c_h3con">
                        	<h2>一、用户非个人信息</h2>
                            <p>我们将通过您的IP地址来收集非个人化的信息，例如用户的浏览器性质、操作系统种类、给您提供接入服务的ISP域名、您访问本网的顺序和链接途径、您访问时所在的地理位置等。通过收集上述信息，我们将优化在您计算机屏幕上显示的页面，同时进行客流量统计、访问者阅读习惯整理等，从而改进本网站的管理和服务。 这些无关个人身份的信息能帮助我们识别医+E（医加E）培训平台最受欢迎的地区和内容等，并帮助我们确定推广活动的有效性。此外，我们也可能将这些信息披露给我们的广告客户，使他们知道点击他们广告的效果数据。</p>
                        </div>
                        <div class="H_c_h3con">
                        	<h2>二、用户个人信息</h2>
                            <p>1、当您在医+E（医加E）服务平台进行用户注册登记、参加网上论坛等各种活动时，在您的同意并确认下，本网站将通过注册表格等形式要求您提供一些个人信息。这些个人信息包括： （1）个人识别资料：如姓名、性别、年龄、出生日期、身份证号码（或护照号码）、座机/手机、通信地址、住址、电子邮件地址等信息。 （2）个人背景：职业、教育程度、收入状况、婚姻状况、家庭状况、兴趣爱好等。 2、请了解，在未经您同意并确认之前，本网站不会将您提供的信息用于其它目的。惟按下列第八章规定应政府及法律相关要求披露时不在此限。 医+E（医加E）服务平台的目标是向所有用户提供愉快、有益的上网经历，并使每个用户使用医+E（医加E）服务平台相关服务时能够更容易、更满意，而收集这些个人信息有助于我们实现这一目标。 所有的注册成员和访问者都应该知道，当他们在公告栏、聊天室或其他网上公开场合披露其个人信息时，该信息可能会被他人收集并使用，包括用来向该用户发送未经邀约的电子邮件等广告信息。</p>
                        </div>
                        <div class="H_c_h3con">
                        	<h2>三、用户权利</h2>
                            <p>1、本网站用户对于自己的个人信息享有以下权利： （1）随时查询及请求阅览； （2）随时请求补充或更正； （3）随时请求删除； （4）请求停止电脑处理及使用。 2、本网站为用户提供各种配套服务，我们通常要求参与者填写相关资料登记表格，以便活动更好的开展。此类信息也将成为本网用户数据的一部分，并将被后续记录、保存、保护和合理使用。是否提供所要求的信息和是否参加此类活动，这完全取决于用户自身。 3、针对以上权利，本网站为您提供相关服务，如用户有任何建议或意见，请发送电子邮件至 webservice@doc-exam.com</p>
                        </div>
                        <div class="H_c_h3con">
                        	<h2>四、用户信息安全保护</h2>
                            <p>1、本网站将严格保护您的个人信息安全，对您所提供的信息采取严密的管理及保护措施。我们将采用相应的安全技术和程序来防止您的个人信息丢失、被盗用或遭篡改，并且保护您的个人信息不被未经授权者访问、使用或泄漏。 2、本网站将在必要时委托专业技术人员代为对该类资料进行电脑处理，以符合专业分工之需求。如本网站将电脑处理之通知送达予您，而您未在通知规定的时间内主动明示反对，本网站将善意推定您已同意。此后您仍然有权按照上述第三章第1条第（4）款之规定，请求停止电脑处理。</p>
                        </div>
                        <div class="H_c_h3con">
                        	<h2>五、用户隐私自我保护</h2>
                            <p>1、医+E（医加E）服务平台为您提供论坛讨论等服务，并善意提醒所有用户，该区域为网络公开场合，在这些区域内，您公布的任何信息都有可能成为公开的信息。因此，本网提醒您，在这些区域公开您的个人信息之前请慎重考虑。 2、用户是唯一对自己的账号密码及其相关注册信息负有权限及保密责任的人。因此，当你在线时，请务必小心保护自己的账户安全。</p>
                        </div>
                        <div class="H_c_h3con">
                        	<h2>六、未成年人隐私权的保护</h2>
                            <p>1、本网站将建立相应合理程序，以保护未成年人个人信息的安全。本网站郑重声明：任何十六岁以下的未成年人参加网上活动应事先得到家长或其法定监护人（以下统称为“监护人”）的明确同意。 2、未经监护人同意，未成年人私自上网或者冒用成人名义上网的行为将可能缺乏与未成年人相应的信息安全保护措施。 3、监护人应承担保护未成年人相关网络隐私的首要责任。 4、本网站收集未成年人的个人信息，仅为更好服务于未成人特定要求的目的，相关资料仅作为保护未成年人参与网络活动时的安全之用，而不会保留这些信息做进一步或其他商业目的的使用。 5、未经监护人同意，本网站将不会使用未成年人的个人信息，亦不会向任何第三方披露或传送可识别该未成人的个人信息。惟按上述第六章规定应政府及法律相关要求披露时不在此限。 6、若经未成年人的监护人同意，本网站可对未成年人的个人信息进行收集，本网站将向监护人提供： （1）审视其子女或被监护人相关信息的机会； （2）拒绝其子女或被监护人相关个人信息被进一步收集或使用的可能； （3）变更或删除其子女或被监护人个人信息。 7、监护人有权拒绝本网站与其子女或被监护人做进一步的联络。</p>
                        </div>
                        <div class="H_c_h3con">
                        	<h2>七、用户信息使用原则</h2>
                            <p>惟在符合下列条件之一的情况下，本网站方可能对本网用户的个人信息进行必要范围之外的合理使用： 1、已取得您的书面同意； 2、为免除您在生命、身体或财产方面的紧急危险； 3、为防止他人权益的重大危害； 4、为增进公共利益，且无害于您的重大利益。</p>
                        </div>
                        <div class="H_c_h3con">
                        	<h2>八、用户信息披露原则</h2>
                            <p>1、根据中华人民共和国相关法律法规，当相关政府机关依照法定程序要求本网站披露相关个人信息时，本网站将根据执法单位之要求或为公共安全之目的提供相关资料。在此情况下对用户信息的任何披露，本网站均不承担任何责任。 2、其他医+E（医加E）服务平台因善意确信或合理原因有必要对相关用户信息进行披露的情形： （1）根据医+E（医加E）服务平台服务协议或医+E（医加E）服务平台其他相关声明所列的条款，有必要进行相关信息公开的； （2）在紧急的情况下，为保护搜房控股公司及医+E（医加E）服务平台用户的人身或财产安全的； （3）为体现公平公正原则，需要对用户的部分信息进行公示的。</p>
                        </div>
                        <div class="H_c_h3con">
                        	<h2>九、Cookies</h2>
                            <p>1、Cookies是指一种技术，当使用者访问设有Cookies装置的本网站时，本网站的服务器会自动发送Cookies至用户浏览器内，并储存到其电脑硬盘内。此Cookies负责记录日后您到访本网站的种种活动、网络个人资料、浏览习惯等。 2、医+E（医加E）服务平台使用cookies仅为为所有用户提供更加周到的个性化服务之目的。运用Cookies技术，本网站能够知道哪些网页或内容受欢迎。此外，本网站将会运用Cookies追访您的喜好，使您在访问我们的网站时能够得到更周密更细致的良好服务。 3、cookies不会跟踪个人信息。当您注册成为我们网站会员时，医+E（医加E）服务平台会使用cookies。在这种情况下，会存储有用信息，使您再次访问我们的网站时可辨认您的身份，从而向您提供感兴趣的信息资料或储存账号密码等，以便您造访本网站时不必每次重复登录。 4、来自医+E（医加E）服务平台的cookies只能被医+E（医加E）服务平台读取。您可以选择接受或拒绝 Cookie。大多数网络浏览器会自动接受 Cookie，但您通常可根据自己的需要来修改浏览器的设置以拒绝 Cookie。如果您的浏览器被设置为拒绝cookies，您仍然能够访问我们的大多数网页，但是您可能无法完全体验医+E（医加E）服务平台全部的网络服务或本网站的特色互动功能。</p>
                        </div>
                        <div class="H_c_h3con">
                        	<h2>十、免责</h2>
                            <p>除上述第八章规定的免责情形外，下列情况本网站亦毋需承担任何责任： 1、由于您将用户密码告知他人或与他人共享注册帐户，由此导致的任何个人信息泄露，或其他非因医+E（医加E）服务平台原因导致的个人信息泄漏； 2、任何由于黑客政击、计算机病毒侵入或发作、因政府管制而造成的暂时性关闭等影响网络正常经营之非平台原因而造成的个人信息泄露、丢失、被盗用或被篡改等； 3、任何第三方根据平台各服务声明及服务协议中所列明的情况使用您的个人信息，由此所产生的纠纷； 4、医+E（医加E）服务平台在各服务声明及服务协议中列明的所有免责情形； 5、医+E（医加E）服务平台的网页上有与其他网站网页的链接。医+E（医加E）服务平台对其他任何网站的内容、隐私政策、运营情况、或经营这些网站的公司的行为概不负责。在向这些与医+E（医加E）服务平台链接的网站提供个人信息之前，请查阅它们的隐私政策。由于与本网站链接的其它网站所造成的个人信息泄露及由此而导致的任何法律争议和后果，医+E（医加E）服务平台概不负责； 6、任何因不可抗力所导致的后果。</p>
                        </div>
                        <div class="H_c_h3con">
                        	<h2>十一、相关法律</h2>
                            <p>本声明未涉及的问题请参见国家有关法律法规，当本声明与国家有关法律法规冲突时，以国家法律法规为准。</p>
                        </div>
                        <div class="H_c_h3con">
                        	<h2>十二、本网站相关声明</h2>
                            <p>本网站相关声明版权及其修改权、更新权和最终解释权均属医+E（医加E）服务平台所有。</p>
                        </div>
                    </div>
                </div>
    </div>
</div>

</template>

<script>
export default{

}

</script>

<style>
</style>