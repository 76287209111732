<template>
<div class="userTop">
        <div class="userMain">
            <div class="user_box01"><router-link :to="{name:'index'}" title="医+E平台"><img  src="../../assets/images/userLogo.png"></router-link><span></span><img id="bbbb"  src="../../assets/images/grzx.png"></div>
            <div class="user_box03">
                <el-menu :default-active="activeIndex" height="100%" active-text-color="#fff" background-color="#28a8e2" text-color="#fff" class="el-menu-demo" mode="horizontal" @select="handleSelect">
                    <!-- <el-menu-item index="1"><router-link :to="{name:'userIndex'}">用户首页</router-link></el-menu-item> -->
                    <!-- <el-submenu index="2">
                        <template slot="title">账户设置</template>
                        <el-menu-item index="2-1">选项1</el-menu-item>
                        <el-menu-item index="2-2">选项2</el-menu-item>
                        <el-menu-item index="2-3">选项3</el-menu-item>
                        <el-submenu index="2-4">
                        <template slot="title">选项4</template>
                        <el-menu-item index="2-4-1">选项1</el-menu-item>
                        <el-menu-item index="2-4-2">选项2</el-menu-item>
                        <el-menu-item index="2-4-3">选项3</el-menu-item>
                        </el-submenu>
                    </el-submenu> -->
                    <router-link tag="el-menu-item" :to="{name:'userIndex'}" index="1">用户首页</router-link>
                    <router-link tag="el-menu-item" :to="{name:'courseList',params:{id:'all'}}" index="2">购买课程</router-link>
                    <!-- <router-link tag="el-menu-item" :to="{name:'userZhang'}" index="2">账户设置</router-link> -->
                    <!-- <router-link tag="el-menu-item" :to="{name:'userMessage'}" index="3">消息中心</router-link> -->
                    <!-- <el-menu-item index="2"><router-link :to="{name:'userZhang'}">账户设置</router-link></el-menu-item>
                    <el-menu-item index="3" ><router-link :to="{name:'userMessage'}">消息中心</router-link></el-menu-item> -->
                    <!-- <el-menu-item index="4"><a href="https://www.ele.me" target="_blank">订单管理</a></el-menu-item> -->
                </el-menu>
            </div>
            <div class="user_box02">
                <router-link :to="{name:'index'}">医+E首页</router-link>
                <span>欢迎你，{{this.$store.state.userName}}</span>|
                <strong @click="exitLogin">[ 退出 ]</strong>
            </div>
        </div>
    </div>

</template>

<script>
import api from "@/api/index.js";
export default{
    data() {
      return {
        activeIndex: '1',
        activeIndex2: '1'
      };
    },
    methods: {
      handleSelect(key, keyPath) {
        console.log(key, keyPath);
      },
      exitLogin(){
        api.loginOut()
            .then(res=>{
                console.log(res);
                if(res.data.Status==1){
                  this.$store.commit("setUserName",'')
                  this.$store.commit("changeLogin",false)
                  this.$store.commit('del_token')
                  this.$router.push({name:'index'});
                  this.$message({
                    message:'你已经成功退出登陆状态',
                    type: 'success',
                  });
                }else{
                  this.$message({
                    message:'退出失败',
                    type: 'success',
                  });
                }
                
            }).then(res=>{
                // this.$store.commit('del_token')
            })
        // this.$store.commit('del_token');
      }
    }

}

</script>

<style>
.userTop{ background: #28a8e2;}
 .userMain{ width:1200px; margin:0px auto; overflow:hidden;}
 .user_box01{ float:left; height:78px; line-height:78px;}
 .user_box01 img{ vertical-align:middle;}
 .user_box01 span{ display:inline-block; width:1px; height:31px; background:#dddddd; position:relative; top:12px; margin:0px 20px 0px 20px;}
 .user_box02{ float:right;height:78px; line-height:78px; font-size: 14px; color: #eee;}
  .user_box03{ float:left;height:78px; line-height:78px; margin-left: 50px;}
 .user_box02 img{ vertical-align:middle;}
.user_box03 .el-menu--horizontal>.el-menu-item{height:78px; line-height: 78px;}
.user_box03 .el-menu--horizontal>.el-submenu .el-submenu__title{height:78px; line-height: 78px;}
.user_box02 a{ display: inline-block; height: 20px; font-size: 14px; line-height: 20px; padding:5px 25px; background: #2086b5; color: #fff; border-radius: 20px;}
.user_box02 strong{ color: #fdea14; cursor: pointer;}
.user_box02 span{margin: 0 5px 0 20px; color: #fff;}
.user_box03 .el-menu--horizontal>.el-menu-item.active{border: none; background: url(../../assets/images/userSan.png) center bottom no-repeat;}
.user_box03 .el-submenu__title i{ color: #fff; font-size: 14px;}

.user_box03 .el-menu--horizontal>.el-menu-item a{ display: block;}
</style>