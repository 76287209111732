<template>
<div class="clearfix">
  <div class="header-left" v-if="!areaIsTrue"><header-left></header-left></div>
  <div class="header-left"  v-else><header-area :areaNamebb='areaIdNameaa'></header-area></div>
  <div class="header-right"><header-right></header-right></div>
</div>
</template>

<script>
import headerLeft from './headerLeft.vue'
import headerRight from './headerRight.vue'
import headerArea from './headerArea.vue'
export default{
    props:{
      areaIsTrue: {
        type: Boolean,
        default: false
      },
      areaIdNameaa: {
        type: String,
        default: ''
      }
    },
    components:{
        headerLeft,
        headerRight,
        headerArea
    },
    created(){
      console.log('pppp:',this.areaIdNameaa)
    }
}

</script>

<style>
.header-left{ float: left;}
.header-right{ float: right;}
</style>