<template>
<div class="courseSearchBox">
    <div class="courseSeaItem">
        <label>所属地区：</label>
        <ul>
            <li><a href="#" class="SeaItemOn">全部</a></li>
            <li><a href="#">上海</a></li>
            <li><a href="#">北京</a></li>
            <li><a href="#">深圳</a></li>
            <li><a href="#">安徽</a></li>
        </ul>
    </div>
    <div class="courseSeaItem">
        <label>专业类别：</label>
        <ul>
            <li><a href="#" class="SeaItemOn">全部</a></li>
            <li><a href="#">临床医学</a></li>
            <li><a href="#">人文医学</a></li>
            <li><a href="#">健康医学</a></li>
            <li><a href="#">公共卫生</a></li>
        </ul>
    </div>
</div>

</template>

<script>
export default{

}

</script>

<style>
.courseSearchBox{  height: 80px; background: #f7f8f9; margin: 15px 10px 0 10px; border: 1px solid #eee;}
.courseSeaItem{ border-bottom: 1px solid #eee; overflow:hidden; font-size: 14px; margin: 0 20px;}
.courseSeaItem label{ float: left; line-height: 40px;}
.courseSeaItem ul{ float: left;}
.courseSeaItem ul li{ float: left;line-height: 40px;}
.courseSeaItem ul li a{ display: block; padding:0 15px; border-radius: 5px; margin-right: 10px; height: 24px; line-height: 24px; margin-top: 8px; }
.courseSeaItem ul li a.SeaItemOn{background: #28a7e1;color: #fff;}
.courseSeaItem:last-child{border-bottom: none;}
</style>