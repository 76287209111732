<template>
<div>
    <div class="loginTop">
        <div class="Main">
            <div class="Navigator_box01"><a href="#" title="医+E平台"><img  src="../assets/images/logo01.png"></a><span></span><img id="bbbb"  src="../assets/images/login_bg.png"></div>
            <div class="Navigator_box02 Logo_right"><el-button type="primary" round><router-link :to="{name:'index'}">返回首页</router-link></el-button></div>
        </div>
    </div>
    <div class="loginMiddle">
        <div class="loginContent">
            <div class="loginConRight"><login-input></login-input></div>
        </div>
    </div>
    <div class="loginBottom">
        <p>
            <a href="http://www.doc-exam.com/contact_us.html" target="_blank">关于我们</a>|
            <router-link :to="{name:'helpAgreement'}">服务条款</router-link>|
            <router-link :to="{name:'helpProblem'}">帮助中心</router-link>|
            <router-link :to="{name:'helpStatement'}">法律声明</router-link>|
            <router-link :to="{name:'helpNew'}">新手上路</router-link>|
            <a href="http://www.doc-exam.com/about_lxwm.html" target="_blank">联系我们</a>|
            <a href="http://www.doc-exam.com/recruitment.html" target="_blank">招聘信息</a>
        </p>
        <p>
            <span>©医东网络科技发展（上海）有限公司版权所有 </span><span>沪公网安备 31010902001559号</span><span>沪ICP备14003938号-2</span>
        </p>
    </div>
</div>

</template>

<script>
import loginInput from '@/components/login/loginInput.vue'
export default{
    components:{
        loginInput,
    }
}

</script>

<style>
</style>