<template>
  <el-tabs type="border-card">
    <el-tab-pane label="个人用户">
      <h4 v-if="error" class="errorTitle">{{error}}</h4>
      <el-input placeholder="请输入手机账号" :class="{error: error && !userName}" v-model="userName">
        <template slot="prepend">
          <img src="../../assets/images/loginUser.png">
        </template>
      </el-input>
      <el-input type="password" placeholder="请输入密码" :class="{error: error && !password}" v-model="password">
        <template slot="prepend">
          <img src="../../assets/images/loginPass.png">
        </template>
      </el-input>
      <el-input
        placeholder="请输入验证码"
        :class="{error: error && !code}"
        v-model="code"
        class="inputDuan"
      >
        <template slot="prepend">
          <img src="../../assets/images/loginCode.png">
          <div class="inputTu"><img @click="onLoginCodeImg" :src="loginCodeImg" alt="验证码"></div>
        </template>
      </el-input>
      <div class="loginFast">
        <router-link :to="{name:'register'}" >快速注册</router-link>
        <router-link :to="{name:'backPassword'}" style="margin-right:10px; color:#777;">忘记密码</router-link>
      </div>
    </el-tab-pane>

    <el-button type="primary" round @click="submit">立即登录</el-button>
    <p></p>
    <dt>友情提示：</dt>
    <dd>1、请使用后正常退出登录，以免账号在其他设备登录失败。</dd>
    <dd>2、如无法正常登录，请拨打客服热线(400-6623-955)解决。</dd>

  </el-tabs>

</template>

<script>

import api from "@/api/index.js";
import Vue from 'vue'
import cookie from 'vue-cookies'
import axios from "axios";
Vue.use(cookie)
export default {
  data() {
    return {
      input1: "",
      input2: "",
      input3: "",
      input4: "",
      checked: false,
      userName: "",
      password: "",
      code: "",
      error: "",
      loginCodeImg:'',
      uid:null,
    };
  },
  created(){
    this.onLoginCodeImg();
  },
  methods: {
    loignOut(){
      console.log('-------');
      api.loginOut()
            .then(res=>{
                console.log(res);
            }).then(res=>{
                this.$store.commit('del_token')
            })
        console.log('++++++');
    },
     onLoginCodeImg(){
        this.uid=(new Date()).getTime();
        console.log(this.uid);
        api.geCodeImg({
          params:{
            uid:this.uid,
          }
        }).then(res=>{
         this.loginCodeImg="/api/CustomerAccount/VerifyRegister?uid=" + this.uid;
      }).catch(function (error) {
        console.log(error);
      });
      },
    submit() {
      console.log(this.userName, this.password);
      if (!this.userName) {
        this.error = "请输入账号";

        return false;
      }
      if (!this.password) {
        this.error = "请输入您的密码";
        return false;
      }
      if (!this.code) {
        this.error = "请输入验证码";
        return false;
      }
      console.log(1230999)
       api.getLogin({
            strAccount: this.userName,
            strPassword: this.password,
            strVerifyCode: this.code,
            uid: this.uid
            })
            .then(res=> {
              console.log(res);
              if(res.data.Status==1){
                  //this.$store.commit('setUserName',res.data.rows.A_LoginAccount);
                  this.$store.commit('setUserNameID',res.data.rows.A_ID);
                  this.$store.commit('set_token',res.data.rows.Token);
                  this.$store.commit('changeLogin',true);

                  var aa=Vue.prototype.$cookies.get('userName');
                   localStorage.setItem('userName',aa);

                  console.log(aa,this.$store.state.token,this.$store.state.userNameID+'++++');

                  this.$message({
                      message:res.data.Msg,
                      type: 'success',
                  });
                  console.log("this.$store.state.courseListUrl:"+this.$store.state.courseListUrl);

                  if(localStorage.getItem('setCourseListUrl')!=null && localStorage.getItem('setCourseListUrl')!='' && localStorage.getItem('setCourseListUrl').toLowerCase().indexOf('courselist')!='-1'){
                      this.$router.push({path:localStorage.getItem('setCourseListUrl')});
                  }
                  else if(localStorage.getItem('setCourseListUrl')!=null && localStorage.getItem('setCourseListUrl')!='' && localStorage.getItem('setCourseListUrl').toLowerCase().indexOf('courseshow')!='-1'){
                      this.$router.push({path:localStorage.getItem('setCourseListUrl')});
                  }
                  else{
                    this.$router.push({name:'userIndex'});
                  }
                }else{
                  this.error=res.data.Msg;
                  this.onLoginCodeImg();

                }
            })

            .catch(function (error) {
              console.log(error);
            });


    }
  }
};
</script>

<style>
.loginConRight .el-tabs--border-card > .el-tabs__content {
  padding: 10px 25px 10px 25px;
}
.loginConRight .el-tabs--border-card {
  box-shadow: none;
  border: none;
}
.loginConRight .el-tabs__item {
  width: 187.5px;
  text-align: center;
  font-size: 18px;
  height: 45px;
  line-height: 45px;
}
.loginConRight .el-tabs--border-card > .el-tabs__header {
  border: none;
}
.loginConRight .el-tabs--border-card > .el-tabs__header .el-tabs__item {
  border: none;
}
.loginConRight .el-input-group {
  position: relative;
  margin-top: 20px;
}
.loginConRight .el-input-group__prepend {
  position: absolute;
  height: 38px;
  padding: 0;
  width: 50px;
  text-align: center;
  vertical-align: middle;
  line-height: 38px;
  border-radius: 0;
  border: none;
  left: 1px;
  top: 1px;
}
.loginConRight .el-input-group__prepend img {
  vertical-align: middle;
}
.loginConRight .el-input__inner {
  padding-left: 64px;
  border-radius: 0px;
}
.loginFast {
  font-size: 14px;
  padding: 15px 0 30px 0;
}
.loginFast a {
  float: right;
  color: #79c6f0;
  font-size: 14px;
}
.loginFast a:hover {
  text-decoration: underline;
}
.loginConRight .el-button.is-round {
  height: 40px;
  font-size: 16px;
  line-height: 40px;
  padding: 0px;
  width: 100%;
  border-radius: 10px;
}
.loginConRight .inputDuan {
  width: 70%;
}
.inputTu {
  background: #eee;
  height: 40px;
  position: absolute;
  width: 90px;
  top: -1px;
  left: 235px;
  cursor: pointer;
  text-align: center;
  line-height: 40px;
}
.inputTu img{height: 40px; height: 40px; border:1px solid #ccc;}
.error input {
  border-color: red;
}
.errorTitle {
  background: #fef0f0;
  color: #f56c6c;
  border-radius: 5px;
  padding: 5px 10px;
  /* position: absolute;
  width: 305px;
  font-size: 12px;
  top: -13px; */
  margin: 0 0 -12px 0;
}
.loginConRight .el-tabs__content .el-tab-pane{position: relative;}
.el-message{ top: 20px;z-index: 99999999 !important;}
</style>
