<template>
<div>
    <div class="aboutBanner"></div>
    <div class="aboutNav">
        <ul>
            <li><router-link :to="{name:'aboutIntroduce'}">公司介绍</router-link></li>
            <!-- <li><router-link :to="{name:'aboutNews'}">公司新闻</router-link></li>
            <li><router-link :to="{name:'aboutDeed'}">公司大事记</router-link></li> -->
            <li><router-link :to="{name:'aboutAptitude'}">公司资质</router-link></li>
            <li><router-link :to="{name:'aboutPartner'}">合作伙伴</router-link></li>
            <li><router-link :to="{name:'aboutUs'}">联系我们</router-link></li>
            <li><router-link :to="{name:'aboutInfo'}">招聘信息</router-link></li>
            
        </ul>
    </div>
    <div class="aboutContent">
        <router-view></router-view>
    </div>
</div>

</template>

<script>
// import aboutIntroduce from '@/components/about/aboutIntroduce.vue'
export default{
    // components:{
    //     aboutIntroduce,
    // }
}

</script>

<style>

</style>