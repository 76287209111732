<template>
  <div class="weTest">
    <div class="weTestTitle">
      模拟考试
      <span>[ 共计{{ courswareNum }}题 ]</span>&nbsp;&nbsp;<span>{{testscore}}</span>
      <el-dialog
        title="提示"
        :visible.sync="centerDialogVisible"
        width="30%"
        :before-close="handleClose"
        center>
        <span>{{alertContent}}</span>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary"  @click="DialogVisible()">确 定</el-button>
        </span>
       </el-dialog>
    </div>
    <div class="weTestCode">
      <el-popover placement="bottom" width="400" trigger="click">
        <div class="weTestCodeItem">
          <h3>
            <span></span>未作答&nbsp;&nbsp;&nbsp;
            <span class="blueLi"></span>已作答
          </h3>
          <ul>
            <li v-for="(item, index) in ItemIDAns" :id="item.ItemID" :class="{ blueLi:item.IsAnswered }" :key="index" @click="clickItem(index+1)">{{index+1}}</li>
          </ul>
        </div>
        <el-button slot="reference" type="primary" size="medium ">答题卡</el-button>
      </el-popover>
    </div>
    <div class="weTestCourse">
      <el-button type="success" @click="backCourse()" size="medium">返回课件</el-button>
    </div>
    <div class="weTestCon">
      <div class="weTestConHeader">
        <span v-if="CI_ItemType==='X型'">【题型】{{CI_ItemType}}多选题：</span>
        <span v-else>【题型】{{CI_ItemType}}单选题：</span>
      </div>
      <template v-if="showA1A2XItem">
        <p :class="isMw?'p_mw':''">
          <span :class="isMw?'lable-title_mw':'lable-title'" >{{itemSortNUM}} </span><span :class="isMw?'lable-title_mw':'lable-title'" v-html="CI_Caption"></span>
        </p>
        <hr class="hr"/>
          <el-radio-group  v-model="selectedOption" v-if="showSingle">
            <el-radio @click.native.prevent="onRadioChange(item.O_ID)" :label="item.O_ID" :key="item.O_ID" v-for="item in Info_ItemOptions" :class="isMw?'elradio-item_mw':'elradio-item'" ><span>{{formateStringToNum(item.CO_OptionSort)}}、</span><span :class="isMw?'mongolianLanguage':''"  v-html="item.CO_Content.length>64?(item.CO_Content.substring(0,64)+'<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'+item.CO_Content.substring(64,item.CO_Content.length)):item.CO_Content"></span></el-radio>
          </el-radio-group>
          <el-checkbox-group v-model="checkXItems" v-if="showMutil">
            <el-checkbox  @click.native.prevent="chooseItem(item.O_ID)" :label="item.O_ID" :key="item.O_ID" v-for="item in Info_ItemOptions" :class="isMw?'elradio-item_mw':'elradio-item'" ><span>{{formateStringToNum(item.CO_OptionSort)}}、</span><span :class="isMw?'mongolianLanguage':''"  v-html="item.CO_Content.length>64?(item.CO_Content.substring(0,64)+'<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'+item.CO_Content.substring(64,item.CO_Content.length)):item.CO_Content"></span></el-checkbox>
          </el-checkbox-group>
        <div style="clear:both"></div>
      </template>
      <template v-if="showA3A4Item">
          <p>
            <span :class="isMw?'lable-title_mw':'lable-title'">{{itemRange}} </span><span :class="isMw?'lable-title_mw':'lable-title'" v-html="CI_Caption"></span>
          </p>
          <div class="div-empty"></div>
          <div class="div-childItem" v-for="(item,index) in childItems" :key="item.Info_Item.CI_ID">
            <p>
            <span :class="isMw?'lable-title_mw':'lable-title'">{{(A3A4ChidItemIndex+index)}}、</span><span :class="isMw?'lable-title_mw':'lable-title'" v-html="item.Info_Item.CI_Caption"></span>
            </p>
            <hr class="hr"/>
            <el-radio-group  v-model="checkedValue[index]">
              <el-radio  @change="onRadioA3A4Change(childitem.O_ID,item.Info_Item.CI_ID)" :label="childitem.O_ID" :key="childitem.O_ID" v-for="childitem in item.Info_ItemOption" :class="isMw?'elradio-item_mw':'elradio-item'" ><span>{{formateStringToNum(childitem.CO_OptionSort)}}、</span><span class="mongolianLanguage" v-html="childitem.CO_Content.length>64?(childitem.CO_Content.substring(0,64)+'<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'+childitem.CO_Content.substring(64,childitem.CO_Content.length)):childitem.CO_Content"></span></el-radio>
            </el-radio-group>
            <div style="clear:both"></div>
            <div class="div-empty"></div>
          </div>
      </template>
      <template v-if="showB1Item">
        <p><span :class="isMw?'lable-title_mw':'lable-title'">{{itemRange}} {{CI_Caption}}</span></p>
        <hr class="hr"/>
        <div class="div-option" v-for="item in Info_ItemOptions" :key="item.O_ID">
          <p><span>{{formateStringToNum(item.CO_OptionSort)}}、</span><span class="mongolianLanguage" v-html="item.CO_Content.length>64?(item.CO_Content.substring(0,64)+'<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'+item.CO_Content.substring(64,item.CO_Content.length)):item.CO_Content"></span></p>
        </div>
        <hr class="hr"/>
        <div class="div-empty"></div>
        <div class="div-childItemB1"  v-for="(childItem,index) in childItems" :key="childItem.Info_Item.CI_ID">
          <p><span :class="isMw?'lable-title_mw':'lable-title'">{{(A3A4ChidItemIndex+index)}}、</span><span :class="isMw?'lable-title_mw':'lable-title'" v-html="childItem.Info_Item.CI_Caption"></span></p>
          <hr class="hr"/>
          <el-radio-group v-model="checkedValue[index]">
                <el-radio  @change="onRadioA3A4Change(cItem.O_ID,childItem.Info_Item.CI_ID)" :label="cItem.O_ID" :key="cItem.O_ID" v-for="cItem in Info_ItemOptions" :class="isMw?'elradio-item_mw':'elradio-item'" ><span>{{formateStringToNum(cItem.CO_OptionSort)}}</span></el-radio>
          </el-radio-group>
           <!-- <div v-for="(item,dx) in Info_ItemOptions" :Key="dx">
            <input type="radio" :id="childItem.Info_Item.CI_ID" :name="childItem.Info_Item.CI_ID" @click="onRadioA3A4Change(item.O_ID,childItem.Info_Item.CI_ID)"/>
            <label>{{formateStringToNum(item.CO_OptionSort)}}</label>
           </div> -->
          <div style="clear:both"></div>
        </div>
      </template>
      <div class="weTestInput">
        <el-button type="primary" size="small" :disabled="prevButtonIsDisabled" icon="el-icon-arrow-left" @click="prevCourseItem()">上一题</el-button>
        <el-button type="primary" size="small" :disabled="nextButtonIsDisabled" @click="nextCourseItem()">下一题<i class="el-icon-arrow-right el-icon--right"></i></el-button>
        <el-button type="primary" size="small" :style="{display:submitIsVisabled}"  @click="subLastItem()">提  交</el-button>
        <!-- <el-button type="info" plain size="small" @click="dialogTableVisible = true">答案解析</el-button> -->
      </div>
      <!-- <el-dialog title="本题解析" :visible.sync="dialogTableVisible">
        <p>《道路交通安全法》第一百零一条：造成交通事故后逃逸的，由公安机关交通管理部门吊销机动车驾驶证，且终生不得重新取得机动车驾驶证。</p>
      </el-dialog> -->
    </div>
  </div>
</template>

<script>
import api from "@/api/index.js";
import { readlink, ftruncate } from "fs";
import { constants } from 'zlib';
import { isArray } from 'util';
import {decodeUnicode} from "../../common/decode";
import consts from "@/common/constants.js";

export default {
  created() {
    // 禁用右键
    document.oncontextmenu = new Function("event.returnValue=false");
    // 禁用选择
    document.onselectstart = new Function("event.returnValue=false");
    this.courseId=this.$route.query.courseId;
    console.log(this.courseId);
    this.postCourseItemIDS();
    console.log(333333);
  },
  data() {
    return {
      radio:{
        radio01:'3',
        radio02:'3',
        radio03:'3',
        radio04:'3',
      },
      testscore:"",
      courswareNum: "",
      itemSortNUM:"",
      currentCoureItemIds: "",
      prevCourseItemIds:"",
      nextCourseItemIds:"",
      Info_Item:"",
      Info_ItemOptions:"",
      CI_ItemType:"",
      CI_Caption:"",
      CI_ItemTagsID:'',
      itemRange:"",
      child_CI_Caption:"",
      TCustomerScoreID:"",
      childItems:[],
      arrayIds: [],
      CheckItems:[],
      checkXItems:[],
      radioList:[],
      checkedValue:[],
      ItemIDAns:[],
      selectIndex:-1,
      A3A4ChidItemIndex:1,
      //radio: "A",
      dialogTableVisible: false,
      dialogFormVisible: false,
      successVisible: false,
      errorVisible: false,
      courseId:'',
      selectedOption:'',
      showSingle:true,
      showMutil:false,
      showA1A2XItem:true,
      showA3A4Item:false,
      showB1Item:false,
      prevButtonIsDisabled:false,
      nextButtonIsDisabled:false,
      centerDialogVisible:false,
      submitIsVisabled:"none",
      issubmit:false,
      alertContent:'',
      isMw:false,
      // formLabelWidth: '120px'
    };
  },
  methods: {
    postCourseItemIDS() {

     api.postGetMockExamItemIDList(this.courseId).then(res=>{
        if(res.data.rows==null) { return false;}
        console.log(res.data.rows.ItemNum);
        this.courswareNum=res.data.rows.ItemNum;
        this.$store.commit('setItemIDsAnswer',res.data.rows.ItemIDsAndAnswerList);
        this.ItemIDAns=res.data.rows.ItemIDsAndAnswerList;
        this.TCustomerScoreID=res.data.rows.TCustomerScoreID;
        console.log("1 TCustomerScoreID:"+this.TCustomerScoreID);
        console.log(JSON.stringify(res.data.rows.ItemIDsAndAnswerList));
        for(var i=0;i<this.ItemIDAns.length;i++){
          this.arrayIds.push(this.ItemIDAns[i].ItemID);
        }

        this.currentCoureItemIds= this.arrayIds[0];
        if(this.currentCoureItemIds==undefined) return false;

        this.GetSelectMockExamItem(this.currentCoureItemIds);

        //默认加载的题项索引等于为0，上一题按钮禁用掉
        this.prevButtonIsDisabled=true;

        this.loadItemAnswer();

      });
    },
    prevCourseItem() {
      this.nextButtonIsDisabled=false;
      this.submitIsVisabled="none";
      //保存答案
      this.saveItemAnswer();

      //用户不按套路出牌，以某道题为最后一题，而不是末尾的那道题为最后一道题
      var num=0;
      for(var i=0;i<this.ItemIDAns.length;i++){
        if(this.ItemIDAns[i].IsAnswered===false){
            num++;
        }
      }

      if(num===1&&this.issubmit===false){
             this.submitIsVisabled="";
      }

      //获取当前题目id的索引
      var valindex = this.indexOf(this.arrayIds, this.prevCourseItemIds);
      var subCount=0;
      var pID=null;
      for(var i=0;i<this.ItemIDAns.length;i++)
      {
        if(this.ItemIDAns[i].ItemID==this.prevCourseItemIds)
        {
          pID=this.ItemIDAns[i].PItemID;
        }
      }
      if(pID!=null){
        var ids=[];
          for(var i=0;i<this.ItemIDAns.length;i++)
          {
              if(this.ItemIDAns[i].PItemID==pID)
              {
                ids.push(this.ItemIDAns[i].ItemID);
                subCount++;
              }
          }

          var starindex=this.indexOf(this.arrayIds,ids[0]);
          this.A3A4ChidItemIndex=(starindex+1);
          valindex=starindex;
      }

      //索引等于为0，上一题按钮就禁用掉
      if (valindex === 0)
      {
        this.prevButtonIsDisabled=true;
      }

      //给当前ID赋新值
      this.currentCoureItemIds = this.prevCourseItemIds;
      //当前ID赋新值后，获取新的当前题目信息
      this.GetSelectMockExamItem(this.currentCoureItemIds);

      this.loadItemAnswer();
    },
    nextCourseItem() {
      this.prevButtonIsDisabled=false;
      //保存答案
      this.saveItemAnswer();

      //用户不按套路出牌，以某道题为最后一题，而不是末尾的那道题为最后一道题
      var num=0;
      for(var i=0;i<this.ItemIDAns.length;i++){
        if(this.ItemIDAns[i].IsAnswered===false){
            num++;
        }
      }

      if(num===1&&this.issubmit===false){
             this.submitIsVisabled="";
      }

      //获取当前题目id的索引
      var valindex = this.indexOf(this.arrayIds, this.nextCourseItemIds);

      var subCount=0;
      var pID=null;
      for(var i=0;i<this.ItemIDAns.length;i++)
      {
        if(this.ItemIDAns[i].ItemID==this.nextCourseItemIds)
        {
          pID=this.ItemIDAns[i].PItemID;
        }
      }

      if(pID!=null){
          var ids=[];
          for(var i=0;i<this.ItemIDAns.length;i++)
          {
              if(this.ItemIDAns[i].PItemID==pID)
              {
                ids.push(this.ItemIDAns[i].ItemID);
                subCount++;
              }
          }

          var starindex=this.indexOf(this.arrayIds,ids[0]);
          this.A3A4ChidItemIndex=(starindex+1);
          valindex=this.indexOf(this.arrayIds,ids[subCount-1]);
      }

      //索引最大值为数组的长度，大于或等于数组的长度就直接返回
      if (valindex === this.arrayIds.length-1)
      {
        this.nextButtonIsDisabled=true;
        this.prevButtonIsDisabled=false;

        if(this.issubmit===false){
          this.submitIsVisabled="";
        }
      }

      this.currentCoureItemIds=this.nextCourseItemIds;
      //当前ID赋新值后，获取新的当前题目信息
      this.GetSelectMockExamItem(this.currentCoureItemIds);
      this.loadItemAnswer();
    },
    indexOf(arr, str) {
      if (arr && arr.indexOf) {
        return arr.indexOf(str);
      }

      var len = arr.length;
      for (var i = 0; i < len; i++) {
        if (arr[i] == str) {
          return i;
        }
      }
      return -1;
    },
    backCourse() {
      this.$router.push({
        name: "userCourselearn",
        query:{courseId:this.courseId},
      });
    },
    clickItem(obj)
    {
      //提交答案
      this.saveItemAnswer();

      this.selectIndex=obj-1;
      this.submitIsVisabled="none";
      this.nextButtonIsDisabled=false;
      this.prevButtonIsDisabled=false;

      //用户不按套路出牌，以某道题为最后一题，而不是末尾的那道题为最后一道题
      var num=0;
      for(var i=0;i<this.ItemIDAns.length;i++){
        if(this.ItemIDAns[i].IsAnswered===false){
            num++;
        }
      }

      if(num===1&&this.issubmit===false){
             this.submitIsVisabled="";
      }

      this.currentCoureItemIds=this.arrayIds[this.selectIndex];
      var pID=null;
      var lastIndex=0;

      for(var i=0;i<this.ItemIDAns.length;i++)
      {
        if(this.ItemIDAns[i].ItemID==this.currentCoureItemIds)
        {
          pID=this.ItemIDAns[i].PItemID;
        }
      }
      if(pID!=null){
          var ids=[];
          for(var i=0;i<this.ItemIDAns.length;i++)
          {
              if(this.ItemIDAns[i].PItemID==pID)
              {
                ids.push(this.ItemIDAns[i].ItemID);
              }
          }

          lastIndex=this.indexOf(this.arrayIds,ids[ids.length-1]);
          var starindex=this.indexOf(this.arrayIds,ids[0]);
          this.selectIndex=starindex;
          this.A3A4ChidItemIndex=(starindex+1);

          if(lastIndex===this.arrayIds.length-1)
          {
              this.nextButtonIsDisabled=true;
          }
      }

      //索引等于为0，上一题按钮就禁用掉
      if (this.selectIndex === 0)
      {
        this.nextButtonIsDisabled=false;
        this.prevButtonIsDisabled=true;
      }

      //索引是最后一题，下一题按钮就禁用掉
      if(lastIndex===0&&this.selectIndex===this.arrayIds.length-1){
        this.nextButtonIsDisabled=true;
        this.prevButtonIsDisabled=false;

        if(this.issubmit===false){
          this.submitIsVisabled="";
        }
      }

      this.GetSelectMockExamItem(this.currentCoureItemIds);
      this.loadItemAnswer();
    },
    onRadioChange(e){
      console.log("事件："+e);
      e === this.selectedOption ? this.selectedOption = '' : this.selectedOption = e;
      //this.radioList.push(e.currentTarget.id);
      //更新store
      for(var i=0;i<this.ItemIDAns.length;i++){
        if(this.ItemIDAns[i].ItemID==this.currentCoureItemIds){
          this.ItemIDAns[i].IsAnswered=true;
          //this.ItemIDAns[i].AnswerIDList=[];
          this.ItemIDAns[i].AnswerIDList=e;
        }
      }
      this.$store.commit('setItemIDsAnswer',this.ItemIDAns);
    },
    onRadioA3A4Change(X,Y){
      //将已做完的A3A4试题的答案更新到store中
      for(var i=0;i<this.ItemIDAns.length;i++){
        if(this.ItemIDAns[i].ItemID==Y){
          this.ItemIDAns[i].IsAnswered=true;
          this.ItemIDAns[i].AnswerIDList=[];
          this.ItemIDAns[i].AnswerIDList.push(X);
        }
      }
      this.$store.commit('setItemIDsAnswer',this.ItemIDAns);
    },
    formateStringToNum(obj){
     return String.fromCharCode(64 + parseInt(obj));
    },
    GetSelectMockExamItem(obj){
        for(var i=0;i<this.ItemIDAns.length;i++){
          if(this.ItemIDAns[i].ItemID == obj && this.ItemIDAns[i].PItemID != null){
              obj=this.ItemIDAns[i].PItemID;
          }
        }

        api.postGetSelectMockExamItemSingle(obj).then(res=>{
          this.CI_ItemType=res.data.rows.CI_ItemType;
          this.CI_Caption= decodeUnicode(res.data.rows.Info_Item.CI_Caption.replace(/&#/g, '\\u'));
          this.CI_ItemTagsID=res.data.rows.Info_Item.CI_ItemTagsID;
          this.isMw=this.CI_ItemTagsID==consts.Nmg_MW;
          let Info_ItemOption=[];
          for(let item of res.data.rows.Info_ItemOption) {
            item.CO_Content=decodeUnicode(item.CO_Content.replace(/&#/g, '\\u'));
            Info_ItemOption.push(item);
          }
          this.Info_ItemOptions=Info_ItemOption;
          switch(this.CI_ItemType)
          {
            case "A1型":
            case "A2型":
             this.showA1A2XItem=true;
             this.showA3A4Item=false;
             this.showB1Item=false;

             this.showSingle=true;
             this.showMutil=false;
             break;
            case "A3型":
            case "A4型":
             this.showA3A4Item=true;
             this.showA1A2XItem=false;
             this.showB1Item=false;
             this.childItems=res.data.rows.ItemChild;
             break;
            case "B1型":
             this.showB1Item=true;
             this.showA3A4Item=false;
             this.showA1A2XItem=false;
             this.childItems=res.data.rows.ItemChild;
             break;
            case "X型":
               this.showA1A2XItem=true;
               this.showA3A4Item=false;
               this.showB1Item=false;

               this.showSingle=false;
               this.showMutil=true;
              break;
          }

          var childItemCount=res.data.rows.ItemChild.length;
          var valindex =0;

          valindex = this.indexOf(this.arrayIds, this.currentCoureItemIds);
          valindex=valindex<=0?0:valindex;

          if(childItemCount>1)
          {
            if(obj!=null){
                var ids=[];
                for(var i=0;i<this.ItemIDAns.length;i++)
                {
                    if(this.ItemIDAns[i].PItemID==obj)
                    {
                      ids.push(this.ItemIDAns[i].ItemID);
                    }
                }

                var starindex=this.indexOf(this.arrayIds,ids[0]);

                var startNUM=(starindex+1);
                var endNUM=startNUM+(childItemCount-1);
                this.itemRange=startNUM+"~"+endNUM;
            }

            this.nextCourseItemIds=this.arrayIds[endNUM];
            this.prevCourseItemIds=this.arrayIds[startNUM-2];
          }else{
             console.log("2.valindex:"+(valindex+1));
            this.itemSortNUM=(valindex+1)+"、";
            this.prevCourseItemIds=this.arrayIds[valindex-1];
            this.nextCourseItemIds=this.arrayIds[valindex+1];
            console.log("2.this.prevCourseItemIds:"+this.prevCourseItemIds);
          }
        });
    },
    chooseItem(id){
      //this.CheckItems.add(id);
      //console.log("1:"+JSON.stringify(this.checkXItems[0]));
      if(this.checkXItems[0]=='null')  this.checkXItems.splice(0, 1);
      //console.log("11:"+JSON.stringify(this.checkXItems));
      if(this.checkXItems.indexOf(id)==-1){
        this.checkXItems.push(id);
        //更新store
        for(var i=0;i<this.ItemIDAns.length;i++){
          if(this.ItemIDAns[i].ItemID==this.currentCoureItemIds){
            this.ItemIDAns[i].IsAnswered=true;
            //this.ItemIDAns[i].AnswerIDList=[];
             //console.log("2:"+JSON.stringify(this.checkXItems));
            this.ItemIDAns[i].AnswerIDList=this.checkXItems.join(',');
             //console.log("3:"+JSON.stringify(this.ItemIDAns[i].AnswerIDList));
          }
        }
        this.$store.commit('setItemIDsAnswer',this.ItemIDAns);
      }else{
         this.checkXItems.splice(this.checkXItems.indexOf(id), 1);
      }

    },
    DialogVisible(){
      this.centerDialogVisible=false;
      this.backCourse();
    },
    handleClose(done){
      done();
      this.backCourse();
    },
    subLastItem(){
      var num=0;
      for(var i=0;i<this.ItemIDAns.length;i++){
        if(this.ItemIDAns[i].IsAnswered===false){
            num++;
        }
      }

      if(num>0){
        alert("您有"+num+"道条没做完，请做完再提交");
        return false;
      }
      this.saveItemAnswer();
      //最终提交试卷，返回分数
      api.postSubmitMockExam({
        CourseScoreID:this.TCustomerScoreID,
        CourseBagID:this.courseId,
        ItemID:this.currentCoureItemIds,
        CustomerAnswer:this.selectedOption,
        EndTime:new Date()
      }).then(res=>{
        //console.log("得分："+res.data.rows);
        if(res.data.rows!=null){
          alert("模拟试卷提交成功，得分："+res.data.rows+" 分。");
          this.testscore="得分："+res.data.rows+" 分";
          //返回分数后隐藏提交按钮
          this.submitIsVisabled="none";
          this.issubmit=true;
        }
      });
    },
    loadItemAnswer(){
        //定义一个题目父级id变量，主要用于A3A4B1型试题
        var vpid=null;
        //存储多选题的题项id
        this.checkXItems=[];
        //清空this.ItemIDAns变量的中的值
        this.ItemIDAns=[];
        //获取存入store中的答题卡数据，赋值给this.ItemIDAns
        this.ItemIDAns=this.$store.state.ItemIDsAnswer;
        //遍历答题卡数据集合，获取A1A2X型试题的答案和其父级id
        for(var i=0;i<this.ItemIDAns.length;i++){
              //判断答题卡数据集合中的题目id是否和当前题目id相同
              if(this.ItemIDAns[i].ItemID===this.currentCoureItemIds&&this.ItemIDAns[i].PItemID==null){
                //这个地方对题型没有做判断，故this.selectedOption是A1A2型题已做完的答案题项id
                if(this.ItemIDAns[i].AnswerIDList==null)
                   this.selectedOption="";
                else{
                  console.log("aadd单选："+this.ItemIDAns[i].AnswerIDList);
                  this.selectedOption=this.ItemIDAns[i].AnswerIDList;
                  //this.CheckItems是X型题已做完的答案题项id
                  if(this.ItemIDAns[i].AnswerIDList.indexOf(',')!=-1){
                   this.checkXItems=this.ItemIDAns[i].AnswerIDList.split(',');
                  }
                  else{
                    this.checkXItems.push(this.ItemIDAns[i].AnswerIDList);
                  }
                }
              }
              else if(this.ItemIDAns[i].ItemID===this.currentCoureItemIds&&this.ItemIDAns[i].PItemID!=null){
                 //题目父级id
                 vpid=this.ItemIDAns[i].PItemID;
              }
        }
        //父级id不为null，说明该型题试A3A4B1型题
        if(vpid!=null)
        {
          //this.checkedValue存放A3A4B1型试题已做完的题项id，清空this.checkedValue中的值
          this.checkedValue=[];
          var strItemids="";
          //再次遍历答题卡数据集合
          for(var i=0;i<this.ItemIDAns.length;i++){
                //答题卡中题目的父级id和变量vpid相同，说明这些都是当前试题的子题
                if(this.ItemIDAns[i].PItemID===vpid && this.ItemIDAns[i].AnswerIDList!=null){
                  //存储各个子题的已做完的题项id，便于页面显示
                  //this.checkedValue=this.ItemIDAns[i].AnswerIDList.split(',');
                  console.log("11111222dd:"+this.ItemIDAns[i].AnswerIDList[0]);
                  this.checkedValue.push(this.ItemIDAns[i].AnswerIDList[0]);
                }
          }
          console.log("1111122222:"+this.checkedValue);
        }
    },
        //保存试题数据
    saveItemAnswer(){
      var parentItemID=null;
       console.log("this.ItemIDAns:"+JSON.stringify(this.ItemIDAns));
      //遍历this.checkedValue，拿到A3A4B1型试题的已做完的答案题项id
      for(var i=0;i<this.ItemIDAns.length;i++)
      {
        if(this.CI_ItemType=="A3型"||this.CI_ItemType=="A4型"||this.CI_ItemType=="B1型"){
            //判断答题卡数据集合中的题目id是否和当前题目id相同
            if(this.ItemIDAns[i].ItemID===this.currentCoureItemIds){
              //题目父级id
              parentItemID=this.ItemIDAns[i].PItemID;
              //this.ItemIDAns[i].AnswerIDList=this.checkedValue;
            }
        }

      }
      //console.log("TCustomerScoreID:"+this.TCustomerScoreID);
      for(var i=0;i<this.ItemIDAns.length;i++)
      {

          if(parentItemID!=null){
              if(this.ItemIDAns[i].IsAnswered && this.ItemIDAns[i].PItemID==parentItemID){
                  console.log("bbb:"+JSON.stringify(this.ItemIDAns[i].AnswerIDList));
                  var answerlist=this.ItemIDAns[i].AnswerIDList.join('');
                  //提交答案
                  api.postReceiveCustomerAnswer({
                    CourseScoreID:this.TCustomerScoreID,
                    CourseBagID:this.courseId,
                    ItemID:this.ItemIDAns[i].ItemID,
                    CustomerAnswer:answerlist,
                    EndTime:new Date()
                  }).then(res=>{
                      console.log(res);
                  });
              }
          }
          else{

              if(this.ItemIDAns[i].IsAnswered && this.ItemIDAns[i].ItemID==this.currentCoureItemIds){
                  console.log("大侠单选："+this.ItemIDAns[i].AnswerIDList);
                  //提交答案
                  api.postReceiveCustomerAnswer({
                    CourseScoreID:this.TCustomerScoreID,
                    CourseBagID:this.courseId,
                    ItemID:this.ItemIDAns[i].ItemID,
                    CustomerAnswer:this.ItemIDAns[i].AnswerIDList,
                    EndTime:new Date()
                  }).then(res=>{
                      console.log(res);
                  });
              }
          }
      }
    },
  }
};
</script>

<style>
.weTestConHeader{height: 30px;}
.weTestCodeItem {
  max-height: 400px;
  overflow-y: scroll;
}
.weTestInput{text-align: right;margin-right: 15px;}
.lable-title{font-size: 16px;font-family: Arial, Helvetica, sans-serif;}
.elradio-item{width: 100%;height: 28px;line-height: 28px; padding-left: 15px;margin-top: 10px;}
.div-empty{height: 15px;}
.div-childItem{float: left; width: 100%;height: 270px;}
.div-childItemB1{float: left; width: 100%;height: auto;margin-top: 10px;}
.div-option{float: left;width:100%;height: 28px;line-height: 28px; padding-left: 15px;margin-top: 5px;font-size: 16PX;}
.div-childItemB1 div{display: inline-block;width: 100%;height: 28px;line-height: 28px; padding-left: 15px;margin-top: 2px;margin-bottom: 5PX;}
.div-childItemB1 div label{margin-left: 2px;font-size: 16px;}
.div-childItem div{height: 28px;line-height: 28px; padding-left: 15px;margin-top: 5px;}
.div-childItem div label{margin-left: 2px;font-size: 16px;}
.elradio-childItemOption{width:10%;float: left;height: 28px;line-height: 28px; padding-left: 15px;margin-top: 3px;}

.radioList .el-radio__input.is-checked+.el-radio__label{color: red}
.radioList .el-radio__input.is-checked .el-radio__inner{background: #ff0000;border-color: red}

.weTestCon .p_mw {float: left; width: 30px;border-right: 1px solid #ddd;   margin-right: 30px;}

.elradio-item_mw {width: auto; padding-left: 15px;margin-top: 10px;}
.el-radio__label .span_mw {display: block;}
.el-checkbox__label .span_mw {display: block;}
.el-checkbox__label  {display: contents;}
.mongolianLanguage {
  font-family:'Menk Sidam Tig' ;
  word-wrap: break-word;
  writing-mode: vertical-rl;
  text-orientation: sideways;
  text-align: right;
  margin-top: 10px;
}
.lable-titleSortNUM{font-size: 16px;font-family: Arial, Helvetica, sans-serif;float: left;}
.lable-title_mw {
  font-size: 16px;
  font-family: 'Menk Sidam Tig';
  word-wrap: break-word;
  text-orientation: sideways;
  writing-mode: vertical-rl;
  text-align: right;
}
</style>
