<template>
<div class="aboutGray">
        <div class="about1200">
                        
                        <div class="H_c_r_t_con">
                            <ul>
                                <li>
                                    <div><a href="http://www.shmda.org.cn/" target="_blank" title="上海市医师协会"><img src="@/assets/images/partner01.png"></a></div>
                                    <h1><a href="http://www.shmda.org.cn/" target="_blank" title="上海市医师协会">上海市医师协会</a></h1>
                                </li>
                                <li>
                                    <div><a href="http://www.shsma.org.cn/" target="_blank" title="上海市医学会"><img src="@/assets/images/partner03.png"></a></div>
                                    <h1><a href="http://www.shsma.org.cn/" target="_blank" title="上海市医学会">上海市医学会</a></h1>
                                </li>
                                <li>
                                    <div><a href="http://www.cnmia.org/" target="_blank" title="中国非公立医疗机构协会"><img src="@/assets/images/partner02.png"></a></div>
                                    <h1><a href="http://www.cnmia.org/" target="_blank" title="中国非公立医疗机构协会">中国非公立医疗机构协会</a></h1>
                                </li>
                                <li>
                                    <div><a href="http://www.zhmda.org.cn/" target="_blank" title="珠海市医师协会"><img src="@/assets/images/partner04.png"></a></div>
                                    <h1><a href="http://www.zhmda.org.cn/" target="_blank" title="珠海市医师协会">珠海市医师协会</a></h1>
                                </li>
                                <li>
                                    <div><a href="http://www.szsmda.org.cn/" target="_blank" title="深圳市医师协会"><img src="@/assets/images/partner05.png"></a></div>
                                    <h1><a href="http://www.szsmda.org.cn/" target="_blank" title="深圳市医师协会">深圳市医师协会</a></h1>
                                </li>
                                <li>
                                    <div><a href="http://ah.cnmia.org" target="_blank" title="安徽非公立医疗机构协会"><img src="@/assets/images/partner06.png"></a></div>
                                    <h1><a href="http://ah.cnmia.org" target="_blank" title="安徽非公立医疗机构协会">安徽非公立医疗机构协会</a></h1>
                                </li>
                                <li>
                                    <div><a href="http://www.hcmda.org.cn" target="_blank" title="惠州市惠城区医师协会"><img src="@/assets/images/partner07.png"></a></div>
                                    <h1><a href="http://www.hcmda.org.cn" target="_blank" title="惠州市惠城区医师协会">惠州市惠城区医师协会</a></h1>
                                </li>
                                <li>
                                    <div><a href="http://jcyl.cnmia.org" target="_blank" title="非公立医疗机构基层医疗分会"><img src="@/assets/images/partner08.png"></a></div>
                                    <h1><a href="http://jcyl.cnmia.org" target="_blank" title="非公立医疗机构基层医疗分会">非公立医疗机构基层医疗分会</a></h1>
                                </li>
                                <li>
                                    <div><a href="http://www.cpmyyl.org" target="_blank" title="北京市昌平区非公有制医疗机构协会"><img src="@/assets/images/partner09.png"></a></div>
                                    <h1><a href="http://www.cpmyyl.org" target="_blank" title="北京市昌平区非公有制医疗机构协会">北京市昌平区非公有制医疗机构协会</a></h1>
                                </li>
                                <li>
                                    <div><a href="http://www.whnmia.org" target="_blank" title="武汉市非公立医疗机构协会"><img src="@/assets/images/partner10.png"></a></div>
                                    <h1><a href="http://www.whnmia.org" target="_blank" title="武汉市非公立医疗机构协会">武汉市非公立医疗机构协会</a></h1>
                                </li>
                                <li>
                                    <div><a href="http://cy.cnmia.org" target="_blank" title="北京市朝阳区非公有制医疗机构协会"><img src="@/assets/images/partner11.png"></a></div>
                                    <h1><a href="http://cy.cnmia.org" target="_blank" title="北京市朝阳区非公有制医疗机构协会">北京市朝阳区非公有制医疗机构协会</a></h1>
                                </li>
                                <li>
                                    <div><a href="http://mxb.cnmia.org" target="_blank" title="中国非公立医疗机构协会慢病管理分会"><img src="@/assets/images/partner17.png" style="width:210px;"></a></div>
                                    <h1><a href="http://mxb.cnmia.org" target="_blank" title="中国非公立医疗机构协会慢病管理分会">中国非公立医疗机构协会慢病管理分会</a></h1>
                                </li>
                                <li>
                                    <div><a href="http://orth.cnmia.org" target="_blank" title="中国非公立医疗机构协会骨科分会"><img src="@/assets/images/partner12.png"></a></div>
                                    <h1><a href="http://orth.cnmia.org" target="_blank" title="中国非公立医疗机构协会骨科分会">中国非公立医疗机构协会骨科分会</a></h1>
                                </li>
                                <li>
                                    <div><a href="http://www.lsnmia.org" target="_blank" title="乐山市非公立医疗机构协会"><img src="@/assets/images/partner13.png"></a></div>
                                    <h1><a href="http://www.lsnmia.org" target="_blank" title="乐山市非公立医疗机构协会">乐山市非公立医疗机构协会</a></h1>
                                </li>
                                
                                <li>
                                    <div><a href="http://plastic.cnmia.org" target="_blank" title="非公立医疗机构整形与美容专业委员会"><img src="@/assets/images/partner14.png"></a></div>
                                    <h1><a href="http://plastic.cnmia.org" target="_blank" title="非公立医疗机构整形与美容专业委员会">非公立医疗机构整形与美容专业委员会</a></h1>
                                </li>
                                <li>
                                    <div><a href="http://hematology.cnmia.org" target="_blank" title="中国非公立医疗机构协会血液病专业委员会"><img src="@/assets/images/partner15.png"></a></div>
                                    <h1><a href="http://hematology.cnmia.org" target="_blank" title="中国非公立医疗机构协会血液病专业委员会">中国非公立医疗机构协会血液病专业委员会</a></h1>
                                </li>
                                <li>
                                    <div><a href="http://health.cnmia.org" target="_blank" title="中国非公立医疗机构协会健康管理与健康保险分会"><img src="@/assets/images/partner19.png" style="width:210px;"></a></div>
                                    <h1><a href="http://health.cnmia.org" target="_blank" title="中国非公立医疗机构协会健康管理与健康保险分会">中国非公立医疗机构协会健康管理与健康保险分会</a></h1>
                                </li>
                                <li>
                                    <div><a href="http://bj.cnmia.org" target="_blank" title="北京市非公立医疗机构协会"><img src="@/assets/images/partner16.png"></a></div>
                                    <h1><a href="http://bj.cnmia.org" target="_blank" title="北京市非公立医疗机构协会">北京市非公立医疗机构协会</a></h1>
                                </li>
                                <li>
                                    <div><a href="http://cd.cnmia.org" target="_blank" title="中国非公立医疗机构协会皮肤专业委员会"><img src="@/assets/images/partner18.png" style="width:210px;"></a></div>
                                    <h1><a href="http://cd.cnmia.org" target="_blank" title="中国非公立医疗机构协会皮肤专业委员会">中国非公立医疗机构协会皮肤专业委员会</a></h1>
                                </li>
                                
                            </ul>
                        </div>
        </div>
</div>


</template>

<script>
export default{

}

</script>

<style>
.about1200{ width: 1200px; margin: 0 auto; background: #fff;}
.Help_con_r{ padding-bottom:40px;}
.H_c_r_text{ padding-bottom:20px; margin-top:0px;}
.H_c_r_t_con{ font-size:14px; margin-top:10px; line-height:30px; overflow:hidden;}

.H_c_r_t_con ul{ overflow:hidden; margin-top:30px;}
.H_c_r_t_con ul li{ float:left; width:220px; height:140px;position:relative; margin-left: 17px;}
.H_c_r_t_con ul li div{ text-align:center;}
.H_c_r_t_con ul li div img{ width:72px;}
.H_c_r_t_con ul li h1{ font-size:14px; font-weight:normal; width:200px; padding:0px 5px; height:40px; line-height:20px; text-align:center; font-size:14px; position:absolute;  bottom:15px;}
.H_c_r_t_con ul li h1 a:hover{ color:#00a8e0;}
.H_c_r_t_con h3{ font-size:14px; font-weight:normal;color:#00a8e0; margin-top:30px;}
.H_c_h3con{ margin-bottom:20px;}
.H_c_h3con span{ color:#00a8e0;}
.H_c_h3con h2{font-size:16px; font-weight:normal; color:#00a8e0;}
</style>